import React from "react";
import { useState } from "react";
import Filter from "../../Components/Filter";
import NavigationBar from "../../Components/NavigationBar";
import DataBioPetani from "../../Components/DataBioPetani";
import { useEffect } from "react";
import { getGrafikTotalProduksi } from "../../api/api";
import { LineChartPetani } from "../../Components/Grafik/LineChartPetani";
import { useLocation, useParams } from "react-router-dom";
import PostCardProduksi from "../../Components/PostCard/PostCardProduksi";
import {
  getFilterPostinganPublic,
  getKetuaPetaniDanAnggota,
  getPostinganByKetuaPetani,
} from "../../api/publicApi";
import { getDetailAnggotaByKetuaPetani } from "../../api/ketuaPetaniPemdaApi";
import SkeletonPostCard from "../../Components/Skeleton/SkeletonPostCard";
import { Spinner } from "@material-tailwind/react";
import InfiniteScroll from "react-infinite-scroll-component";
import DefaultImg from "../../asset/img/defaultImage.png";

const BioPetani = (props) => {
  const [getGrafik, setGetGrafik] = useState();
  const [dataGrafik, setDataGrafik] = useState();
  const [ketuaPetani, setKetuaPetani] = useState();
  const [anggota, setAnggota] = useState([]);
  const [produksi, setProduksi] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [average, setAverage] = useState();
  const [loader, setLoader] = useState(false);
  const [maxTotalPostingan, setMaxTotalPostingan] = useState();
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [dateError, setDateError] = useState(false);

  const location = useLocation().pathname;

  // const showProduksi () => {

  // }

  const { ketuaPetaniId, role, type } = useParams();
  // console.log(ketuaPetaniId);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  // const [userData, setUserData] = useState({
  //   labels: UserData.map((data) => data.date),
  //   datasets: [
  //     {
  //       label: ["Pendapatan produksi garam kecamatan xxxxxx 10 bulan terakhir", "Kecamatan 1"],
  //       data: UserData.map((data) => data.produksi),
  //       backgroundColor: ["rgba(75,192,192,1)", "#ecf0f1", "#50AF95", "#f3ba2f", "#2a71d0"],
  //       borderColor: "black",
  //       borderWidth: 2,
  //     },
  //   ],
  // });
  useEffect(() => {
    getGrafikTotalProduksi(ketuaPetaniId).then((result) => {
      setGetGrafik(result);
      if (result) {
        const data = {
          labels: result.map((data) => data.date),
          datasets: [
            {
              label: "Petani 1",
              data: result.map((data) => data.produksi),
              borderColor: "rgb(77, 123, 151)",
              backgroundColor: "rgb(77, 123, 151)",
              cubicInterpolationMode: "monotone",
            },
          ],
        };
        const maxPostingan = Math.max(...result.map((data) => data.produksi));
        setMaxTotalPostingan(maxPostingan);
        setDataGrafik(data);
      }
    });
  }, [ketuaPetaniId]);

  useEffect(() => {
    if (role === "ketua_petani" || role === "pemda" || role === "unhas") {
      try {
        getDetailAnggotaByKetuaPetani(ketuaPetaniId).then((result) => {
          setAnggota(result);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [ketuaPetaniId, type, role]);

  useEffect(() => {
    try {
      setLoader(true);
      getPostinganByKetuaPetani(ketuaPetaniId, page).then((result) => {
        setProduksi((prev) => [...prev, ...result.postingans]);

        if (result.pagination.last_page <= result.pagination.current_page) {
          setHasMoreData(false);
        }
        setLoader(false);
      });
    } catch (error) {
      console.error(error);
    }
  }, [ketuaPetaniId, type, page]);

  useEffect(() => {
    getKetuaPetaniDanAnggota(ketuaPetaniId).then((result) => {
      setKetuaPetani(result);
    });
  }, [ketuaPetaniId, type]);

  useEffect(() => {
    setLoader(true);
    if (startDate || endDate) {
      if (startDate > endDate) {
        setDateError(true);
        return;
      }
      setDateError(false);
      getFilterPostinganPublic(startDate, endDate, ketuaPetaniId).then((result) => {
        setProduksi((prev) => [...prev, ...result.postingans]);

        if (result.pagination.last_page <= result.pagination.current_page) {
          setHasMoreData(false);
        }
        setLoader(false);
      });
    }
  }, [startDate, endDate, ketuaPetaniId]);

  useEffect(() => {
    setProduksi([]);
    setPage(1);
  }, [location]);

  const startDateOnChange = (e) => {
    setStartDate(e.target.value);
    setProduksi([]);
    setPage(1);
  };
  const endDateOnChange = (e) => {
    setEndDate(e.target.value);
    setProduksi([]);
    setPage(1);
  };

  // useEffect(() => {
  //   getPostinganAveragePublic(ketuaPetaniId).then((result) => {
  //     setAverage(result);
  //   });
  // }, [ketuaPetaniId]);

  const [fix, setFix] = useState(false);

  function fixedFilter() {
    if (window.scrollY >= 600) {
      setFix(true);
    } else {
      setFix(false);
    }
  }

  const displayLoader = () => {
    return (
      <>
        <SkeletonPostCard />
        <SkeletonPostCard />
      </>
    );
  };

  window.addEventListener("scroll", fixedFilter);

  return (
    <>
      <NavigationBar
        ketuaPetaniId={ketuaPetaniId}
        startDate={startDate}
        endDate={endDate}
        startDateOnChange={startDateOnChange}
        endDateOnChange={endDateOnChange}
      />
      <div className="pt-28 mx-auto lg:bg-[#eee]">
        <div className="lg:w-[1100px] mx-auto lg:border lg:border-1 bg-white rounded-xl">
          <div className="lg:flex gap-10 justify-between">
            <div className="flex justify-center">
              <div className="h-[500px] md:w-[320px] w-[300px] lg:mx-0 mx-10 lg:mb-0 mb-10 border border-gray-600 rounded-xl">
                <div className="text-center my-3 md:text-xl text-lg font-semibold text-header1">
                  <h1>Ketua</h1>
                  <h1>Kelompok Petani Garam</h1>
                </div>
                {ketuaPetani ? (
                  <>
                    <img
                      src={
                        ketuaPetani.profile !== null
                          ? `${process.env.REACT_APP_API_STORAGE_URL}/${ketuaPetani.profile}`
                          : DefaultImg
                      }
                      alt=""
                      className={
                        ketuaPetani.profile
                          ? "mx-auto h-72 object-cover w-full"
                          : "mx-auto p-5 h-72"
                      }
                    />
                    <div className="w-full h-14 bg-primary text-white flex items-center justify-center">
                      <p className="font-bold">{ketuaPetani.full_name}</p>
                    </div>
                    <div className="text-center mt-3 ">
                      <p className="md:text-xl text-lg font-medium text-header2">
                        Luas Lahan: {ketuaPetani.total_lahan} Hektar
                      </p>
                      <p className="font-light md:text-base text-xs text-paragraf">
                        {ketuaPetani.kecamatan}
                      </p>
                    </div>
                  </>
                ) : (
                  <div className="mx-5">
                    <div className="h-72 w-full animate-pulse bg-gray-200"></div>
                    <div className="w-full h-5 bg-gray-200 animate-pulse mt-5"></div>
                    <div className="w-full h-5 bg-gray-200 animate-pulse mt-1"></div>
                    <div className="w-full h-5 bg-gray-200 animate-pulse mt-1"></div>
                  </div>
                )}
              </div>
            </div>

            <div className="lg:flex items-center mx-3">
              <div>
                <h1 className="md:text-xl text-lg font-medium text-header1">
                  Grafik garis produksi (Ton)
                </h1>
                <div className="lg:w-[700px] w-full">
                  {/* <Chart chartData={userData} /> */}
                  {dataGrafik ? (
                    <LineChartPetani data={dataGrafik} max={maxTotalPostingan} />
                  ) : (
                    <div>
                      <div className="w-full h-5 bg-gray-200 animate-pulse mt-2"></div>
                      <div className="w-full h-5 bg-gray-200 animate-pulse mt-2"></div>
                      <div className="w-full h-5 bg-gray-200 animate-pulse mt-2"></div>
                      <div className="w-full h-5 bg-gray-200 animate-pulse mt-2"></div>
                      <div className="w-full h-5 bg-gray-200 animate-pulse mt-2"></div>
                      <div className="w-full h-5 bg-gray-200 animate-pulse mt-2"></div>
                      <div className="w-full h-5 bg-gray-200 animate-pulse mt-2"></div>
                    </div>
                  )}
                </div>

                <div>
                  <h1 className="md:text-xl text-lg font-medium text-header2">
                    Anggota Kelompok Tani :
                  </h1>
                  {ketuaPetani ? (
                    <div className="flex gap-10 pl-5 md:text-sm text-sm text-paragraf">
                      <ul className="list-disc">
                        {ketuaPetani.anggotas.map((data, index) => {
                          return (
                            <li key={index}>
                              {data.full_name} : {data.luas_lahan} Hektar
                            </li>
                          );
                        })}
                        {/* <li>Nama lengkap Petani1: X Hektar</li>
                    <li>Nama lengkap Petani1: X Hektar</li> */}
                      </ul>
                    </div>
                  ) : (
                    <div>
                      <div className="w-1/3 h-5 bg-gray-200 animate-pulse mt-2"></div>
                      <div className="w-1/3 h-5 bg-gray-200 animate-pulse mt-2"></div>
                      <div className="w-1/3 h-5 bg-gray-200 animate-pulse mt-2"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* section fix */}
        <div className="mt-16 flex h-screen bg-white ">
          {/* Left Konten */}
          <div className={fix ? "fixed top-16 bg-white" : "bg-white"}>
            <Filter
              ketuaPetaniId={ketuaPetaniId}
              endDate={endDate}
              startDate={startDate}
              endDateOnChange={endDateOnChange}
              startDateOnChange={startDateOnChange}
              ratarata={average}
              dateError={dateError}
            />
          </div>

          <div className="w-full">
            {/* Right Konten */}

            <div
              className={
                fix ? "w-full md:fixed top-16 z-10 bg-white lg:ml-[300px]" : "w-full top-16"
              }
            >
              <div className="h-24 w-full flex items-center justify-center md:justify-start md:shadow-lg md:pl-10">
                <h1 className="md:text-3xl text-2xl font-semibold">
                  {location.includes("produksi") ? "Produksi" : "Anggota"}
                </h1>
              </div>
            </div>

            {/* post card */}
            <div className={fix ? "lg:ml-[300px] md:mt-28 pb-10" : "pb-10"}>
              {type === "bio-anggota" &&
                (!loader ? (
                  anggota.map((data) => {
                    return (
                      <DataBioPetani
                        key={data.id}
                        namaLengkap={data.full_name}
                        namaPanggilan={data.nickname}
                        gender={data.gender}
                        tlp={data.phone_number}
                        tempatLahir={data.place_birth}
                        tanggalLahir={data.birth_date}
                        jalanLahanGaram={data.alamat_anggota.jalan}
                        kelurahanLahanGaram={data.alamat_anggota.kelurahan}
                        kecamatanLahanGaram={data.alamat_anggota.kecamatan}
                        kabupatenLahanGaram={data.alamat_anggota.kota}
                        koordinatLahanGaram={data.lahan_anggota.koordinat_lahan}
                        luasLahanGaram={data.lahan_anggota.luas_lahan}
                        metodeProduksi={data.lahan_anggota.metode_produksi}
                      />
                    );
                  })
                ) : (
                  <div className="flex mt-64 justify-center">
                    <Spinner className="w-20 h-20" />
                  </div>
                ))}
            </div>
            {type === "produksi" && !loader ? (
              <InfiniteScroll
                dataLength={produksi.length}
                next={() => setPage((prev) => prev + 1)}
                loader={displayLoader()}
                hasMore={hasMoreData}
                className={fix ? "lg:ml-[300px] md:mt-28" : ""}
              >
                <div className="overflow-y-hidden flex flex-wrap mb-10">
                  {produksi
                    ? produksi.map((data, index) => {
                        return (
                          <PostCardProduksi
                            key={index}
                            id={data.id}
                            total_views={data.total_views}
                            total_komen={data.komentars_count}
                            total_penjualan={data.total_penjualan}
                            total_produksi={data.total_produksi}
                            deskripsi={data.deskripsi}
                            tanggal_produksi={data.tanggal_produksi}
                            image={data.media.length !== 0 && data.media[0].file_name}
                          />
                        );
                      })
                    : displayLoader()}
                  {!loader && produksi.length === 0 && (
                    <div className="m-10">
                      <p>Data tidak ditemukan....</p>
                    </div>
                  )}
                </div>
              </InfiniteScroll>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BioPetani;
