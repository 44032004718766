import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

export function LineChartPetani({ data, max }) {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
  const options = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: max + 3,

        title: {
          display: true,
          text: "Produksi Ton",
          font: {
            size: 14,
            weight: "bold",
          },
          color: "black",
          padding: {
            bottom: 15,
          },
        },
      },
      x: {
        title: {
          display: true,
          text: "Waktu",
          font: {
            size: 14,
            weight: "bold",
          },
          color: "black",
          padding: {
            top: 15,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true, // Use a circular point style for legend items
        },
      },
    },
  };

  return <Line options={options} data={data} />;
}
