import { RequestApi } from "../helpers/RequestApi";
import TokenHelper from "../helpers/TokenHelper";

const addPostingan = async (ketuaPetaniId, data) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "POST",
      `postingan/${ketuaPetaniId}`,
      data,
      headerToken,
      "Mencoba Menambahkan Postingan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menambahkan postingan  ", error);
    throw error;
  }
};

const addLaporan = async (ketuaPetaniId, data) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "POST",
      `laporan/${ketuaPetaniId}`,
      data,
      headerToken,
      "Mencoba Menambahkan laporan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menambahkan laporan  ", error);
    throw error;
  }
};

const getAllProduksi = async (ketuaPetaniId, page) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };
    const params = [];
    if (page) {
      params.push(`page=${page}`);
    }

    const apiUrl = `postingan/list/${ketuaPetaniId}?${params}`;

    const responseData = await RequestApi(
      "GET",
      `postingan/list/${ketuaPetaniId}?page=${page}`,
      {},
      headerToken,
      "Mencoba Menampilkan Postingan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan postingan  ", error);
    throw error;
  }
};

const getAllLaporan = async (ketuaPetaniId, page) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `laporan/list/${ketuaPetaniId}?page=${page}`,
      {},
      headerToken,
      "Mencoba Menampilkan Laporan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan Laporan  ", error);
    throw error;
  }
};

const getAllAnggota = async (ketuaPetaniId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `anggota/${ketuaPetaniId}`,
      {},
      headerToken,
      "Mencoba Menampilkan Anggota"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan Anggota  ", error);
    throw error;
  }
};

const getDetailProfil = async (userId) => {
  try {
    const token = TokenHelper();
    const headerToken = {
      Authorization: `Bearer ${token}`,
    };
    const responseData = await RequestApi(
      "GET",
      `profile/${userId}`,
      {},
      headerToken,
      "Mencoba Menampilkan detail profile"
    );
    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan profile", error);
    throw error;
  }
};

const getFilterLaporan = async (startDate, endDate, status, userId, page, search) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };
    const params = [];

    if (status) {
      params.push(`status_laporan=${status}`);
    }
    if (startDate) {
      params.push(`start_date=${startDate}`);
    }
    if (endDate) {
      params.push(`end_date=${endDate}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (search) {
      params.push(`search=${search}`);
    }

    const apiUrl = `laporan/${userId}/filter?${params.join("&")}`;

    const responseData = await RequestApi(
      "GET",
      apiUrl,
      {},
      headerToken,
      "Mencoba Menampilkan filter laporan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan filter laporan", error);
    throw error;
  }
};

const getAllRiwayat = async (ketuaPetaniId, page) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const params = [];
    if (page) {
      params.push(`page=${page}`);
    }

    const apiUrl = `riwayat/${ketuaPetaniId}?${params}`;

    const responseData = await RequestApi(
      "GET",
      apiUrl,
      {},
      headerToken,
      "Mencoba Menampilkan Laporan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan Laporan  ", error);
    throw error;
  }
};

const getSearchRiwayat = async (ketuaPetaniId, page, search) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const params = [];
    if (page) {
      params.push(`page=${page}`);
    }
    if (search) {
      params.push(`search=${search}`);
    }

    const apiUrl = `riwayat/${ketuaPetaniId}/filter?${params.join("&")}`;

    const responseData = await RequestApi(
      "GET",
      apiUrl,
      {},
      headerToken,
      "Mencoba Menampilkan laporan search"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan laporan search  ", error);
    throw error;
  }
};

const getGrafikTotalProduksi = async (userId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `public/grafik/produksi/${userId}`,
      {},
      headerToken,
      "Mencoba Menampilkan Laporan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan Laporan  ", error);
    throw error;
  }
};

const getDetailPostingan = async (postinganId, setErrorPage) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `postingan/${postinganId}`,
      {},
      headerToken,
      "Mencoba Menampilkan Detail Postingan"
    );

    return responseData.data;
  } catch (error) {
    setErrorPage(true);
    console.error("Terjadi kesalahan saat menampilkan Detail Postingan  ", error);
    // throw error;
  }
};

const getDetailLaporan = async (postinganId, setErrorPage) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `laporan/${postinganId}`,
      {},
      headerToken,
      "Mencoba Menampilkan Detail Postingan"
    );

    return responseData.data;
  } catch (error) {
    setErrorPage(true);
    console.error("Terjadi kesalahan saat menampilkan Detail Postingan  ", error);
    return;
  }
};

const addKomentar = async (userId, komentar) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "POST",
      `komentar/ketua-petani/${userId}`,
      komentar,
      headerToken,
      "Mencoba add komentar"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat add komentar  ", error);
    throw error;
  }
};

const getLaporanAverage = async (userId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `laporan/average/${userId}`,
      {},
      headerToken,
      "Mencoba Menampilkan Detail ratarata laporan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan Detail Postingan  ", error);
    throw error;
  }
};

const getPostinganAverage = async (userId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `postingan/average/${userId}`,
      {},
      headerToken,
      "Mencoba Menampilkan Detail ratarata postingan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan average postingan  ", error);
    throw error;
  }
};

const getNotif = async (userId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `ketua-petani/notification/${userId}`,
      {},
      headerToken,
      "Mencoba Menampilkan notif"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan notif", error);
    throw error;
  }
};

const getPostinganPemda = async (page) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `postingan?page=${page}`,
      {},
      headerToken,
      "Mencoba Menampilkan total postingan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan total postingan", error);
    throw error;
  }
};

// api pemda
const getTotalPostingan = async (userId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `riwayat/totals/${userId}`,
      {},
      headerToken,
      "Mencoba Menampilkan total postingan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan total postingan", error);
    throw error;
  }
};

const updateProfilePetani = async (userId, newProfile) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "POST",
      `profile/${userId}`,
      newProfile,
      headerToken,
      "Mencoba update profile petani"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat update profile petani", error);
    throw error;
  }
};

const deletePostingan = async (userId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "DELETE",
      `postingan/${userId}`,
      {},
      headerToken,
      "Mencoba delete postingan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat delete postingan", error);
    throw error;
  }
};

const deleteLaporan = async (userId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "DELETE",
      `laporan/${userId}`,
      {},
      headerToken,
      "Mencoba delete laporan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat delete laporan", error);
    throw error;
  }
};

const updateProduksi = async (id, newDataPostingan) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "POST",
      `postingan/edit/${id}`,
      newDataPostingan,
      headerToken,
      "Mencoba edit postingan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat edit postingan", error);
    throw error;
  }
};

const updateLaporan = async (id, newDataPostingan) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "POST",
      `laporan/edit/${id}`,
      newDataPostingan,
      headerToken,
      "Mencoba edit laporan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat edit laporan", error);
    throw error;
  }
};

export {
  addPostingan,
  getAllProduksi,
  getAllLaporan,
  getAllAnggota,
  getAllRiwayat,
  getDetailProfil,
  // getFilterPostingan,
  getFilterLaporan,
  getGrafikTotalProduksi,
  getDetailPostingan,
  addKomentar,
  getLaporanAverage,
  getPostinganAverage,
  getNotif,
  getTotalPostingan,
  getPostinganPemda,
  getDetailLaporan,
  addLaporan,
  updateProfilePetani,
  deletePostingan,
  deleteLaporan,
  updateProduksi,
  updateLaporan,
  getSearchRiwayat,
};
