import React from "react";

const ImagePost = (props) => {
  const { image } = props;
  return (
    <img
      src={`${process.env.REACT_APP_API_STORAGE_URL}/${image}`}
      alt="postingan"
      className="h-full max-h-40 w-[250px] md:min-w-[250px] rounded-l-lg object-cover object-center"
    />
  );
};

export default ImagePost;
