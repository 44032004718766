import React from "react";
import LayoutForgotPassword from "../../Components/Auth/LayoutForgotPassword";
import { Select, Option, Button, Input, Spinner } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { forgotPassword } from "../../api/apiAuth";
import AlertModal from "../../Components/Modals/AlertModal";
import SuccessIcon from "../../asset/icon/success.png";
import ErrorIcon from "../../asset/icon/warning.png";

const LupaPassword = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const emailOnChange = (e) => {
    setEmail(e.target.value);
  };

  const forgotPasswordHandler = async (e) => {
    e.preventDefault();
    const data = {
      email: email,
    };
    setLoader(true);
    try {
      const res = await forgotPassword(data);
      if (res) {
        setIsSuccess(true);
      }
      setLoader(false);
      // alert("email pemulihan telah dikirim");
      navigate("/cek-email");
    } catch (error) {
      setLoader(false);
      setIsError(true);
    }
  };

  return (
    <LayoutForgotPassword title="Lupa email/password">
      <form>
        <div className="w-72 h-8">
          <label htmlFor="email" className="font-semibold text-sm">
            email
          </label>
          <Input
            type="email"
            placeholder="contoh@gmail.com"
            onChange={emailOnChange}
            value={email}
            className="placeholder:text-xs !border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10 mt-1"
            labelProps={{
              className: "hidden",
            }}
            containerProps={{ className: "min-w-[100px]" }}
            id="email"
          />
        </div>
        <Button
          className="bg-buttonColor w-full mt-16 rounded-md"
          onClick={forgotPasswordHandler}
          disabled={loader}
        >
          Selanjutnya
          {loader && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Spinner />
            </div>
          )}
        </Button>
      </form>

      <AlertModal
        title="Email pemulihan telah dikirim"
        dialogOpen={isSuccess}
        dialogOpenHandler={() => setIsSuccess(false)}
        icon={SuccessIcon}
      />

      <AlertModal
        title="Periksa kembali email Anda karena email Anda tidak terdaftar di sistem kami"
        dialogOpen={isError}
        dialogOpenHandler={() => setIsError(false)}
        icon={ErrorIcon}
      />
    </LayoutForgotPassword>
  );
};

export default LupaPassword;
