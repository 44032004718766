// Library & Package Import
import axios from "axios";
import Cookies from "js-cookie";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const handleErrorResponse = (error, action) => {
  console.error(`Error: saat ${action}`, error);
  if (error.response.data.errors.message === "Unauthenticated") {
    Cookies.remove("access_token");
    Cookies.remove("role");
    Cookies.remove("userId");
    window.location.href = "/";
  }
  throw error;
};

const RequestApi = async (method, url, data = {}, headers = {}, action) => {
  try {
    const response = await axios({
      method,
      url: `${API_BASE_URL}/${url}`,
      data,
      headers: {
        "Content-Type": "aplication/json",
        ...headers,
      },
    });

    return response.data;
  } catch (error) {
    return handleErrorResponse(error, action);
  }
};

export { RequestApi };
