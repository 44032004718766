import React from "react";
import FilterPengaturan from "../Filter/FilterPengaturan";
import NavigationBar from "../NavigationBar";

const LayoutPengaturan = (props) => {
  const { children, title, userId } = props;
  return (
    <>
      <NavigationBar userId={userId} />
      <div className="pt-16 flex grid-container">
        {/* Left Konten */}
        <div className="fixed bg-white">
          <FilterPengaturan />
        </div>

        <div className="w-full">
          {/* Right Konten */}

          <div className="w-full header fixed bg-white z-10 lg:ml-[300px] ">
            <div className="lg:h-24 lg:justify-start justify-center h-16 text-center w-full flex items-center shadow-lg md:pl-10">
              <h1 className="lg:text-3xl text-2xl font-semibold">{title}</h1>
            </div>
            {/* <FilterPetani /> */}
          </div>
          <div className="md:mt-0 mt-24 md:mb-52 mb-10 md:mx-0">{children}</div>

          {/* post card */}
        </div>
      </div>
    </>
  );
};

export default LayoutPengaturan;
