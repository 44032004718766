import { useNavigate } from "react-router-dom";
import MasalahIcon from "../../asset/icon/Error.svg";
import DefaultImg from "../../asset/img/defaultImage.png";

const NotifCreateMasalah = ({ date, path, username, profile, kecamatan }) => {
  const navigate = useNavigate();
  return (
    <li
      className="py-3 hover:bg-gray-200 rounded-sm cursor-pointer px-5"
      onClick={() => navigate(path)}
    >
      <div className="flex items-center gap-3 mb-3">
        <img
          src={
            profile && profile !== null
              ? `${process.env.REACT_APP_API_STORAGE_URL}/${profile}`
              : DefaultImg
          }
          alt="profile"
          className="h-10 w-10 rounded-full object-cover object-center"
        />
        <p className="text-xs font-bold">
          {username} - {kecamatan}
        </p>
      </div>
      <div className="flex items-center gap-1 my-1 border border-button-laporan w-24 py-1 justify-center">
        <img src={MasalahIcon} alt="produksi icon" className="h-5 w-5" />
        <p className="text-xs">Masalah</p>
      </div>
      <p className="text-[10px] font-semibold">Mengunggah laporan</p>
      <p className="text-[10px] text-hint mt-1">{date}</p>
      <hr className="mt-3" />
    </li>
  );
};

export default NotifCreateMasalah;
