import React, { useState } from "react";

const FilterPetani = (props) => {
  const {
    kecamatan,
    ketuaPetani,
    selectedKecamatan,
    selectedKetuaPetani,
    handleSelectedKecamatan,
    handleSelectedKetuaPetani,
  } = props;

  return (
    <div className="flex items-center md:h-16 h-32 border border-gray-200 border-1 shadow-sm pl-10 gap-5">
      <div>
        <h1 className="font-semibold text-lg">Filter Petani : </h1>
      </div>
      <div>
        {kecamatan ? (
          <select
            value={selectedKecamatan}
            onChange={handleSelectedKecamatan}
            className="mr-5 border border-gray-200 shadow-sm rounded-md md:mb-0"
          >
            <option value="" className="md:text-sm text-[10px]">
              Semua
            </option>
            {kecamatan.map((data, index) => {
              return (
                <option value={data} key={index + 1} className="md:text-sm text-[10px]">
                  {data}
                </option>
              );
            })}
          </select>
        ) : (
          ""
        )}

        {selectedKecamatan && (
          <select
            value={selectedKetuaPetani}
            onChange={handleSelectedKetuaPetani}
            className="border border-gray-200 shadow-sm rounded-md md:mt-0 mt-3"
          >
            <option value="" className="md:text-sm text-[10px]">
              semua
            </option>

            {ketuaPetani[selectedKecamatan].map((data, index) => {
              return (
                <>
                  <option
                    value={data.ketua_petani_id}
                    key={index + 1}
                    className="md:text-sm text-[10px]"
                  >
                    {data.full_name}
                  </option>
                </>
              );
            })}
            {/* {selectedKecamatan === "option1b" && (
              <>
                <option value="option2b1">Petani 2B1</option>
                <option value="option2b2">Petani 2B2</option>
              </>
            )} */}
          </select>
        )}
      </div>
    </div>
  );
};

export default FilterPetani;
