import React from "react";

const TotalInput = (props) => {
  const { value, onChange, label, defaultValue } = props;
  return (
    <div className="md:mt-5">
      <p className="text-xs">
        {label} <span className="font-semibold">(wajib diisi)</span>
      </p>
      <p className="text-xs text-red-500 mb-3">Jika tidak ada, isi 0</p>
      <input
        type="number"
        id="inputBerat"
        onChange={onChange}
        value={value}
        className="w-14 h-7 border-black border rounded-lg text-xs px-1"
        defaultValue={defaultValue}
        min="0"
      />
      <label htmlFor="inputBerat" className="pl-2">
        Kg
      </label>
    </div>
  );
};

export default TotalInput;
