import React from "react";
import { Link } from "react-router-dom";

const CekEmail = () => {
  return (
    <div className="md:h-[500px] h-screen sm:w-[600px] sm:h-[550px] w-full sm:border border-gray-600 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl md:p-10 p-10 md:mt-0 mt-16">
      <h1 className="text-lg font font-semibold text-center">Silahkan cek e-mail Anda.</h1>
      <p className="text-center text-sm mt-5">
        Kami telah mengirim link yang akan mengarahkan Anda untuk reset password
      </p>
      <div className="text-sm mt-5 border border-gray-600 p-5">
        <p>Bagaimana cara mendapatkan link reset password di e-mail?</p>
        <ol className="list-decimal ml-5">
          <li>
            Buka aplikasi e-mail Anda atau klik link berikut{" "}
            <a
              href="https://mail.google.com"
              target="_blank"
              rel="noreferrer"
              className="text-cyan-600 underline"
            >
              https://mail.google.com
            </a>
          </li>
          <li>Lihat email terbaru dari kami dengan judul e-mail "Reset password Garamqu"</li>

          <li>
            Klik link (tulisan berwarna biru) yang terletak di dalam isi e-mail "Reset password"
          </li>
        </ol>
      </div>
      <div>
        <div className="flex justify-center my-5">
          <a
            href="https://youtube.com/playlist?list=PLtG6uNbdXAe_dnkMDMAPF1D1argvmAhXg&feature=shared"
            className="text-xs text-cyan-600 underline text-center"
            target="_blank"
          >
            Tutorial youtube lupa e-mail/password garamku
          </a>
        </div>
        <div className="flex justify-center mb-5">
          <Link to={"/login"} className="text-xs text-cyan-600 underline">
            Kembali ke halaman login
          </Link>
        </div>
        <div className="flex justify-center">
          <Link
            to={"https://wa.me/+6281524025278"}
            rel="noreferrer"
            target="_blank"
            className="text-xs text-cyan-600 underline"
          >
            Butuh bantuan? Hubungi kami
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CekEmail;
