import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { addKomentarPemda, getDetailLaporanPemda, updateStatusLaporan } from "../../api/PemdaApi";
import LaporanDetail from "../../Components/OnePost/LaporanDetail";
import PageNotFound from "../PageNotFound";

const LaporanDetailPemda = () => {
  const { id, userId } = useParams();

  const [detailLaporan, setDetailLaporan] = useState();
  const [listKomentar, setListKomentar] = useState();
  const [komentar, setKomentar] = useState();
  const [newStatus, setNewStatus] = useState(null);
  const [openStatusSelect, setOpenStatusSelect] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [errorPage, setErrorPage] = useState(false);

  useEffect(() => {
    getDetailLaporanPemda(id, setErrorPage).then((result) => {
      setDetailLaporan(result);
      if (errorPage === true) {
        return;
      }
      setListKomentar(result?.komentar);
    });
  }, [id, errorPage]);

  const addKomentarHandler = async (e) => {
    e.preventDefault();

    const KomentarData = {
      type: "laporan",
      item_id: detailLaporan.id,
      deskripsi: komentar,
    };

    try {
      setLoader(true);

      await addKomentarPemda(userId, KomentarData);
      setIsSuccess(true);
      setKomentar("");

      await getDetailLaporanPemda(id).then((result) => {
        setListKomentar(result.komentar);
      });
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setIsFail(true);
      setLoader(false);
      setTimeout(() => {
        setIsFail(false);
      }, 2000);
    }
  };
  // const updateStatusHandler = async (e) => {
  //   e.preventDefault();

  //   console.log(status);
  //   try {
  //     const response = await updateStatusHandler(id, status);
  //     console.log(response);
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  const statusOnChange = async (newStatus) => {
    setNewStatus(newStatus);
    handleOpenSelectStatus();
    if (newStatus) {
      try {
        const status = {
          status_laporan: newStatus.value,
        };
        const response = await updateStatusLaporan(id, status);
        setStatusError(false);
      } catch (error) {
        setStatusError(true);
        console.error(error);
      }
    }
  };
  const handleOpenSelectStatus = () => {
    setOpenStatusSelect(!openStatusSelect);
  };

  const komentarOnChange = (e) => {
    setKomentar(e.target.value);
  };
  return (
    <>
      {errorPage === false ? (
        <LaporanDetail
          detailLaporan={detailLaporan}
          addKomentarHandler={addKomentarHandler}
          komentarOnChange={komentarOnChange}
          komentar={komentar}
          listKomentar={listKomentar}
          statusOnChange={statusOnChange}
          newStatus={newStatus}
          handleOpenSelectStatus={handleOpenSelectStatus}
          openStatusSelect={openStatusSelect}
          statusError={statusError}
          loader={loader}
          isSuccess={isSuccess}
          isFail={isFail}
        />
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default LaporanDetailPemda;
