import React from "react";
import EditIcon from "../../asset/icon/Edit.svg";
import DeleteIcon from "../../asset/icon/Delete.svg";
import { Link, useLocation, useParams } from "react-router-dom";

const Edit = (props) => {
  const { handleOpen } = props;
  const { role, id, userId } = useParams();
  const location = useLocation().pathname;
  return (
    <div className="flex items-center gap-3">
      <Link
        to={
          location.includes("produksi")
            ? `/update/produksi/${id}/${role}/${userId}`
            : `/update/laporan/${id}/${role}/${userId}`
        }
        className="flex gap-1 items-center text-hint cursor-pointer"
      >
        <img src={EditIcon} alt="" className="md:h-4 h-3" />
        <p className="text-xs">Edit</p>
      </Link>
      <p>|</p>
      <div className="flex gap-2 items-center text-hint cursor-pointer" onClick={handleOpen}>
        <img src={DeleteIcon} alt="" className="md:h-4 h-3" />
        <p className="text-xs">Hapus</p>
      </div>
    </div>
  );
};

export default Edit;
