import { Spinner } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getDetailPostinganPemda,
  getDetailProfilPemda,
  updateProfilePemda,
} from "../../api/PemdaApi";
import NavigationBar from "../../Components/NavigationBar";
import LayoutPengaturan from "../../Components/Pengaturan/LayoutPengaturan";
import Profil from "../../Components/Pengaturan/Profil";
import PengaturanProfilPemda from "../../Components/PengaturanPemda";

const PengaturanPemda = () => {
  const { userId } = useParams();
  const [detailProfil, setDetailProfil] = useState();
  const [currProfile, setCurrProfile] = useState(null);
  const [newProfile, setNewProfile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    getDetailProfilPemda(userId).then((result) => {
      setDetailProfil(result);
    });
  }, [userId]);

  useEffect(() => {
    const handleUpdateProfile = async () => {
      if (!newProfile) {
        return;
      }
      const image = {
        profile_pictures: newProfile,
      };

      setIsLoading(true);

      try {
        const result = await updateProfilePemda(userId, image);
        setCurrProfile(result.media.file_name);
        setIsLoading(false);
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
      } catch (error) {
        console.error(error);
      }
    };
    handleUpdateProfile();
  }, [newProfile, userId]);

  const profileOnChange = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    setNewProfile(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => [reject(error)];
    });
  };
  return (
    <>
      <NavigationBar userId={userId} />
      <div className="pt-16 flex grid-container">
        {/* Left Konten */}
        {/* <div className="fixed bg-white">
          
          <FilterPengaturan />
        </div> */}

        <div className="w-full">
          {/* Right Konten */}

          <div className="w-full header fixed bg-white z-10">
            <div className="lg:h-24 lg:justify-start justify-center h-16 text-center w-full flex items-center shadow-lg md:pl-10 ">
              <h1 className="lg:text-4xl text-2xl font-semibold md:ml-[200px]">Profil</h1>
            </div>
            {/* <FilterPetani /> */}
          </div>

          {/* post card */}
          {detailProfil ? (
            <PengaturanProfilPemda
              fullName={detailProfil.full_name}
              namaPanggilan={detailProfil.nickname}
              gender={detailProfil.gender}
              tempatLahir={detailProfil.place_birth}
              tanggalLahir={detailProfil.birth_date}
              tlp={detailProfil.phone_number}
              jalan={detailProfil.alamat.jalan}
              kelurahan={detailProfil.alamat.kelurahan}
              kecamatan={detailProfil.alamat.kecamatan}
              kabupaten={detailProfil.alamat.kota}
              provinsi={detailProfil.alamat.provinsi}
              profile={currProfile !== null ? currProfile : detailProfil.profile}
              profileOnChange={profileOnChange}
              isLoading={isLoading}
              isError={isError}
              isSuccess={isSuccess}
            />
          ) : (
            <div className="mt-80 flex justify-center">
              <Spinner className="h-12 w-12" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PengaturanPemda;
