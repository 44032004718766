import React from "react";
import ImagePost from "../Elements/PostCard/ImagePost";
import TotalPenjualanProduksi from "../Elements/PostCard/TotalPenjualanProduksi";
import DatePostCard from "../Elements/PostCard/DatePostCard";
import DeskripsiPostCard from "../Elements/PostCard/DeskripsiPostCard";
import KomenPostcard from "../Elements/PostCard/KomenPostcard";
import LayoutPostCardPemda from "./LayoutPostCard";
import UserKecamatan from "../Elements/PostCard/UserKecamatan";
import LogoUnhas from "../../asset/logo/logo-unhas.png";
import LogoMaros from "../../asset/logo/logo-maros.png";

const PostCardProduksiPemda = (props) => {
  const {
    tanggal_produksi,
    total_produksi,
    total_penjualan,
    deskripsi,
    total_views,
    total_komen,
    nama,
    image,
    id,
    kecamatan,
    read_by_pemda,
    read_by_unhas,
  } = props;

  return (
    <LayoutPostCardPemda id={id} type="produksi">
      <div className="md:w-fit w-1/3">{image && <ImagePost image={image} />}</div>
      <div className="flex items-center md:w-full w-2/3">
        <div className="md:p-5 p-3">
          <div className="flex">
            <div className="md:flex gap-5">
              {/* <h3 className="text-2xl">
                Total Produksi <span className="font-bold">60 Kg</span>
              </h3> */}
              <TotalPenjualanProduksi
                total_produksi={total_produksi}
                total_penjualan={total_penjualan}
              />
            </div>
            <div className="flex md:gap-3 gap-2 absolute md:right-10 right-3 top-3">
              {read_by_unhas === 1 && (
                <img src={LogoUnhas} alt="Logo Unhas" className="md:h-5 h-4" />
              )}
              {read_by_pemda === 1 && (
                <img src={LogoMaros} alt="Logo Maros" className="md:h-5 h-4" />
              )}
            </div>
          </div>
          <UserKecamatan nama={nama} kecamatan={kecamatan} />

          <DatePostCard date={tanggal_produksi} />

          <DeskripsiPostCard deskripsi={deskripsi} />
          <div className="flex gap-3 mt-3 text-hint">
            <KomenPostcard total_komentar={total_komen} total_views={total_views} />
          </div>
        </div>
      </div>
    </LayoutPostCardPemda>
  );
};

export default PostCardProduksiPemda;
