import React from "react";

const TanggalInput = (props) => {
  const { value, onChange, label, defaultValue } = props;
  return (
    <div className="mt-5">
      <p className="text-xs mb-3">
        {label} <span className="font-semibold">(wajib diisi)</span>
      </p>
      <input
        name="tgl_produksi"
        type="date"
        className="w-30 h-7 pl-2 border-black border rounded-lg text-xs"
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default TanggalInput;
