import React from "react";

const DataBioPetani = (props) => {
  const {
    namaLengkap,
    namaPanggilan,
    gender,
    tlp,
    tempatLahir,
    tanggalLahir,
    jalanLahanGaram,
    kelurahanLahanGaram,
    kecamatanLahanGaram,
    kabupatenLahanGaram,
    koordinatLahanGaram,
    luasLahanGaram,
    metodeProduksi,
  } = props;
  return (
    <div className="p-5 border border-gray-700 rounded-xl  md:mr-10 mx-2 md:translate-y-24 md:mt-10 mt-2 md:mb-10 mb-5">
      <div className="lg:flex">
        <div>
          <h3 className="font-semibold sm:text-xl text-sm md:mb-3 mb-1">Alamat Rumah</h3>
          <table className="w-full table-fixed sm:text-lg text-xs">
            <tr>
              <td>Username</td>
              <td>Petani1</td>
            </tr>
            <tr>
              <td>Nama Lengkap</td>
              <td>{namaLengkap}</td>
            </tr>
            <tr>
              <td>Nama Panggilan</td>
              <td>{namaPanggilan}</td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>{gender}</td>
            </tr>
            <tr>
              <td>Tempat Lahir</td>
              <td>{tempatLahir}</td>
            </tr>
            <tr>
              <td>Tanggal Lahir</td>
              <td>{tanggalLahir}</td>
            </tr>
            <tr>
              <td>No.Telp</td>
              <td>{tlp}</td>
            </tr>
          </table>
        </div>
        <div className="md:flex md:mt-0 mt-5">
          <div>
            <h3 className="font-semibold sm:text-xl text-sm md:mb-3 mb-1">Lahan Garam</h3>
            <table className="w-full table-fixed sm:text-lg text-xs">
              <tr>
                <td>Jalan</td>
                <td>{jalanLahanGaram}</td>
              </tr>
              <tr>
                <td>Kelurahan/Dusun</td>
                <td>{kelurahanLahanGaram}</td>
              </tr>
              <tr>
                <td>Kecamatan</td>
                <td>{kecamatanLahanGaram}</td>
              </tr>
              <tr>
                <td>Kabupaten/Kota</td>
                <td>{kabupatenLahanGaram}</td>
              </tr>
              <tr>
                <td>Koordinat lahan garam</td>
                <td>{koordinatLahanGaram}</td>
              </tr>
              <tr>
                <td>Luas Lahan Garam</td>
                <td>{luasLahanGaram}</td>
              </tr>
              <tr>
                <td>Metode Produksi</td>
                <td>{metodeProduksi}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataBioPetani;
