import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultImg from "../../asset/img/defaultImage.png";

const NotifUbahStatus = ({ deskripsi, date, path, username, profile }) => {
  const navigate = useNavigate();
  const [color, setColor] = useState();
  useEffect(() => {
    if (deskripsi === "laporan masuk") return setColor("bg-button-laporan");
    else if (deskripsi === "ditindaklanjuti") return setColor("bg-button-ditindaklanjuti");
    else if (deskripsi === "selesai") return setColor("bg-button-selesai");
  }, [deskripsi]);
  return (
    <li
      className="py-3 hover:bg-gray-200 rounded-sm cursor-pointer px-5"
      onClick={() => navigate(path)}
    >
      <div className="flex items-center gap-3 mb-3">
        <img
          src={
            profile && profile !== null
              ? `${process.env.REACT_APP_API_STORAGE_URL}/${profile}`
              : DefaultImg
          }
          alt="profile"
          className="h-10 w-10 rounded-full object-cover object-center"
        />
        <p className="text-xs font-bold">{username}</p>
      </div>
      <div className={`flex items-center gap-1 my-1 ${color} w-28 rounded-md py-1 justify-center`}>
        <p className="text-xs text-white">{deskripsi}</p>
      </div>
      <p className="text-[10px] font-semibold">Perubahan status</p>
      <p className="text-[10px] text-hint mt-1">{date}</p>
      <hr className="mt-3" />
    </li>
  );
};

export default NotifUbahStatus;
