import React from "react";
import SkeletonRatarata from "../../Skeleton/SkeletonRatarata";

const Ratarata = (props) => {
  const { title, ratarata } = props;
  return (
    <div className="md:ml-8 md:my-3 mt-3 ml-5 w-full">
      {ratarata ? (
        <>
          <h5 className="text-sm font-semibold mb-2 text-header2">{title}</h5>
          <div className="pl-2 w-1/2 text-paragraf">
            <div className="flex items-center mb-2 justify-between">
              <p className="text-xs">Mingguan :</p>
              <p className="text-xs">{ratarata.weekly_average}</p>
            </div>
            <div className="flex items-center mb-2 justify-between">
              <p className="text-xs">Bulanan :</p>
              <p className="text-xs">{ratarata.monthly_average}</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-xs">Tahunan :</p>
              <p className="text-xs">{ratarata.yearly_average}</p>
            </div>
          </div>
        </>
      ) : (
        <SkeletonRatarata />
      )}
    </div>
  );
};

export default Ratarata;
