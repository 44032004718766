import React from "react";
import { useLocation, useParams } from "react-router-dom";
import FilterLaporan from "./FilterLaporan";
import FilterPengaturan from "./FilterPengaturan";
import FilterProduksi from "./FilterProduksi";
import FilterRiwayat from "./FilterRiwayat";
import FilterBio from "./FilterBio";
import { useEffect } from "react";
import { getFilterPostingan } from "../../api/api";
import { useState } from "react";

const Filter = (props) => {
  const {
    // userId,
    startDateOnChange,
    endDateOnChange,
    startDate,
    endDate,
    totalProduksi,
    totalLaporan,
    dateError,
    statusOnChange,
    selectedStatus,
    ratarata,
    totalPostingan,
    // role,
  } = props;

  const { role, userId, ketuaPetaniId, type } = useParams();

  const location = useLocation().pathname;
  return (
    <>
      {location === `/produksi/${role}/${userId}` && (
        <FilterProduksi
          startDateOnChange={startDateOnChange}
          endDateOnChange={endDateOnChange}
          startDate={startDate}
          endDate={endDate}
          totalProduksi={totalProduksi}
          totalLaporan={totalLaporan}
          dateError={dateError}
          userId={userId}
          ratarata={ratarata}
        />
      )}
      {location === `/laporan/${role}/${userId}` && (
        <FilterLaporan
          startDateOnChange={startDateOnChange}
          endDateOnChange={endDateOnChange}
          startDate={startDate}
          endDate={endDate}
          totalProduksi={totalProduksi}
          totalLaporan={totalLaporan}
          userId={userId}
          statusOnChange={statusOnChange}
          selectedStatus={selectedStatus}
          ratarata={ratarata}
          dateError={dateError}
        />
      )}
      {location === `/pengaturan/profil/${userId}` && <FilterPengaturan userId={userId} />}
      {location === `/pengaturan/kelompok/${userId}` && <FilterPengaturan userId={userId} />}
      {location === `/riwayat/${role}/${userId}` && (
        <FilterRiwayat userId={userId} totalPostingan={totalPostingan} />
      )}
      {location.includes("bio") && (
        <FilterBio
          userId={userId}
          startDateOnChange={startDateOnChange}
          endDateOnChange={endDateOnChange}
          startDate={startDate}
          endDate={endDate}
          ratarata={ratarata}
          dateError={dateError}
        />
      )}
    </>
  );
};

export default Filter;
