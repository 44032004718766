import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const StatusLaporan = (props) => {
  const { buttonColor, status, handleOpenSelectStatus } = props;
  const [color, setColor] = useState();

  useEffect(() => {
    if (status === "laporan masuk") return setColor("bg-button-laporan");
    else if (status === "ditindaklanjuti") return setColor("bg-button-ditindaklanjuti");
    else if (status === "selesai") return setColor("bg-button-selesai");
  }, [status]);
  return (
    <button
      className={`${color} capitalize rounded-md md:w-36 w-28 md:text-sm text-xs text-white md:h-8 h-7`}
      onClick={handleOpenSelectStatus}
    >
      {status === "laporan masuk" ? "masuk" : status}
    </button>
  );
};

export default StatusLaporan;
