import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { addKomentar } from "../../api/api";
import { getDetailPostinganWithKomen } from "../../api/generalApi";
import { addKomentarPemda } from "../../api/PemdaApi";
import { getDetailPostinganPublic } from "../../api/publicApi";
import ProduksiDetail from "../../Components/OnePost/ProduksiDetail";
import TokenHelper from "../../helpers/TokenHelper";

const PostinganDetailPublic = () => {
  const { id } = useParams();
  const [detailPostingan, setDetailPostingan] = useState();
  const [listKomentar, setListKomentar] = useState([]);
  const [komentar, setKomentar] = useState();
  const token = TokenHelper();

  useEffect(() => {
    if (!token) {
      getDetailPostinganPublic(id).then((result) => {
        setDetailPostingan(result);
      });
    } else {
      getDetailPostinganWithKomen(id).then((result) => {
        setDetailPostingan(result);
      });
    }
  }, [id, token]);

  useEffect(() => {
    if (detailPostingan) {
      setListKomentar(detailPostingan.komentars);
    }
  }, [detailPostingan]);

  //   const addKomentarHandler = async (e) => {
  //     e.preventDefault();

  //     const KomentarData = {
  //       type: "postingan",
  //       item_id: detailPostingan.id,
  //       deskripsi: komentar,
  //     };

  //     try {
  //       if (role === "ketua_petani") {
  //         await addKomentar(userId, KomentarData);
  //         return;
  //       } else {
  //         await addKomentarPemda(userId, KomentarData);
  //         return;
  //       }
  //     } catch (error) {
  //       console.log(KomentarData);
  //       throw error;
  //     }
  //   };

  //   const komentarOnChange = (e) => {
  //     setKomentar(e.target.value);
  //   };

  return (
    <div>
      <ProduksiDetail
        detailPostingan={detailPostingan}
        listKomentar={listKomentar}
        dontShowKomentarInput={true}
        // komentarOnChange={komentarOnChange}
        // addKomentarHandler={addKomentarHandler}
      />
    </div>
  );
};

export default PostinganDetailPublic;
