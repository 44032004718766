import React from "react";

const Waktu = (props) => {
  const { title, startDateOnChange, endDateOnChange, startDate, endDate, dateError } = props;
  return (
    <div className="md:ml-8 md:my-5 mt-3  ml-5 w-full overflow-auto">
      <h5 className="text-sm font-semibold mb-2 md:mr-10 text-header2">{title}</h5>
      <div className="pl-2 md:w-3/4 w-full text-paragraf">
        <div className="flex justify-between items-end mb-3 gap-3 mr-5">
          <label htmlFor="date" className="text-xs">
            Dari
          </label>
          <input
            type="date"
            id="date"
            value={startDate}
            onChange={startDateOnChange}
            className="md:mr-0 mr-5 border-t-0 border-x-0 border-b-2  border-gray-700 text-xs text-hint font-semibold focus:border-t-0 focus:border-x-0 focus:border-b-2 focus:border-gray-900 focus:ring-0 p-1"
          />
        </div>
        <div className="flex justify-between items-end mb-3 gap-3 mr-5">
          <label htmlFor="date" className="text-xs">
            Sampai
          </label>
          <input
            type="date"
            id="date"
            value={endDate}
            onChange={endDateOnChange}
            className="md:mr-0 mr-5 border-t-0 border-x-0 border-b-2 border-gray-700 text-xs text-hint font-semibold focus:border-t-0 focus:border-x-0 focus:border-b-2 focus:border-gray-900 focus:ring-0 p-1"
          />
        </div>
        {dateError && (
          <p className="text-xs text-red-600">
            *tanggal 'dari' tidak boleh lebih besar dari tanggal 'sampai'
          </p>
        )}
      </div>
    </div>
  );
};

export default Waktu;
