import { RequestApi } from "../helpers/RequestApi";
import TokenHelper from "../helpers/TokenHelper";

const getDetailPostinganWithKomen = async (id) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `general/postingan/detail/${id}`,
      {},
      headerToken,
      "Mencoba Menampilkan detail postingan with komen"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan detail postingan with komen", error);
    throw error;
  }
};

export { getDetailPostinganWithKomen };
