import DefaultImg from "../../asset/img/defaultImage.png";
import { useNavigate } from "react-router-dom";

const NotifKomen = ({ deskripsi, date, path, username, profile }) => {
  const navigate = useNavigate();
  return (
    <li
      className="py-3 hover:bg-gray-200 rounded-sm cursor-pointer px-5"
      onClick={() => navigate(path)}
    >
      <div className="flex items-center gap-3 mb-3">
        <img
          src={
            profile && profile !== null
              ? `${process.env.REACT_APP_API_STORAGE_URL}/${profile}`
              : DefaultImg
          }
          alt="profile"
          className="h-10 w-10 rounded-full object-cover object-center"
        />
        <div className="flex items-center gap-3">
          <h3 className="text-xs font-bold">{username}</h3>
          <p className="text-xs">berkomentar:</p>
        </div>
      </div>
      <p className="max-w-[100ch] text-xs whitespace-nowrap text-ellipsis overflow-hidden">
        {deskripsi}
      </p>
      <p className="text-[10px] text-hint mt-1">{date}</p>
      <hr className="mt-3" />
    </li>
  );
};

export default NotifKomen;
