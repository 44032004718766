import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useEffect } from "react";
import { useState } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "right",
      labels: {
        usePointStyle: true,
      },
    },
    title: {
      display: true,
      text: "Garam 30 hari terakhir",
    },
  },
};

export const data = {
  labels: ["Terjual", "Tersedia"],
  datasets: [
    {
      label: "# of Votes",
      data: [100, 1300],
      backgroundColor: ["rgba(215, 39, 39, 0.8)", "rgba(0, 102, 141, 0.57)"],
      // borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
      borderWidth: 1,
    },
  ],
};

export function PieChartPemda(props) {
  return <Pie data={props.dataGrafik} options={options} />;
}
