import "./App.css";
import LoginPage from "./pages/public/LoginPage";
import ProduksiSubmit from "./pages/ketuaPetani/ProduksiSubmit";
import LaporanSubmit from "./pages/ketuaPetani/LaporanSubmit";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/public/Home";
import LupaPassword from "./pages/public/LupaPassword";
import KirimKodePemulihan from "./pages/public/KirimKodePemulihan";
import PengaturanKelompok from "./pages/ketuaPetani/PengaturanKelompok";
import PengaturanProfil from "./pages/ketuaPetani/PengaturanProfil";
import ProtectedRoute from "./middleware/ProtectedRoute";
import ProduksiDetailPemda from "./pages/pemda/ProduksiDetailPemda";
import Notifikasi from "./Components/Notifikasi";
import NotFound from "./pages/NotFound";
import LaporanDetailPemda from "./pages/pemda/LaporanDetailPemda";
import BioPetani from "./pages/public/BioPetani";
import PostinganDetailPublic from "./pages/public/PostinganDetailPublic";
import ProduksiDetailPetani from "./pages/ketuaPetani/ProduksiDetailPetani";
import RiwayatPage from "./pages/RiwayatPage";
import MasalahPage from "./pages/MasalahPage";
import ProduksiPage from "./pages/ProduksiPage";
import LaporanDetailPetani from "./pages/ketuaPetani/LaporanDetailPetani";
import ProduksiUpdate from "./pages/ketuaPetani/ProduksiUpdate";
import LaporanUpdate from "./pages/ketuaPetani/LaporanUpdate";
import PengaturanPemda from "./pages/pemda/PengaturanPemda";
import NewPassword from "./pages/public/NewPassword";
import CekEmail from "./pages/public/CekEmail";
import Bantuan from "./pages/public/Bantuan";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/lupa-password" element={<LupaPassword />} />
        <Route path="/kirim-kode-pemulihan" element={<KirimKodePemulihan />} />
        <Route path="/reset-password" element={<NewPassword />} />
        <Route path="/cek-email" element={<CekEmail />} />

        <Route path="/:role/:userId" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route
          path={`/submit/produksi/:role/:userId`}
          element={
            <ProtectedRoute>
              <ProduksiSubmit />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/submit/laporan/:role/:userId`}
          element={
            <ProtectedRoute>
              <LaporanSubmit />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/update/produksi/:id/:role/:userId`}
          element={
            <ProtectedRoute>
              <ProduksiUpdate />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/update/laporan/:id/:role/:userId`}
          element={
            <ProtectedRoute>
              <LaporanUpdate />
            </ProtectedRoute>
          }
        />

        <Route path={`/produksi/:role/:userId/detail/:id`} element={<ProduksiDetailPetani />} />
        <Route path={`/laporan/:role/:userId/detail/:id`} element={<LaporanDetailPetani />} />
        <Route path={`/pengaturan/profil/petani/:role/:userId`} element={<PengaturanProfil />} />
        <Route
          path={`/pengaturan/kelompok/petani/:role/:userId`}
          element={<PengaturanKelompok />}
        />

        <Route path="/notifikasi" element={<Notifikasi />} />
        <Route path="*" element={<NotFound />} />

        {/* Pemda */}
        <Route path="/laporan/:role/:userId" element={<MasalahPage />} />
        <Route path="/produksi/:role/:userId" element={<ProduksiPage />} />
        <Route path="/riwayat/:role/:userId" element={<RiwayatPage />} />
        <Route path="/produksi/:role/:userId/detail/:type/:id" element={<ProduksiDetailPemda />} />
        <Route path="/laporan/:role/:userId/detail/:type/:id" element={<LaporanDetailPemda />} />

        {/* Pemda dan ketua petani */}
        <Route path="/bio/petani/:role/:ketuaPetaniId/:userId/:type" element={<BioPetani />} />
        <Route
          path="/bio/petani/:role/:ketuaPetaniId/:userId/:type/detail/:id"
          element={<PostinganDetailPublic />}
        />
        <Route path="/pengaturan/:role/:userId" element={<PengaturanPemda />} />

        {/* public */}
        <Route path={`/bio/petani/:ketuaPetaniId/:type`} element={<BioPetani />} />
        <Route
          path="bio/petani/:ketuaPetaniId/produksi/detail/:id"
          element={<PostinganDetailPublic />}
        />
        <Route path={`/bantuan`} element={<Bantuan />} />
      </Routes>
    </Router>
  );
}

export default App;
