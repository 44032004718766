import { Button, IconButton } from "@material-tailwind/react";
import React from "react";
import LayoutForgotPassword from "../../Components/Auth/LayoutForgotPassword";
import MessageIcon from "../../asset/icon/pesan-icon.svg";
import WaIcon from "../../asset/icon/wa-icon.svg";

const KirimKodePemulihan = () => {
  return (
    <LayoutForgotPassword>
      <div className="w-72">
        <Button
          ripple={true}
          variant="outlined"
          className="w-full flex gap-5 py-0 h-12 items-center mb-3"
        >
          <img src={MessageIcon} alt="sms icon" className="" />
          <p className="text-start lowercase font-normal text-paragraf">
            Kirim melalui SMS ke +62********
          </p>
        </Button>
        <Button
          ripple={true}
          variant="outlined"
          className="w-full flex gap-5 py-0 items-center h-12"
        >
          <img src={WaIcon} alt="sms icon" className="" />
          <p className="text-start lowercase font-normal text-paragraf">
            Kirim melalui whatsapp ke +62********
          </p>
        </Button>
      </div>
    </LayoutForgotPassword>
  );
};

export default KirimKodePemulihan;
