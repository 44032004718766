import React from "react";
import NavigationBar from "../NavigationBar";
// import Filter from "../Filter";
import Filter from "../Filter/index";
import { useLocation } from "react-router-dom";
import FilterProduksi from "../Filter/FilterProduksi";
import FilterLaporan from "../Filter/FilterLaporan";
import FilterPengaturan from "../Filter/FilterPengaturan";

const LayoutAllPostPetani = (props) => {
  const location = useLocation().pathname;
  const {
    title,
    children,
    userId,
    startDateOnChange,
    endDateOnChange,
    startDate,
    endDate,
    totalProduksi,
    totalLaporan,
    dateError,
    statusOnChange,
    selectedStatus,
    ratarata,
    totalPostingan,
    searchOnChange,
  } = props;

  return (
    <>
      <NavigationBar
        searchOnChange={searchOnChange}
        startDateOnChange={startDateOnChange}
        endDateOnChange={endDateOnChange}
        startDate={startDate}
        endDate={endDate}
        totalProduksi={totalProduksi}
        totalLaporan={totalLaporan}
        dateError={dateError}
        statusOnChange={statusOnChange}
        selectedStatus={selectedStatus}
        ratarata={ratarata}
        totalPostingan={totalPostingan}
      />
      <div className="pt-16 flex grid-container">
        {/* Left Konten */}
        <div className="fixed bg-white">
          <Filter
            userId={userId}
            startDateOnChange={startDateOnChange}
            endDateOnChange={endDateOnChange}
            startDate={startDate}
            endDate={endDate}
            totalProduksi={totalProduksi}
            totalLaporan={totalLaporan}
            dateError={dateError}
            statusOnChange={statusOnChange}
            selectedStatus={selectedStatus}
            ratarata={ratarata}
            totalPostingan={totalPostingan}
          />
        </div>

        <div className="w-full">
          {/* Right Konten */}

          <div className="w-full header fixed bg-white z-10 lg:ml-[300px] ">
            <div className="lg:h-24 lg:justify-start justify-center h-16 text-center w-full flex items-center  shadow-md md:pl-10">
              <h1 className="lg:text-3xl text-2xl font-semibold">{title}</h1>
            </div>
            {/* <FilterPetani /> */}
          </div>

          {/* post card */}
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

export default LayoutAllPostPetani;
