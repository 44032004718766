import React from "react";

const Total = (props) => {
  const { totalPenjualan, totalProduksi } = props;
  return (
    <div className="flex gap-10">
      <div className="mt-5">
        <p className="text-xs">Total Produksi</p>
        <p className="text-2xl font-bold">{totalProduksi} Kg</p>
      </div>
      <div className="mt-5">
        <p className="text-xs">Total Penjualan</p>
        <p className="text-2xl font-bold">{totalPenjualan} Kg</p>
      </div>
    </div>
  );
};

export default Total;
