import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const DatePostCard = (props) => {
  const { date } = props;
  const [tahun, setTahun] = useState();
  const [bulan, setBulan] = useState();
  const [hari, setHari] = useState();

  useEffect(() => {
    if (date) {
      const [tahun, bulanAngka, hari] = date.split("-");
      const namaBulan = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const namaBulanString = namaBulan[bulanAngka - 1];
      setTahun(tahun);
      setBulan(namaBulanString);
      setHari(hari);
    }
  }, [date]);

  return (
    <div className="flex justify-between pt-1 pb-1">
      <p className="text-[10px] text-hint">
        {hari} {bulan} {tahun}
      </p>
    </div>
  );
};

export default DatePostCard;
