import React from "react";
import BackIcon from "../../asset/icon/back-icon.svg";
import HomeIcon from "../../asset/icon/home-icon.svg";
import { useNavigate } from "react-router-dom";

const Bantuan = () => {
  const navigate = useNavigate();

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="w-full">
      <div className="md:h-24 h-16 w-full flex items-center justify-between md:px-0 px-5 shadow-md bg-white ">
        <div className="flex items-center gap-5">
          <img
            src={BackIcon}
            alt=""
            className="md:ml-20 lg:ml-64 cursor-pointer w-6 h-6"
            onClick={goBack}
          />
          <h1 className="md:text-3xl text-2xl font-semibold">Bantuan</h1>
        </div>
        <img
          src={HomeIcon}
          alt="home icon"
          className="w-6 h-6 md:mr-20 lg:mr-64 cursor-pointer"
          onClick={() => navigate("/")}
        />
      </div>

      <div className="md:mx-20 lg:mx-64 mx-5 mt-5 mb-10 flex flex-col gap-5">
        <p className="text-hint text-sm">Pertanyaan yang sering muncul beserta solusinya</p>
        <div>
          <p className="text-md font-semibold">Bagaimana cara untuk mempunyai akun?</p>
          <p className="text-paragraf">
            Hanya ketua kelompok petani garam di kabupaten Maros yang dapat mempunyai akun garamqu.
            Jika Anda adalah ketua tersebut, Anda dapat mengajukan kelompok tani Anda kepada kami
            (jika kelompok tani Anda belum bergabung)
          </p>
        </div>
        <div>
          <p className="text-md font-semibold">Bagaimana cara melaporkan hasil produksi garam?</p>
          <p className="text-paragraf">
            Anda dapat login lalu menekan tombol warna biru di atas (di samping kolom pencarian)
            lalu Anda dapat memasukkan data yang diperlukan
          </p>
        </div>
        <div>
          <p className="text-paragraf">
            <span className="text-md font-semibold">Masih Ada Pertanyaan? </span>Anda dapat
            menghubungi kami melalui WhatsApp
          </p>
          <a
            href="https://wa.me/+6281524025278"
            rel="noreferrer"
            target="_blank"
            className="text-cyan-600 underline"
          >
            Hubungi Kami
          </a>
        </div>
      </div>
    </div>
  );
};

export default Bantuan;
