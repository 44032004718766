import React, { useState } from "react";
import LogoUnhas from "../../asset/logo/logo-unhas.png";
import LogoMaros from "../../asset/logo/logo-maros.png";
import LogoPetani from "../../asset/logo/logo-petani.png";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../api/apiAuth";
import InputLogin from "./InputLogin";
import { Spinner } from "@material-tailwind/react";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isFail, setIsFail] = useState(false);

  const loginHandler = async (e) => {
    e.preventDefault();
    const credential = {
      email: email,
      password: password,
    };

    try {
      setLoader(true);
      const response = await loginUser(credential);
      const role = response.login_user.role;
      let userId;
      if (role === "ketua_petani") {
        userId = response.login_user.ketua_petani_id;
      } else {
        userId = response.login_user.user_id;
      }
      if (response) {
        setLoader(false);
      }
      if (response.login_user.role === "ketua_petani") {
        navigate(`/${role}/${userId}`);
      } else {
        navigate(`/riwayat/${role}/${userId}`);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
      setIsFail(true);
    }
  };

  const emailOnChange = (e) => {
    setEmail(e.target.value);
  };
  const passOnChange = (e) => {
    setpassword(e.target.value);
  };
  return (
    <div className="md:h-[550px] h-screen sm:w-[400px] sm:h-[650px] sm:border border-gray-600 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl">
      <div className="h-10 flex justify-center gap-5 mt-16">
        <img src={LogoUnhas} alt="" />
        <img src={LogoMaros} alt="" />
        <img src={LogoPetani} alt="" />
      </div>
      <div>
        <h1 className="text-2xl mt-5 font-semibold text-center text-header0">Masuk!</h1>
        <h3 className="text-center text-sm">
          Selamat datang di <b>Garamqu</b>
        </h3>
      </div>
      <div className="flex justify-center mt-5">
        <form onSubmit={loginHandler}>
          <InputLogin onChangeHandler={emailOnChange} value={email} type="email" title="email" />
          <InputLogin
            onChangeHandler={passOnChange}
            value={password}
            type="password"
            title="password"
          />
          <button
            className="w-72 bg-buttonColor hover:bg-buttonColor-hover active:bg-buttonColor-active h-8 rounded-md text-white font-semibold mt-5 disabled:opacity-80 relative"
            type="submit"
            disabled={loader}
          >
            Masuk
            {loader && (
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Spinner />
              </div>
            )}
          </button>
          {isFail && <p className="text-xs text-red-600 mt-2">email/password salah!</p>}
          <div className="gap-16 flex justify-center mt-5">
            <div>
              <Link to={"/lupa-password"} className="text-xs text-cyan-600 underline">
                Lupa kata sandi
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
