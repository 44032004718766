import React from "react";

import BookIcon from "../../../asset/icon/Book.svg";
import ErrorIcon from "../../../asset/icon/Error.svg";
import CircledMenuIcon from "../../../asset/icon/circled-menu.svg";
import { Link, useLocation, useParams } from "react-router-dom";

const Riwayat = (props) => {
  const location = useLocation().pathname;
  const { title } = props;
  const { userId, role } = useParams();
  return (
    <div className="md:ml-8 md:my-5 w-full ml-5 mt-3">
      <h5 className="text-sm font-semibold mb-2 text-header2">{title}</h5>
      <div className="pl-2 text-paragraf">
        <Link
          to={`/riwayat/${role}/${userId}`}
          className={`flex items-center mb-3 gap-1 hover:font-bold ${
            location === `/riwayat/${role}/${userId}` && "font-bold"
          }`}
        >
          <img src={CircledMenuIcon} alt="Circled Menu Icon " className="h-4" />
          <p className="text-xs">Semua</p>
        </Link>
        <Link
          to={`/produksi/${role}/${userId}`}
          className={`flex items-center mb-3 gap-1 hover:font-bold ${
            location === `/produksi/${role}/${userId}` && "font-bold"
          }`}
        >
          <img src={BookIcon} alt="Book Icon" className="h-4" />
          <p className="text-xs">Produksi</p>
        </Link>
        <Link
          to={`/laporan/${role}/${userId}`}
          className={`flex items-center gap-1 hover:font-bold ${
            location === `/laporan/${role}/${userId}` && "font-bold"
          }`}
        >
          <img src={ErrorIcon} alt="Laporan Icon" className="h-4" />
          <p className="text-xs">Masalah</p>
        </Link>
      </div>
    </div>
  );
};

export default Riwayat;
