import React from "react";
import { useLocation } from "react-router-dom";

const DeskripsiSubmit = (props) => {
  const { deskripsi, onChange, label, defaultValue } = props;
  const location = useLocation().pathname;
  return (
    <div className="mt-5">
      <p className="text-xs mb-3">
        {label}{" "}
        <span className="font-semibold">
          ( {location.includes("produksi") ? "tidak wajib diisi" : "wajib diisi"} )
        </span>
      </p>
      <textarea
        name=""
        id=""
        className="w-full h-36 p-3 rounded-xl border border-black active:ring-1 active:ring-gray-500 active:border-gray-500 focus:ring-1 focus:ring-blue-gray-700 focus:border-gray-500 text-xs"
        value={deskripsi}
        defaultValue={defaultValue}
        onChange={onChange}
      ></textarea>
    </div>
  );
};

export default DeskripsiSubmit;
