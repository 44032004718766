import { Alert } from "@material-tailwind/react";

const AlertSuccess = ({ deskripsi }) => {
  return (
    <div className="fixed bottom-10 w-[500px] left-1/2 transform -translate-x-1/2 z-50">
      <Alert color="green" className="flex justify-center">
        <p className="text-center">{deskripsi}</p>
      </Alert>
    </div>
  );
};

export default AlertSuccess;
