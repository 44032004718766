import React from "react";
import DefaultImg from "../../asset/img/defaultImage.png";

const Komentar = (props) => {
  const { image, user, deskripsi, date } = props;
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const options = { month: "long" };
  const monthName = new Intl.DateTimeFormat("id-ID", options).format(dateObject); // Bulan dimulai dari 0 (Januari) hingga 11 (Desember), jadi tambahkan 1.
  const day = dateObject.getDate();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();

  const formattedTime = (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes;

  return (
    <div className="mt-10">
      <div className="flex items-center gap-3">
        <img
          src={
            image && image !== null
              ? `${process.env.REACT_APP_API_STORAGE_URL}/${image}`
              : DefaultImg
          }
          alt=""
          className="h-9 w-9 object-cover object-center rounded-full"
        />
        <h1 className="text-xl font-bold">{user}</h1>
      </div>
      <div className="mt-3">
        <p className="md:text-sm text-xs text-paragraf break-words">{deskripsi}</p>
        <p className="text-xs mt-3 text-hint">
          {year} {monthName} {day} | {formattedTime}
        </p>
      </div>
      <div className="w-full border border-gray-700 mt-10"></div>
    </div>
  );
};

export default Komentar;
