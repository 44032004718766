import { Spinner } from "@material-tailwind/react";
import React from "react";

const ButtonSubmit = (props) => {
  const { btnColor, buttonType, loader } = props;
  return (
    <button
      className={`md:px-5 px-3 h-12 text-sm ${btnColor} text-white rounded-lg font-medium relative`}
      disabled={loader}
      // onClick={() =>
      //   location === "/submit/produksi" ? navigate("/produksi") : navigate("/laporan")
      // }
      // onClick={handlerAddPostingan}
    >
      {buttonType}

      {loader && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Spinner />
        </div>
      )}
    </button>
  );
};

export default ButtonSubmit;
