import React from "react";
import DefaultImg from "../../asset/img/defaultImage.png";

function PengaturanProfilPemda(props) {
  const {
    fullName,
    gender,
    tempatLahir,
    tlp,
    tanggalLahir,
    jalan,
    kelurahan,
    kecamatan,
    kabupaten,
    provinsi,
    profile,
    namaPanggilan,
    profileOnChange,
    isLoading,
    isError,
    isSuccess,
  } = props;

  return (
    <div className=" md:translate-y-24 md:flex overflow-x-hidden overflow-y-hidden md:mb-0 mb-5 ">
      <div className="md:w-1/2 md:border-r-2 border-gray-600 mt-3 grid place-content-center">
        <div className="flex justify-center">
          <div className="md:mt-5">
            {/* <img
              src={`${process.env.REACT_APP_API_STORAGE_URL}/${profile}`}
              alt="Avatar"
              className="mx-auto w-48 h-48 object-cover rounded-full md:mt-0 mt-20"
            /> */}

            {!isLoading ? (
              <img
                src={
                  profile !== null
                    ? `${process.env.REACT_APP_API_STORAGE_URL}/${profile}`
                    : DefaultImg
                }
                alt="Avatar"
                className="mx-auto w-48 h-48 object-cover rounded-full md:mt-0 mt-20"
              />
            ) : (
              <div className="mx-auto w-48 h-48 object-cover rounded-full bg-gray-400 animate-pulse"></div>
            )}

            <div className="mt-10 w-52 py-2 rounded-xl text-lg border border-gray-300 flex justify-center cursor-pointer hover:bg-gray-200">
              <input type="file" id="foto" className="hidden" onChange={profileOnChange} />
              <label htmlFor="foto" className="cursor-pointer">
                Ganti Foto Profil
              </label>
            </div>
            <div className="text-xs mt-3 text-center">
              <p>Maksimal 10 mb</p>
              <p>jenis foto PNG, JPG, JPEG</p>
            </div>
          </div>
        </div>
      </div>

      {/* right kontent */}
      <div className="md:w-1/2 ml-3 w-full ">
        <div className="md:mt-16 mt-8 md:ml-10 ms-5">
          <div className="md:mb-16 mb-8 ">
            <h3 className="font-semibold md:text-lg text-sm mb-3">Data Diri</h3>
            <table className="w-full max-auto table-fixed sm:text-sm text-xs">
              <tr>
                <td>Nama Lengkap</td>
                <td>{fullName}</td>
              </tr>
              <tr>
                <td>Nama Panggilan</td>
                <td>{namaPanggilan}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>{gender}</td>
              </tr>
              <tr>
                <td>Tempat Lahir</td>
                <td>{tempatLahir}</td>
              </tr>
              <tr>
                <td>Tanggal Lahir</td>
                <td>{tanggalLahir}</td>
              </tr>
              <tr>
                <td>No.Telp</td>
                <td>{tlp}</td>
              </tr>
            </table>
          </div>
          <div>
            <h3 className="font-semibold md:text-lg text-sm mb-3">Alamat Rumah</h3>
            <table className="w-full max-auto table-fixed  sm:text-sm text-xs">
              <tr>
                <td>Jalan</td>
                <td>{jalan}</td>
              </tr>
              <tr>
                <td>Kelurahan/Dusun</td>
                <td>{kelurahan}</td>
              </tr>
              <tr>
                <td>Kecamatan</td>
                <td>{kecamatan}</td>
              </tr>
              <tr>
                <td>Kabupaten/Kota</td>
                <td>{kabupaten}</td>
              </tr>
              <tr>
                <td>Provinsi</td>
                <td>{provinsi}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PengaturanProfilPemda;
