import React, { useEffect, useState } from "react";
// import PostCard from "../Components/PostCard";

import LayoutAllPostPetani from "../../Components/LayoutAllPostPetani/LayoutAllPostPetani";
import PostCardProduksi from "../../Components/PostCard/PostCardProduksi";
import LayoutPostCard from "../../Components/PostCard/LayoutPostCard";
import Modals from "../../Components/Modals/Modals";
import { getAllProduksi, getFilterPostingan, getPostinganAverage } from "../../api/api";
import { useParams } from "react-router-dom";
import SkeletonPostCard from "../../Components/Skeleton/SkeletonPostCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { getFilterPostinganPublic } from "../../api/publicApi";
import _ from "lodash";

const ProduksiAllPost = (props) => {
  // const { userId } = props;
  const { userId, role } = useParams();
  const [post, setPost] = useState([]);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState([]);
  const [average, setAverage] = useState();
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [alertPop, setAlertPop] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [search, setSearch] = useState("");
  const [postinganId, setPostinganId] = useState();

  const handleOpen = (id) => {
    setOpen(!open);
    setPostinganId(id);
  };

  useEffect(() => {
    if (startDate || endDate || search) {
      if (startDate > endDate) {
        setAlertPop(true);
        return;
      }
      setAlertPop(false);
      setHasMoreData(true);
      setLoader(true);
      getFilterPostinganPublic(startDate, endDate, userId, page, search).then((result) => {
        setPost((prev) => [...prev, ...result.postingans]);

        if (result.pagination.last_page <= result.pagination.current_page) {
          setHasMoreData(false);
        }
        setAlertPop(false);
        setLoader(false);
      });

      return;
    }
    try {
      setHasMoreData(true);
      setLoader(true);
      getAllProduksi(userId, page).then((result) => {
        setPost((prev) => [...prev, ...result.postingans]);

        if (result.pagination.last_page <= result.pagination.current_page) {
          setHasMoreData(false);
        }
        setLoader(false);
      });

      getPostinganAverage(userId).then((result) => {
        setAverage(result);
      });
    } catch (error) {
      console.error(error);
    }
  }, [page, endDate, startDate, userId, search]);
  const debouncedSearchOnChange = _.debounce((value) => {
    setSearch(value);
    setPage(1);
    setPost([]);
  }, 500);

  const searchOnChange = (e) => {
    const value = e.target.value;
    debouncedSearchOnChange(value);
  };

  const startDateOnChange = (e) => {
    setStartDate(e.target.value);
    setPage(1);
    setPost([]);
  };
  const endDateOnChange = (e) => {
    setEndDate(e.target.value);
    setPage(1);
    setPost([]);
  };
  // useEffect(() => {
  //   if (startDate || endDate) {
  //     setLoader(true);
  //     if (endDate >= startDate) {
  //       getFilterPostinganPublic(startDate, endDate, userId, page).then((result) => {
  //         setPost(result.postingans);
  //         setAlertPop(false);
  //         setLoader(false);
  //       });
  //     } else {
  //       setAlertPop(true);
  //       setEndDate("");
  //       setLoader(false);
  //     }
  //   }
  // }, [startDate, endDate, userId, page]);

  // useEffect(() => {
  //   getPostinganAverage(userId).then((result) => {
  //     setAverage(result);
  //   });
  // }, [userId]);

  const displayLoader = () => {
    return (
      <>
        <SkeletonPostCard />
        <SkeletonPostCard />
      </>
    );
  };

  return (
    <>
      <LayoutAllPostPetani
        title="Produksi Garam"
        userId={userId}
        startDateOnChange={startDateOnChange}
        endDateOnChange={endDateOnChange}
        startDate={startDate}
        endDate={endDate}
        dateError={alertPop}
        ratarata={average}
        searchOnChange={searchOnChange}
      >
        <div className="lg:ml-[300px] lg:mt-24 mt-16 mb-10">
          <InfiniteScroll
            dataLength={post.length}
            next={() => setPage((prev) => prev + 1)}
            loader={displayLoader()}
            hasMore={hasMoreData}
          >
            <LayoutPostCard image={image}>
              <div className="flex justify-center right-0">
                <Modals open={open} handler={handleOpen} id={postinganId} type="postingan"></Modals>
              </div>

              {post?.map((data, index) => {
                return (
                  <PostCardProduksi
                    key={index}
                    id={data.id}
                    handleOpen={handleOpen}
                    total_penjualan={data.total_penjualan}
                    total_produksi={data.total_produksi}
                    deskripsi={data.deskripsi}
                    tanggal_produksi={data.tanggal_produksi}
                    image={data.media.length !== 0 && data.media[0].file_name}
                    total_views={data.total_views}
                    total_komen={data.komentars_count}
                    read_by_pemda={data.read_by_pemda}
                    read_by_unhas={data.read_by_unhas}
                  />
                );
              })}
              {!loader && post.length === 0 && (
                <div className="m-10">
                  <p>Data tidak ditemukan....</p>
                </div>
              )}
            </LayoutPostCard>
          </InfiniteScroll>
        </div>
      </LayoutAllPostPetani>
    </>
  );
};

export default ProduksiAllPost;
