import React from "react";
import LayoutForgotPassword from "../../Components/Auth/LayoutForgotPassword";
import { Button, Input, Spinner } from "@material-tailwind/react";
import SuccessIcon from "../../asset/icon/success.png";
import ErrorIcon from "../../asset/icon/warning.png";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { forgotPassword, resetPassword } from "../../api/apiAuth";
import AlertModal from "../../Components/Modals/AlertModal";
import OpenEyeIcon from "../../asset/icon/open-eye.svg";
import CloseEyeIcon from "../../asset/icon/close-eye.svg";

const NewPassword = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [tokenInvalid, setTokenInvalid] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [changeType, setChangeType] = useState("password");
  const [changeTypePassConfirm, setChangeTypePassConfirm] = useState("password");

  const currentUrl = window.location.href;

  const navigate = useNavigate();

  // Membuat objek URL dari URL saat ini
  const url = new URL(currentUrl);

  // Mengambil nilai dari parameter 'token' dan 'email'

  //   const navigate = useNavigate();

  const resetPasswordHandler = async () => {
    // setToken(url.searchParams.get("token"));
    // setEmail(url.searchParams.get("email"));
    if (password !== confirmPassword) {
      setIsError(true);
      return;
    }

    const data = {
      email: url.searchParams.get("email"),
      token: url.searchParams.get("token"),
      password: password,
      password_confirmation: confirmPassword,
    };

    setLoader(true);

    try {
      const res = await resetPassword(data);
      if (res) {
        setDialogOpen(true);
        setLoader(false);
        // if (dialogOpen === false) {
        //   navigate("/login");
        // }
      }

      // setDialogOpen(true);
    } catch (error) {
      setLoader(false);
      setTokenInvalid(true);
    }
  };

  const passwordOnChange = (e) => {
    setPassword(e.target.value);
  };
  const confirmPasswordOnChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const dialogOpenHandler = () => {
    setDialogOpen(false);
    navigate("/login");
  };

  const changeTypePasswordHandler = () => {
    if (changeType === "password") {
      setChangeType("text");
    }
    if (changeType === "text") {
      setChangeType("password");
    }
  };

  const changeTypePasswordConfirmHandler = () => {
    if (changeTypePassConfirm === "password") {
      setChangeTypePassConfirm("text");
    }
    if (changeTypePassConfirm === "text") {
      setChangeTypePassConfirm("password");
    }
  };

  return (
    <LayoutForgotPassword title="Password Baru">
      <form>
        <div className="w-72 h-8 relative">
          <Input label="password" type={changeType} value={password} onChange={passwordOnChange} />
          <img
            src={changeType === "password" ? CloseEyeIcon : OpenEyeIcon}
            alt=""
            className="cursor-pointer absolute top-1/2 transform right-3 h-5 w-5"
            onClick={changeTypePasswordHandler}
          />
        </div>
        <div className="w-72 h-8 mt-5 relative">
          <Input
            label="konfirmasi password"
            type={changeTypePassConfirm}
            value={confirmPassword}
            onChange={confirmPasswordOnChange}
          />
          <img
            src={changeTypePassConfirm === "password" ? CloseEyeIcon : OpenEyeIcon}
            alt=""
            className="cursor-pointer absolute top-1/2 transform right-3 h-5 w-5 "
            onClick={changeTypePasswordConfirmHandler}
          />
        </div>
        <Button
          className="bg-buttonColor w-full mt-5 rounded-md"
          onClick={resetPasswordHandler}
          disabled={loader}
        >
          Selanjutnya
          {loader && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Spinner />
            </div>
          )}
        </Button>
        {isError && (
          <p className="text-xs text-red-700 mt-2">password dan konfirmasi password tidak sama</p>
        )}
      </form>
      <AlertModal
        title="Password Berhasil Diubah"
        dialogOpen={dialogOpen}
        dialogOpenHandler={dialogOpenHandler}
        icon={SuccessIcon}
      />

      <AlertModal
        title="Token Invalid"
        dialogOpen={tokenInvalid}
        dialogOpenHandler={() => setTokenInvalid(false)}
        icon={ErrorIcon}
      />
    </LayoutForgotPassword>
  );
};

export default NewPassword;
