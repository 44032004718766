import React from "react";
import SkeletonRatarata from "../Skeleton/SkeletonRatarata";
import Riwayat from "./Fragments/Riwayat";
import TotalLaporan from "./Fragments/TotalLaporan";
import LayoutFilter from "./LayoutFilter";

const FilterRiwayat = (props) => {
  const { userId, totalPostingan } = props;
  return (
    <LayoutFilter>
      {totalPostingan ? (
        <>
          <Riwayat title="RIWAYAT LAPORAN" userId={userId} />
          <TotalLaporan
            title="TOTAL SELURUH RIWAYAT LAPORAN"
            postinganTotal={totalPostingan.total_postingan}
            laporanTotal={totalPostingan.total_laporan}
          />
        </>
      ) : (
        <div className="m-5">
          <SkeletonRatarata />
          <SkeletonRatarata />
        </div>
      )}
    </LayoutFilter>
  );
};

export default FilterRiwayat;
