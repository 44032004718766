import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllLaporan,
  getFilterLaporan,
  getFilterPostingan,
  getLaporanAverage,
} from "../../api/api";
// import PostCard from "../Components/PostCard";
import LayoutAllPostPetani from "../../Components/LayoutAllPostPetani/LayoutAllPostPetani";
import Modals from "../../Components/Modals/Modals";
import LayoutPostCard from "../../Components/PostCard/LayoutPostCard";
import PostCardLaporan from "../../Components/PostCard/PostCardLaporan";
import SkeletonPostCard from "../../Components/Skeleton/SkeletonPostCard";
import _ from "lodash";

const LaporanAllPost = (props) => {
  const [post, setPost] = useState([]);
  // const { userId } = props;
  const { userId } = useParams();
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  // const [laporanMasuk, setLaporanMasuk] = useState();
  const [selectedStatus, setSelectedStatus] = useState("semua");

  const [alertPop, setAlertPop] = useState(false);
  // const [status, setStatus] = useState();

  const [average, setAverage] = useState();
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [search, setSearch] = useState("");
  const [postinganId, setPostinganId] = useState();
  const [filterParams, setFilterParams] = useState(["?"]);

  const currentUrl = window.location.href;

  const url = new URL(currentUrl);

  const navigate = useNavigate();

  const handleOpen = (id) => {
    // e.preventDefault();
    setOpen(!open);
    setPostinganId(id);
  };

  useEffect(() => {
    setLoader(true);
    let status = selectedStatus;
    if (status === "semua") {
      status = "";
    }

    if (startDate || endDate || status || search) {
      // if (selectedStatus === "semua" && search <= 1) {
      //   getAllLaporan(userId, page).then((result) => {
      //     setPost((prev) => [...prev, ...result.laporans]);
      //     if (result.pagination.last_page <= result.pagination.current_page) {
      //       setHasMoreData(false);
      //     }
      //     setLoader(false);
      //   });
      // } else {
      if (startDate > endDate) {
        setAlertPop(true);
        return;
      }
      setAlertPop(false);
      getFilterLaporan(startDate, endDate, status, userId, page, search).then((result) => {
        setPost((prev) => [...prev, ...result.laporans]);
        if (result.pagination.last_page <= result.pagination.current_page) {
          setHasMoreData(false);
        }
        setLoader(false);
      });
      // }
      return;
    }
    try {
      setLoader(true);
      getAllLaporan(userId, page).then((result) => {
        setPost((prev) => [...prev, ...result.laporans]);
        if (result.pagination.last_page <= result.pagination.current_page) {
          setHasMoreData(false);
        }
        setLoader(false);
      });
    } catch (error) {
      console.error(error);
    }
  }, [startDate, endDate, selectedStatus, userId, page, search]);

  const debouncedSearchOnChange = _.debounce((value) => {
    setSearch(value);
    setPage(1);
    setPost([]);
  }, 500);

  const searchOnChange = (e) => {
    const value = e.target.value;
    debouncedSearchOnChange(value);
  };

  const statusOnChange = (event) => {
    let status = event.target.value;
    if (status === "semua") {
      setSelectedStatus("");
    }
    setSelectedStatus(status);
    // setFilterParams((prev) => [...prev, status]);
    // navigate(`${filterParams}`);
    setPage(1);
    setPost([]);
  };
  const startDateOnChange = (e) => {
    setStartDate(e.target.value);
    setPage(1);
    setPost([]);
  };
  const endDateOnChange = (e) => {
    setEndDate(e.target.value);
    setPage(1);
    setPost([]);
  };

  useEffect(() => {
    getLaporanAverage(userId).then((result) => {
      setAverage(result);
    });
  }, [userId]);

  const displayLoader = () => {
    return (
      <>
        <SkeletonPostCard />
        <SkeletonPostCard />
      </>
    );
  };

  return (
    <>
      <LayoutAllPostPetani
        title="Masalah Garam"
        userId={userId}
        startDateOnChange={startDateOnChange}
        endDateOnChange={endDateOnChange}
        startDate={startDate}
        endDate={endDate}
        statusOnChange={statusOnChange}
        selectedStatus={selectedStatus}
        ratarata={average}
        dateError={alertPop}
        searchOnChange={searchOnChange}
      >
        <div className="flex justify-center right-0">
          <Modals open={open} handler={handleOpen} id={postinganId} type="laporan"></Modals>
        </div>
        <div className="lg:ml-[300px] lg:mt-24 mt-16 mb-10">
          <InfiniteScroll
            dataLength={post.length}
            next={() => setPage((prev) => prev + 1)}
            loader={displayLoader()}
            hasMore={hasMoreData}
          >
            <LayoutPostCard>
              {post?.map((data, index) => {
                return (
                  <PostCardLaporan
                    key={index}
                    handleOpen={handleOpen}
                    id={data.id}
                    status_laporan={data.status_laporan}
                    deskripsi={data.deskripsi}
                    tanggal_laporan={data.tanggal_laporan}
                    buttonColor={
                      data.status_laporan === "laporan masuk"
                        ? "bg-button-laporan"
                        : data.status_laporan === "selesai"
                        ? "bg-button-selesai"
                        : data.status_laporan === "ditindaklanjuti"
                        ? "bg-button-ditindaklanjuti"
                        : ""
                    }
                    image={data.media.length !== 0 && data.media[0].file_name}
                    total_views={data.total_views}
                    total_komen={data.komentars_count}
                    read_by_pemda={data.read_by_pemda}
                    read_by_unhas={data.read_by_unhas}
                  />
                );
              })}
              {!loader && post.length === 0 && (
                <div className="m-10">
                  <p>Data tidak ditemukan....</p>
                </div>
              )}
            </LayoutPostCard>
          </InfiniteScroll>
        </div>
      </LayoutAllPostPetani>
    </>
  );
};

export default LaporanAllPost;
