import NavigationBar from "../../Components/NavigationBar";
import React from "react";
import { Carousel } from "@material-tailwind/react";
import LogoUnhas from "../../asset/logo/logo-unhas.svg";
import LogoMaros from "../../asset/logo/logo-maros.svg";
import LogoPetani from "../../asset/logo/logo-petani.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getAllKetuaPetani } from "../../api/publicApi";
import SliderImage1 from "../../asset/slider/1.png";
import SliderImage2 from "../../asset/slider/2.png";
import SliderImage3 from "../../asset/slider/3.png";
import Cookies from "js-cookie";
import TokenHelper from "../../helpers/TokenHelper";
import defaultImg from "../../asset/img/defaultImage.png";

const Home = () => {
  const userId = Cookies.get("userId");
  const role = Cookies.get("role");
  const [ketuaPetani, setKetuaPetani] = useState([]);
  const navigate = useNavigate();
  const token = TokenHelper();

  useEffect(() => {
    if (token) {
      navigate(`/${role}/${userId}`);
    }
  }, [token, role, userId, navigate]);

  useEffect(() => {
    try {
      getAllKetuaPetani().then((result) => {
        setKetuaPetani(result);
        // console.log(result);
      });
    } catch (error) {
      throw error;
    }
  }, []);

  return (
    <>
      <NavigationBar userId={userId} />
      <div className="h-[430px] w-full bg-black absolute z-10 top-16 bg-opacity-50">
        <div className="flex justify-center gap-5 mt-32">
          <img src={LogoUnhas} alt="Logo unhas" className="w-20 h-20" />
          <img src={LogoMaros} alt="Logo unhas" className="w-20 h-20" />
          <img src={LogoPetani} alt="Logo unhas" className="w-20 h-20 rounded-full" />
        </div>
        <div className="mt-5 text-center text-white">
          <h1 className="font-bold text-2xl">Garamqu</h1>
          <p>Universitas Hasanuddin Bekerja Sama dengan</p>
          <p>Pemerintah Kabupaten Maros</p>
        </div>
      </div>
      <Carousel
        transition={{ duration: 2 }}
        autoplay={true}
        autoplayDelay={5000}
        loop={true}
        className=" h-[430px] top-16  "
      >
        <div className="flex h-[430px]">
          <img src={SliderImage1} alt="" className="h-full md:w-1/2 w-full object-cover" />
          <img src={SliderImage2} alt="" className="h-full md:w-1/2 w-full object-cover" />
        </div>
        <div className="flex h-[430px]">
          <img src={SliderImage3} alt="" className="h-full md:w-1/2 w-full object-cover" />
          <img src={SliderImage1} alt="" className="h-full md:w-1/2 w-full object-cover" />
        </div>
      </Carousel>

      <div className="lg:mx-16 sm:mx-10 mx-5 pt-20">
        <h1 className="text-4xl font-semibold text-center my-20 text-header1">Tentang Kami</h1>
        <div className="md:mx-32">
          <p className="text-justify font-bold text-paragraf">Selamat datang di Garamqu!</p>
          <p className="text-justify text-paragraf">
            Garamqu adalah monitoring yang berdedikasi untuk membantu mengoptimalkan produksi
            budidaya garam. Visi garamqu adalah menciptakan solusi yang dapat memudahkan para petani
            garam dan mitra dalam memantau, mengelola, dan meningkatkan kualitas produksi garam di
            Kabupaten Maros.
          </p>
          <p className="text-justify font-bold text-paragraf">
            Salam, <br /> [Tim Garam Keren]
          </p>
        </div>
      </div>

      <hr className="border border-black my-20 md:mx-52 mx-10" />
      <h1 className="text-4xl text-center font-semibold text-header1">Petani Garam</h1>
      <div className="mt-10 mb-24">
        <div className="lg:flex lg:justify-center gap-10 mb-10 flex-wrap">
          {ketuaPetani.map((data, index) => {
            return (
              <Link
                to={
                  userId
                    ? `/bio/petani/${role}/${data.ketua_petani_id}/${userId}/bio-anggota`
                    : `/bio/petani/${data.ketua_petani_id}/produksi`
                }
                key={index}
              >
                <div className="h-fit pb-10 w-[300px] mx-auto rounded-xl border border-black mt-10">
                  <div>
                    <img
                      src={
                        data.profile !== null
                          ? `${process.env.REACT_APP_API_STORAGE_URL}/${data.profile}`
                          : defaultImg
                      }
                      alt=""
                      className={`${
                        data.profile
                          ? "h-[350px] rounded-t-xl object-cover mx-auto w-full"
                          : "h-[350px] rounded-t-xl object-contain mx-auto w-full p-5"
                      }`}
                    />
                  </div>
                  <div className="card-title bg-primary h-10 flex items-center justify-center">
                    <h1 className="text-white text-xl font-semibold">{data.username}</h1>
                  </div>
                  <div className="text-center">
                    <h3 className="text-xl text-semibold my-3">{data.total_member} Anggota</h3>
                    <p>{data.kecamatan}</p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>

      <footer className="w-full h-[150px] bg-primary flex items-center justify-center gap-3">
        <a
          href="https://wa.me/+6281524025278"
          rel="noreferrer"
          target="_blank"
          className="md:text-xl text-xl text-white font-light hover:text-gray-200"
        >
          Hubungi Kami
        </a>
        <p className="text-white">|</p>
        <a href="/bantuan" className="md:text-xl text-xl text-white font-light hover:text-gray-200">
          Bantuan
        </a>
      </footer>
    </>
  );
};

export default Home;
