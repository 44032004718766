import { React, useEffect, useState } from "react";
import TanggalInput from "../../Components/Submit/TanggalInput";
import DeskripsiSubmit from "../../Components/Submit/DeskripsiSubmit";
import FotoSubmit from "../../Components/Submit/FotoSubmit";
import ButtonSubmit from "../../Components/Submit/ButtonSubmit";
import LayoutSubmit from "../../Components/Submit/LayoutSubmit";
import { useNavigate, useParams } from "react-router-dom";
import { addLaporan } from "../../api/api";
import AlertFail from "../../Components/Alert/AlertFail";
import SubmitAlert from "../../Components/Modals/SubmitAlert";

const LaporanSubmit = (props) => {
  const [tanggalLaporan, setTanggalLaporan] = useState();
  const [deskripsi, setDeskripsi] = useState();
  const [imageValue, setImageValue] = useState([]);
  const [alert, setAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [showSubmitALert, setShowSubmitALert] = useState(false);
  const [path, setPath] = useState("");
  const [alertDescription, setAlertDescription] = useState("");

  const navigate = useNavigate();
  const { userId, role } = useParams();

  const showAlertError = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 3000);
  };
  const handlerAddPostingan = async (e) => {
    e.preventDefault();

    if (!tanggalLaporan || !deskripsi) {
      const errorDesc = [];
      if (!tanggalLaporan) {
        errorDesc.push("tanggal masalah");
      }
      showAlertError();
      setAlertDescription(errorDesc.join(" ,") + " tidak boleh kosong");
      return;
    }
    const dataPostingan = {
      deskripsi: deskripsi,
      tanggal_laporan: tanggalLaporan,
      laporan_pictures: imageValue,
    };

    setLoader(true);
    try {
      const response = await addLaporan(userId, dataPostingan);
      setLoader(false);
      if (response) {
        navigate(`/riwayat/${role}/${userId}`);
      }
    } catch (error) {
      console.error(error);
      showAlertError();
      setAlertDescription("terjadi kesalahan saat submit");
      setLoader(false);
    }
  };

  const tanggalLaporanOnChange = (e) => {
    setTanggalLaporan(e.target.value);
    setHasChange(true);
  };

  const deskripsiOnChange = (e) => {
    setDeskripsi(e.target.value);
    setHasChange(true);
  };

  const imageOnChange = async (e) => {
    const files = e.target.files;
    setHasChange(true);

    const base64Promises = Array.from(files).map(async (file) => {
      return await convertBase64(file);
    });

    const base64Array = await Promise.all(base64Promises);
    setImageValue((curr) => [...curr, ...base64Array]);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => [reject(error)];
    });
  };

  const handleDeleteImage = (index) => {
    const newImages = [...imageValue];
    newImages.splice(index, 1);
    setImageValue(newImages);
  };

  const showAlert = (path) => {
    setShowSubmitALert((prev) => !prev);
    setPath(path);
  };

  return (
    <LayoutSubmit userId={userId} hasChange={hasChange} showAlert={showAlert}>
      <SubmitAlert
        open={showSubmitALert}
        handler={() => showAlert()}
        path={path}
        desc="Ini belum diunggah. Apakah Anda yakin ingin ke halaman lainnya?"
      />
      <form onSubmit={handlerAddPostingan}>
        <div className="flex justify-between items-center">
          <h1 className="md:text-3xl text-xl font-semibold">Masalah Garam!</h1>
          <ButtonSubmit btnColor="bg-button-laporan" buttonType="Unggah" loader={loader} />
        </div>
        <div className="md:flex gap-20">
          <TanggalInput
            label="tanggal masalah"
            value={tanggalLaporan}
            onChange={tanggalLaporanOnChange}
          />
        </div>
        <DeskripsiSubmit label="deskripsi masalah" value={deskripsi} onChange={deskripsiOnChange} />
        <FotoSubmit
          imageValue={imageValue}
          onChange={imageOnChange}
          handleDeleteImage={handleDeleteImage}
        />
      </form>
      {alert && <AlertFail deskripsi={alertDescription} />}
    </LayoutSubmit>
  );
};

export default LaporanSubmit;
