import React from "react";

const TotalPenjualanProduksi = (props) => {
  const { total_penjualan, total_produksi } = props;
  return (
    <div className="md:flex md:gap-10">
      <h3 className="md:text-lg text-sm text-paragraf">
        Total Penjualan <span className="font-bold">{total_penjualan} Kg</span>
      </h3>
      <h3 className="md:text-lg text-sm text-paragraf">
        Total Produksi <span className="font-bold">{total_produksi} Kg</span>
      </h3>
    </div>
  );
};

export default TotalPenjualanProduksi;
