import React, { useEffect, useState } from "react";

const StatusPostCard = (props) => {
  const { status_laporan } = props;
  const [color, setColor] = useState();

  useEffect(() => {
    if (status_laporan === "laporan masuk") return setColor("bg-button-laporan");
    else if (status_laporan === "ditindaklanjuti") return setColor("bg-button-ditindaklanjuti");
    else if (status_laporan === "selesai") return setColor("bg-button-selesai");
  }, [status_laporan]);

  return (
    <>
      {color && (
        <button
          className={`${color} capitalize rounded-md md:w-36 w-28 md:text-sm text-xs text-white md:h-8 h-7 mb-1`}
        >
          {status_laporan === "laporan masuk" ? "masuk" : status_laporan}
        </button>
      )}
    </>
  );
};

export default StatusPostCard;
