import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";

const AlertModal = ({ dialogOpen, dialogOpenHandler, icon, title }) => {
  return (
    <Dialog
      open={dialogOpen}
      size="xs"
      handler={dialogOpenHandler}
      className="grid place-items-center"
    >
      <DialogHeader className="flex justify-center mt-10">
        <img src={icon} alt="" className="w-20 h-20" />
      </DialogHeader>
      <div className="flex justify-center">
        <DialogBody>
          <p className="text-md font-bold text-black text-center">{title}</p>
        </DialogBody>
      </div>
      <DialogFooter className="flex justify-center">
        <Button variant="text" color="red" onClick={dialogOpenHandler} className="mr-1">
          <span>Tutup</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default AlertModal;
