import React from "react";
import { Dialog, DialogHeader, DialogBody, DialogFooter, Spinner } from "@material-tailwind/react";
import { deleteLaporan, deletePostingan } from "../../api/api";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import SuccessIcon from "../../asset/icon/success.png";
import AlertModal from "./AlertModal";

const Modals = ({ open, handler, id, type }) => {
  // const { id } = useParams();

  const location = useLocation().pathname;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const deleteHandler = async () => {
    setLoading(true);
    try {
      if (type === "laporan") {
        await deleteLaporan(id);
      } else {
        await deletePostingan(id);
      }

      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        if (location.includes("detail")) {
          window.history.back();
        } else {
          window.location.reload();
        }
        handler();
      }, 2000);
    } catch (error) {
      console.error("error saat mendelete postingan", error);
    }
  };

  return (
    <div className="flex justify-center items-center">
      <Dialog
        open={open}
        handler={handler}
        size="sm"
        className="w-[100px] md:h-[200px] h-[280px] grid place-content-center"
      >
        {!loading && !success ? (
          <>
            <DialogHeader className="text-red-600 flex justify-center">Peringatan</DialogHeader>
            <hr />
            <DialogBody className="text-center text-black">
              Apakah anda yakin ingin menghapus ini?
            </DialogBody>
            <DialogFooter className="text-center flex justify-center">
              <button
                onClick={deleteHandler}
                className="mr-1 w-44 h-10 md:mb-0 mb-3 text-red-700 rounded-none border-2 border-red-700 uppercase"
              >
                <span>Iya, Hapus</span>
              </button>
              <button
                className="bg-white w-44 text-black font-semibold uppercase"
                onClick={handler}
              >
                <span>Tidak</span>
              </button>
            </DialogFooter>
          </>
        ) : (
          !success && (
            <div className="flex place-items-center justify-center ">
              <Spinner className="w-16 h-16 mt-12" />
            </div>
          )
        )}
        {success && (
          <>
            <DialogHeader className="flex justify-center">
              <img src={SuccessIcon} alt="" className="w-20 h-20" />
            </DialogHeader>
            <div className="flex justify-center">
              <DialogBody>
                <p className="text-md font-bold text-black">Berhasil Menghapus</p>
              </DialogBody>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};
export default Modals;
