import React from "react";

import { Navigate } from "react-router-dom";
import TokenHelper from "../helpers/TokenHelper";

const ProtectedRoute = (props) => {
  const token = TokenHelper();
  if (!token) {
    return <Navigate to={"/"} />;
  }
  return props.children;
};

export default ProtectedRoute;
