import React from "react";
import KomentarIcon from "../../../asset/icon/speech-bubble.svg";
import EyeIcon from "../../../asset/icon/Eye.svg";

const KomenPostcard = (props) => {
  const { total_komentar, total_views } = props;
  return (
    <div className="flex gap-3 text-hint">
      <div className="flex items-center">
        <img src={KomentarIcon} alt="" className="h-4 w-4" />
        <p className="text-xs">{total_komentar} Komentar</p>
      </div>
      <div className="flex items-center gap-2">
        <img src={EyeIcon} alt="" className="h-4 w-4" />
        <p className="text-xs">{total_views} dilihat</p>
      </div>
    </div>
  );
};

export default KomenPostcard;
