import { Spinner } from "@material-tailwind/react";
import AlertFail from "../Alert/AlertFail";
import AlertSuccess from "../Alert/AlertSuccess";
import DefaultImg from "../../asset/img/defaultImage.png";

function Profil(props) {
  const {
    fullName,
    gender,
    tempatLahir,
    tlp,
    tanggalLahir,
    jalan,
    kelurahan,
    kecamatan,
    kabupaten,
    jalanLahan,
    kelurahanLahan,
    kecamatanLahan,
    kabupatenLahan,
    profile,
    namaPanggilan,
    koordinatLahan,
    metodeProduksi,
    luasLahan,
    profileOnChange,
    isError,
    isSuccess,
    isLoading,
  } = props;

  return (
    <div className=" md:translate-y-24 md:flex md:mb-0 mb-5 lg:ml-[300px] md:mt-0 mt-20 overflow-x-hidden">
      {isSuccess && <AlertSuccess deskripsi="Berhasil memperbaharui foto profile" />}
      {isError && <AlertFail deskripsi={"Gagal memperbaharui foto profile"} />}
      <div className="md:w-1/2 md:border-r-2 border-gray-600 mt-3">
        <div className="flex justify-center">
          <div className="md:mt-5">
            {!isLoading ? (
              <img
                src={
                  profile !== null
                    ? `${process.env.REACT_APP_API_STORAGE_URL}/${profile}`
                    : DefaultImg
                }
                alt="Avatar"
                className="mx-auto w-24 h-24 object-cover rounded-full"
              />
            ) : (
              <div className="mx-auto w-24 h-24 object-cover rounded-full bg-gray-400 animate-pulse"></div>
            )}
            <div className="mt-5 w-52 py-2 rounded-xl text-lg border border-gray-700 flex justify-center cursor-pointer hover:bg-gray-200">
              <input type="file" id="foto" className="hidden" onChange={profileOnChange} />
              <label htmlFor="foto" className="cursor-pointer">
                Ganti Foto Profil
              </label>
            </div>
            <div className="text-xs mt-3">
              <p>Maksimal 10 mb</p>
              <p>jenis foto PNG, JPG, JPEG</p>
            </div>
          </div>
        </div>

        <div className="flex md:justify-center justify-start ml-3 md:ml-0 mt-8">
          <div className="md:mx-10">
            <h3 className="font-semibold md:text-lg text-sm md:mb-3">Data Diri</h3>
            <table className="w-full max-auto table-fixed sm:text-sm text-xs">
              <tr>
                <td>Nama Lengkap</td>
                <td>{fullName}</td>
              </tr>
              <tr>
                <td>Nama Panggilan</td>
                <td>{namaPanggilan}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>{gender}</td>
              </tr>
              <tr>
                <td>Tempat Lahir</td>
                <td>{tempatLahir}</td>
              </tr>
              <tr>
                <td>Tanggal Lahir</td>
                <td>{tanggalLahir}</td>
              </tr>
              <tr>
                <td>No.Telp</td>
                <td>{tlp}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      {/* right kontent */}
      <div className="md:w-1/2 ml-3 w-full md:h-fit h-[500px]">
        <div className="md:mt-16 mt-8 md:ml-10">
          <div className="md:mb-16 mb-8">
            <h3 className="font-semibold md:text-lg text-sm mb-3">Alamat Rumah</h3>
            <table className="w-full max-auto table-fixed  sm:text-sm text-xs">
              <tr>
                <td>Jalan</td>
                <td>{jalan}</td>
              </tr>
              <tr>
                <td>Kelurahan/Dusun</td>
                <td>{kelurahan}</td>
              </tr>
              <tr>
                <td>Kecamatan</td>
                <td>{kecamatan}</td>
              </tr>
              <tr>
                <td>Kabupaten/Kota</td>
                <td>{kabupaten}</td>
              </tr>
            </table>
          </div>
          <div>
            <h3 className="font-semibold md:text-lg text-sm mb-3">Lahan Garam</h3>
            <table className="w-full max-auto table-fixed  sm:text-sm text-xs">
              <tr>
                <td>Jalan</td>
                <td>{jalanLahan}</td>
              </tr>
              <tr>
                <td>Kelurahan/Dusun</td>
                <td>{kelurahanLahan}</td>
              </tr>
              <tr>
                <td>Kecamatan</td>
                <td>{kecamatanLahan}</td>
              </tr>
              <tr>
                <td>Kabupaten/Kota</td>
                <td>{kabupatenLahan}</td>
              </tr>
              <tr>
                <td>Koordinat Lahan Garam</td>
                <td>{koordinatLahan}</td>
              </tr>
              <tr>
                <td>Luas Lahan Garam</td>
                <td>{luasLahan}</td>
              </tr>
              <tr>
                <td>Metode Produksi</td>
                <td>{metodeProduksi}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profil;
