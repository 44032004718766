import { RequestApi } from "../helpers/RequestApi";
import TokenHelper from "../helpers/TokenHelper";

const getDetailAnggotaByKetuaPetani = async (ketuaPetaniId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `ketua-petani/${ketuaPetaniId}/anggota`,
      {},
      headerToken,
      "Mencoba Menampilkan Anggota"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan Anggota  ", error);
    throw error;
  }
};

export { getDetailAnggotaByKetuaPetani };
