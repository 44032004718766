import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import BookIcon from "../../../asset/icon/Book.svg";
import KelompokIcon from "../../../asset/icon/kelompok-icon.svg";
import TokenHelper from "../../../helpers/TokenHelper";

const Bio = (props) => {
  const { produksiReset } = props;
  const { userId, type, role, ketuaPetaniId } = useParams();
  const location = useLocation().pathname;

  return (
    <div className="md:ml-8 md:my-5 ml-3 mt-3">
      {/* <h1 className="md:text-2xl text-lg font-semibold mb-3 text-header2">Pengaturan</h1> */}
      <div className="pl-2">
        {userId && (
          <Link
            to={`/bio/petani/${role}/${ketuaPetaniId}/${userId}/bio-anggota`}
            className="flex items-center gap-3 text-paragraf mb-3"
          >
            <img src={KelompokIcon} alt="" className="md:h-5 md:w-5 h-4 w-4" />
            <p
              className={`text-lg ${
                location.includes("bio-anggota")
                  ? "font-bold md:text-base text-sm"
                  : "md:text-base text-sm"
              }`}
            >
              Profil Petani garam
            </p>
          </Link>
        )}
        <Link
          to={`/bio/petani/${role}/${ketuaPetaniId}/${userId}/produksi`}
          className="flex items-center gap-3 text-paragraf"
        >
          <img src={BookIcon} alt="" className="md:h-5 md:w-5 h-4 w-4" />
          <p
            className={`text-lg ${
              location.includes("produksi")
                ? "font-bold md:text-base text-sm"
                : "md:text-base text-sm"
            }`}
          >
            Produksi
          </p>
        </Link>
      </div>
    </div>
  );
};

export default Bio;
