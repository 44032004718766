import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import {
  getAllKecamatanByKetuaPetani,
  getAllKetuaPetaniGroupByKecamatan,
  getAllRiwayatPemda,
  getGrafikRiwayat,
  getSearchRiwayatPemda,
  getTotalRiwayatPemda,
} from "../../api/PemdaApi";
import { LineChartPemda } from "../../Components/Grafik/LineChartPemda";
import LayotuAllPostPemda from "../../Components/LayoutAllPostPemda/LayotuAllPostPemda";
import PostCardLaporanPemda from "../../Components/PostcardPemda/PostCardLaporanPemda";
import PostCardProduksiPemda from "../../Components/PostcardPemda/PostCardProduksiPemda";
import SkeletonPostCard from "../../Components/Skeleton/SkeletonPostCard";
import _ from "lodash";

const RiwayatLaporanPemda = () => {
  const [riwayat, setRiwayat] = useState([]);
  const [kecamatan, setKecamatan] = useState();
  const [ketuaPetani, setKetuaPetani] = useState();
  const [selectedKecamatan, setSelectedKecamatan] = useState("");
  const [selectedKetuaPetani, setSelectedKetuaPetani] = useState("");
  const [dataGrafik, setDataGrafik] = useState();
  const [totalRiwayat, setTotalRiwayat] = useState();
  const [maxTotalPostingan, setMaxTotalPostingan] = useState();
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  const { role, userId } = useParams();

  const handleSelectedKecamatan = (event) => {
    setSelectedKecamatan(event.target.value);
    setSelectedKetuaPetani(""); // Reset opsi kedua saat opsi pertama berubah
    setPage(1);
    setRiwayat([]);
  };

  const handleSelectedKetuaPetani = (e) => {
    setSelectedKetuaPetani(e.target.value);
    setPage(1);
    setRiwayat([]);
  };

  useEffect(() => {
    setLoading(true);
    try {
      if (search || selectedKecamatan || selectedKetuaPetani) {
        setHasMoreData(true);
        getSearchRiwayatPemda(page, search, selectedKecamatan, selectedKetuaPetani).then(
          (result) => {
            setRiwayat((prevRiwayat) => [...prevRiwayat, ...result.riwayats]);

            if (result.pagination.last_page <= result.pagination.current_page) {
              setHasMoreData(false);
            }
            setLoading(false);
          }
        );
      } else {
        setHasMoreData(true);
        getAllRiwayatPemda(page).then((result) => {
          if (page === 1) {
            setRiwayat(result.riwayats);
          } else {
            // Jika bukan halaman pertama, tambahkan hasil pencarian ke riwayat yang sudah ada
            setRiwayat((prevRiwayat) => [...prevRiwayat, ...result.riwayats]);
          }

          if (result.pagination.last_page <= result.pagination.current_page) {
            setHasMoreData(false);
          }
          setLoading(false);
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [page, search, selectedKecamatan, selectedKetuaPetani]);

  const debouncedSearchOnChange = _.debounce((value) => {
    setSearch(value);
    setPage(1);
    setRiwayat([]);
  }, 1000); // Sesuaikan timeout debouncing sesuai kebutuhan

  const searchOnChange = (e) => {
    const value = e.target.value;
    debouncedSearchOnChange(value);
  };

  useEffect(() => {
    getAllKecamatanByKetuaPetani().then((result) => {
      setKecamatan(result);
    });
  }, []);

  useEffect(() => {
    getAllKetuaPetaniGroupByKecamatan().then((result) => {
      setKetuaPetani(result);
    });
  }, []);

  useEffect(() => {
    getTotalRiwayatPemda().then((result) => {
      setTotalRiwayat(result);
    });
  }, []);

  useEffect(() => {
    getGrafikRiwayat(selectedKecamatan, selectedKetuaPetani).then((result) => {
      if (result) {
        const data = {
          labels: result.map((data) => data.date),
          datasets: [
            {
              label: "Produksi",
              data: result.map((data) => data.total_postingan),
              borderColor: "rgb(77, 123, 151)",
              backgroundColor: "rgb(77, 123, 151)",
              cubicInterpolationMode: "monotone",
            },
            {
              label: "Masalah",
              data: result.map((data) => data.total_laporan),
              borderColor: "rgb(247, 65, 25)",
              backgroundColor: "rgb(247, 65, 25)",
              cubicInterpolationMode: "monotone",
            },
          ],
        };

        const maxPostingan = Math.max(...result.map((data) => data.total_postingan));
        const maxLaporan = Math.max(...result.map((data) => data.total_laporan));

        let highestTotal;
        if (maxPostingan > maxLaporan) {
          highestTotal = maxPostingan;
        } else {
          highestTotal = maxLaporan;
        }
        setMaxTotalPostingan(highestTotal);
        setDataGrafik(data);
      }
    });
  }, [selectedKetuaPetani, selectedKecamatan]);

  const displayLoader = () => {
    return (
      <>
        <SkeletonPostCard />
        <SkeletonPostCard />
      </>
    );
  };
  return (
    <LayotuAllPostPemda
      title="Semua Riwayat Laporan"
      role={role}
      userId={userId}
      kecamatan={kecamatan}
      ketuaPetani={ketuaPetani}
      selectedKecamatan={selectedKecamatan}
      selectedKetuaPetani={selectedKetuaPetani}
      handleSelectedKecamatan={handleSelectedKecamatan}
      handleSelectedKetuaPetani={handleSelectedKetuaPetani}
      totalPostingan={totalRiwayat}
      searchOnChange={searchOnChange}
    >
      <div className="lg:ml-[310px] md:mt-28 mt-36 items-center">
        <h1 className="lg:text-4xl text-xl font-bold text-center">Grafik Laporan Garam</h1>
        <div className="flex items-center lg:mx-32">
          <div className="w-full">
            {dataGrafik && (
              <LineChartPemda
                dataGrafik={dataGrafik}
                max={maxTotalPostingan}
                title="Total Laporan"
              />
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="lg:ml-[300px] mb-10">
        <InfiniteScroll
          dataLength={riwayat.length}
          next={() => setPage((prev) => prev + 1)}
          loader={displayLoader()}
          hasMore={hasMoreData}
        >
          <div className="flex flex-wrap overflow-y-hidden">
            {riwayat?.map((data, index) => {
              if (data.type === "postingan") {
                return (
                  <PostCardProduksiPemda
                    key={index}
                    id={data.id}
                    tanggal_produksi={data.tanggal_produksi}
                    total_produksi={data.total_produksi}
                    total_penjualan={data.total_penjualan}
                    deskripsi={data.deskripsi}
                    total_views={data.total_views}
                    total_komen={data.komentars_count}
                    nama={data.username}
                    image={data.media.length !== 0 && data.media[0].file_name}
                    kecamatan={data.kecamatan}
                    read_by_pemda={data.read_by_pemda}
                    read_by_unhas={data.read_by_unhas}
                  />
                );
              } else {
                return (
                  <PostCardLaporanPemda
                    key={index}
                    id={data.id}
                    tanggal_produksi={data.tanggal_laporan}
                    deskripsi={data.deskripsi}
                    total_views={data.total_views}
                    total_komen={data.komentars_count}
                    nama={data.username}
                    image={data.media.length !== 0 && data.media[0].file_name}
                    kecamatan={data.kecamatan}
                    status_laporan={data.status_laporan}
                    read_by_pemda={data.read_by_pemda}
                    read_by_unhas={data.read_by_unhas}
                  />
                );
              }
            })}
            {!loading && riwayat.length === 0 && (
              <div className="m-10">
                <p>Data tidak ditemukan....</p>
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    </LayotuAllPostPemda>
  );
};

export default RiwayatLaporanPemda;
