import React from "react";
import NavigationBar from "../NavigationBar";

const LayoutSubmit = (props) => {
  const { children, userId, hasChange, showAlert } = props;
  return (
    <>
      <NavigationBar userId={userId} hasChange={hasChange} showAlert={showAlert} />
      <div className="flex justify-center items-center w-screen md:mb-0 mb-36">
        <div className="md:w-2/3 overflow-y-auto w-screen mx-3 border border-gray-500 rounded-2xl md:p-12 p-5 mt-20 ">
          {children}
        </div>
      </div>
    </>
  );
};

export default LayoutSubmit;
