import React from "react";

const UserKecamatan = (props) => {
  const { nama, kecamatan } = props;
  return (
    <div className="flex gap-1">
      <p className="md:text-xs text-[10px] text-paragraf font-medium">{nama}</p>
      <p className="text-xs">|</p>
      <p className="md:text-xs text-[10px] text-paragraf">{kecamatan}</p>
    </div>
  );
};

export default UserKecamatan;
