import React from "react";
import PetaniImg from "../../asset/img/petani1.png";
import EditIcon from "../../asset/icon/Edit.svg";
import DeleteIcon from "../../asset/icon/Delete.svg";
import KomentarIcon from "../../asset/icon/speech-bubble.svg";
import EyeIcon from "../../asset/icon/Eye.svg";
import LogoUnhas from "../../asset/logo/logo-unhas.png";
import LogoMaros from "../../asset/logo/logo-maros.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

const PostCardProduksi = (props) => {
  const {
    total_penjualan,
    id,
    handleOpen,
    tanggal_produksi,
    total_produksi,
    deskripsi,
    image,
    ref,
    total_views,
    total_komen,
    type,
    read_by_pemda,
    read_by_unhas,
  } = props;
  const { role, userId } = useParams();
  const [tahun, setTahun] = useState();
  const [bulan, setBulan] = useState();
  const [hari, setHari] = useState();
  const navigate = useNavigate();
  const handleEditClick = (e) => {
    e.preventDefault();
    navigate(`/update/produksi/${id}/${role}/${userId}`);
  };

  useEffect(() => {
    const [tahun, bulanAngka, hari] = tanggal_produksi.split("-");
    const namaBulan = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const namaBulanString = namaBulan[bulanAngka - 1];
    setTahun(tahun);
    setBulan(namaBulanString);
    setHari(hari);
  }, [tanggal_produksi]);

  const location = useLocation().pathname;
  return (
    <Link
      to={location.includes("bio") ? `detail/${id}` : `/produksi/${role}/${userId}/detail/${id}`}
      className="md:h-40 w-full lg:ml-10 md:ml-3 mx-3 mt-5 md:pr-5 border border-gray-300 shadow-md md:rounded-l-lg rounded-lg flex relative"
      ref={ref}
    >
      <div className="md:w-fit w-fit max-w-[33%]">
        {image ? (
          <img
            src={`${process.env.REACT_APP_API_STORAGE_URL}/${image}`}
            alt=""
            className="h-full max-h-40 w-[250px] md:min-w-[250px] rounded-l-lg object-cover object-center"
          />
        ) : (
          ""
        )}
      </div>
      <div className="flex items-center w-2/3">
        <div className="md:p-5 p-3">
          <div className="md:flex gap-5">
            {/* <h3 className="text-2xl">
                Total Produksi <span className="font-bold">60 Kg</span>
              </h3> */}
            <div className="md:flex md:gap-5">
              <h3 className="md:text-lg text-sm text-paragraf">
                Total Penjualan <span className="font-bold">{total_penjualan} Kg</span>
              </h3>

              <h3 className="md:text-lg text-sm text-paragraf">
                Total Produksi <span className="font-bold">{total_produksi} Kg</span>
              </h3>
            </div>

            <div className="flex md:gap-3 gap-2 absolute md:right-10 right-3 top-3">
              {read_by_unhas === 1 && (
                <img src={LogoUnhas} alt="Logo Unhas" className="md:h-5 h-4" />
              )}
              {read_by_pemda === 1 && (
                <img src={LogoMaros} alt="Logo Maros" className="md:h-5 h-4" />
              )}
            </div>
          </div>
          {location.includes("bio") ? (
            ""
          ) : (
            <div className="flex items-center gap-3">
              <div
                className="flex gap-1 items-center text-hint cursor-pointer hover:bg-gray-200"
                onClick={handleEditClick}
              >
                <img src={EditIcon} alt="" className="md:h-4 h-3" />
                <p className="text-xs">Edit</p>
              </div>
              <p>|</p>
              <div
                className="flex gap-2 items-center text-hint cursor-pointer hover:bg-gray-200"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpen(id, type);
                }}
              >
                <img src={DeleteIcon} alt="" className="md:h-4 h-3" />
                <p className="text-xs">Hapus</p>
              </div>
            </div>
          )}
          <div className="flex justify-between md:pt-0 pt-1 pb-1">
            <p className="text-[10px] text-hint">
              {hari} {bulan} {tahun}
            </p>
          </div>

          <p className="md:text-sm text-xs text-hint overflow-hidden text-ellipsis truncate md:max-w-[65ch] max-w-[25ch] ">
            {deskripsi}
          </p>
          <div className="flex gap-3 mt-3 text-hint">
            <div className="flex items-center">
              <img src={KomentarIcon} alt="" className="h-4 w-4" />
              <p className="text-xs">{total_komen}</p>
            </div>
            <div className="flex items-center gap-2">
              <img src={EyeIcon} alt="" className="h-4 w-4" />
              <p className="text-xs">{total_views}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PostCardProduksi;
