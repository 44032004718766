import React from "react";
import { useParams } from "react-router-dom";
import ProduksiAllPost from "./ketuaPetani/ProduksiAllPost";
import ProduksiAllPostPemda from "./pemda/ProduksiAllPostPemda";

const ProduksiPage = () => {
  const { role } = useParams();
  return <>{role === "ketua_petani" ? <ProduksiAllPost /> : <ProduksiAllPostPemda />}</>;
};

export default ProduksiPage;
