import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import LayoutPengaturan from "../../Components/Pengaturan/LayoutPengaturan";
import Profil from "../../Components/Pengaturan/Profil";
import { getDetailProfil, updateProfilePetani } from "../../api/api";
import { useState } from "react";
import { Spinner } from "@material-tailwind/react";

const PengaturanProfil = (props) => {
  const { userId } = useParams();
  const [dataPetani, setDataPetani] = useState();
  const [currProfile, setCurrProfile] = useState(null);
  const [newProfile, setNewProfile] = useState();
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getProfile = async (userId) => {
    await getDetailProfil(userId).then((result) => {
      setDataPetani(result);
    });
  };

  useEffect(() => {
    getProfile(userId);
  }, [userId]);

  useEffect(() => {
    const handleUpdateProfile = async () => {
      if (!newProfile) {
        return;
      }

      const image = {
        profile_pictures: newProfile,
      };

      setIsLoading(true);

      try {
        const result = await updateProfilePetani(userId, image);
        setIsLoading(false);
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);

        setCurrProfile(result.media.file_name);
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
        setTimeout(() => {
          setIsError(false);
        }, 2000);
        console.error(error);
      }
    };

    handleUpdateProfile();
  }, [newProfile, userId]);

  const profileOnChange = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    setNewProfile(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => [reject(error)];
    });
  };
  return (
    // <div>
    //   <NavigationBar />

    //   <div className="pt-16 flex">
    //     {/* Left Konten */}
    //     <Filter />

    //     <div className="w-full">
    //       {/* Right Konten */}

    //       <div className="w-full header md:fixed md:bg-white z-10">
    //         <div className="md:h-24 h-20 w-full flex items-center justify-center md:justify-start md:border-2 md:border-gray-300 md:shadow-lg md:pl-10">
    //           <h1 className="md:text-3xl text-2xl font-semibold">
    //             {jenis === "profil"
    //               ? "Profil Saya"
    //               : jenis === "kelompok" && "Data Diri Anggota Saya"}
    //           </h1>
    //         </div>
    //       </div>
    //       <div>{jenis === "profil" ? <Profil /> : jenis === "kelompok" && <Kelompok />}</div>
    //     </div>
    //   </div>
    // </div>
    <LayoutPengaturan title={"Profil Saya"} userId={userId}>
      {dataPetani ? (
        <Profil
          fullName={dataPetani.full_name}
          gender={dataPetani.gender}
          tempatLahir={dataPetani.place_birth}
          tanggalLahir={dataPetani.birth_date}
          tlp={dataPetani.phone_number}
          jalan={dataPetani.alamat.jalan}
          kelurahan={dataPetani.alamat.kelurahan}
          kecamatan={dataPetani.alamat.kecamatan}
          kabupaten={dataPetani.alamat.kota}
          jalanLahan={dataPetani.lahan_garam.jalan}
          kelurahanLahan={dataPetani.lahan_garam.kelurahan}
          kecamatanLahan={dataPetani.lahan_garam.kecamatan}
          kabupatenLahan={dataPetani.lahan_garam.kota}
          profile={currProfile !== null ? currProfile : dataPetani.profile}
          koordinatLahan={dataPetani.lahan_garam.koordinat_lahan}
          metodeProduksi={dataPetani.lahan_garam.metode_produksi}
          luasLahan={dataPetani.lahan_garam.luas_lahan}
          profileOnChange={profileOnChange}
          isError={isError}
          isSuccess={isSuccess}
          isLoading={isLoading}
        />
      ) : (
        <div className="mt-80 flex justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      )}
    </LayoutPengaturan>
  );
};

// const Profil = () => {
//   return (
//     <div className=" md:translate-y-24 md:flex overflow-x-hidden overflow-y-hidden md:mb-0 mb-5">
//       <div className="md:w-1/2 md:border-r-2 border-gray-600 mt-3">
//         <div className="flex justify-center">
//           <div className="md:mt-5">
//             <img src={AvatarIcon} alt="Avatar" className="mx-auto w-24" />

//             <button className="w-52 py-2 rounded-xl text-lg border border-gray-700 mt-5">
//               Ganti Foto Profil
//             </button>
//             <div className="text-xs mt-3">
//               <p>Maksimal 10 mb</p>
//               <p>jenis foto PNG, JPG, JPEG</p>
//             </div>
//           </div>
//         </div>

//         <div className="flex md:justify-center justify-start ml-3 md:ml-0 mt-8">
//           <div className="md:mx-10">
//             <h3 className="font-semibold md:text-lg text-sm md:mb-3">Data Diri</h3>
//             <table className="w-full max-auto table-fixed sm:text-sm text-xs">
//               <tr>
//                 <td>Nama Lengkap</td>
//                 <td>Nama Lengkap Petani</td>
//               </tr>
//               <tr>
//                 <td>Nama Panggilan</td>
//                 <td>Petani</td>
//               </tr>
//               <tr>
//                 <td>Gender</td>
//                 <td>Laki-laki</td>
//               </tr>
//               <tr>
//                 <td>Tempat Lahir</td>
//                 <td>Makassar</td>
//               </tr>
//               <tr>
//                 <td>Tanggal Lahir</td>
//                 <td>31 Juli 2000</td>
//               </tr>
//               <tr>
//                 <td>No.Telp</td>
//                 <td>08192737465</td>
//               </tr>
//             </table>
//           </div>
//         </div>
//       </div>

//       {/* right kontent */}
//       <div className="md:w-1/2 ml-3 w-full ">
//         <div className="md:mt-16 mt-8 md:ml-10">
//           <div className="md:mb-16 mb-8">
//             <h3 className="font-semibold md:text-lg text-sm mb-3">Alamat Rumah</h3>
//             <table className="w-full max-auto table-fixed  sm:text-sm text-xs">
//               <tr>
//                 <td>Jalan</td>
//                 <td>Jl.xxxxxxxxxxxxxx</td>
//               </tr>
//               <tr>
//                 <td>Kelurahan/Dusun</td>
//                 <td>xxxxxxxxxxxxxxx</td>
//               </tr>
//               <tr>
//                 <td>Kecamatan</td>
//                 <td>xxxxxxxxxxxxxx</td>
//               </tr>
//               <tr>
//                 <td>Kabupaten/Kota</td>
//                 <td>xxxxxxxxxxxxx</td>
//               </tr>
//             </table>
//           </div>
//           <div>
//             <h3 className="font-semibold md:text-lg text-sm mb-3">Lahan Garam</h3>
//             <table className="w-full max-auto table-fixed  sm:text-sm text-xs">
//               <tr>
//                 <td>Jalan</td>
//                 <td>Jl.xxxxxxxxxxxxxx</td>
//               </tr>
//               <tr>
//                 <td>Kelurahan/Dusun</td>
//                 <td>xxxxxxxxxxxxxxx</td>
//               </tr>
//               <tr>
//                 <td>Kecamatan</td>
//                 <td>xxxxxxxxxxxxxx</td>
//               </tr>
//               <tr>
//                 <td>Kabupaten/Kota</td>
//                 <td>xxxxxxxxxxxxx</td>
//               </tr>
//               <tr>
//                 <td>Koordinat Lahan Garam</td>
//                 <td>xxxxxxxxxxxxx</td>
//               </tr>
//               <tr>
//                 <td>Luas Lahan Garam</td>
//                 <td>xxxxxxxxxxxxx</td>
//               </tr>
//               <tr>
//                 <td>Metode Produksi</td>
//                 <td>xxxxxxxxxxxxx</td>
//               </tr>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// const Kelompok = () => {
//   return (
//     <div className="pb-[150px] overflow-x-hidden">
//       <div className="p-5 border border-gray-700 rounded-xl md:mr-10 mx-2 md:translate-y-24 md:mt-5 mt-2 md:mb-10 mb-5 ">
//         <div className="lg:flex gap-32">
//           <div>
//             <h3 className="font-semibold sm:text-lg text-sm md:mb-3 mb-1">Alamat Rumah</h3>
//             <table className="w-full table-fixed sm:text-sm text-xs">
//               <tr>
//                 <td>Username</td>
//                 <td>Petani1</td>
//               </tr>
//               <tr>
//                 <td>Nama Lengkap</td>
//                 <td>Nama Lengkap Petani</td>
//               </tr>
//               <tr>
//                 <td>Nama Panggilan</td>
//                 <td>Petani</td>
//               </tr>
//               <tr>
//                 <td>Gender</td>
//                 <td>Laki-laki</td>
//               </tr>
//               <tr>
//                 <td>Tempat Lahir</td>
//                 <td>Makassar</td>
//               </tr>
//               <tr>
//                 <td>Tanggal Lahir</td>
//                 <td>31 Juli 2000</td>
//               </tr>
//               <tr>
//                 <td>No.Telp</td>
//                 <td>08192737465</td>
//               </tr>
//             </table>
//           </div>
//           <div className="md:flex gap-32 md:mt-0 mt-5">
//             <div>
//               <h3 className="font-semibold sm:text-lg text-sm md:mb-3 mb-1">Lahan Garam</h3>
//               <table className="w-full table-fixed sm:text-sm text-xs">
//                 <tr>
//                   <td>Jalan</td>
//                   <td>Jl.xxxxxxxxxxxxxx</td>
//                 </tr>
//                 <tr>
//                   <td>Kelurahan/Dusun</td>
//                   <td>xxxxxxxxxxxxxxx</td>
//                 </tr>
//                 <tr>
//                   <td>Kecamatan</td>
//                   <td>xxxxxxxxxxxxxx</td>
//                 </tr>
//                 <tr>
//                   <td>Kabupaten/Kota</td>
//                   <td>xxxxxxxxxxxxx</td>
//                 </tr>
//                 <tr>
//                   <td>Provinsi</td>
//                   <td>xxxxxxxxxxxxx</td>
//                 </tr>
//                 <tr>
//                   <td>Alamat lahan garam</td>
//                   <td>xxxxxxxxxxxxx</td>
//                 </tr>
//                 <tr>
//                   <td>Luas Lahan</td>
//                   <td>xxxxxxxxxxxxx</td>
//                 </tr>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default PengaturanProfil;
