import React from "react";
import { Link } from "react-router-dom";

const LayoutForgotPassword = (props) => {
  const { children, title } = props;
  return (
    <div className="md:h-[550px] h-screen sm:w-[400px] sm:h-[650px] sm:border border-gray-600 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl">
      <h1 className="text-3xl text-header0 text-center mt-10 font-semibold mb-16">{title}</h1>
      <div>
        <div className="flex justify-center">{children}</div>

        <div className="flex justify-center my-5">
          <Link to={"/login"} className="text-xs text-cyan-600 underline">
            Kembali ke halaman login
          </Link>
        </div>
        <div className="flex justify-center">
          <Link
            to={"https://wa.me/+6281524025278"}
            rel="noreferrer"
            target="_blank"
            className="text-xs text-cyan-600 underline"
          >
            Butuh bantuan? Hubungi kami
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LayoutForgotPassword;
