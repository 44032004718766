import { React, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TotalInput from "../../Components/Submit/TotalInput";
import TanggalInput from "../../Components/Submit/TanggalInput";
import DeskripsiSubmit from "../../Components/Submit/DeskripsiSubmit";
import FotoSubmit from "../../Components/Submit/FotoSubmit";
import ButtonSubmit from "../../Components/Submit/ButtonSubmit";
import LayoutSubmit from "../../Components/Submit/LayoutSubmit";
import { addPostingan } from "../../api/api";
import AlertFail from "../../Components/Alert/AlertFail";
import SubmitAlert from "../../Components/Modals/SubmitAlert";

const ProduksiSubmit = (props) => {
  const [totalProduksi, setTotalProduksi] = useState();
  const [totalPenjualan, setTotalPenjualan] = useState();
  const [tanggalProduksi, setTanggalProduksi] = useState();
  const [deskripsi, setDeskripsi] = useState();
  const [imageValue, setImageValue] = useState([]);
  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [showSubmitALert, setShowSubmitALert] = useState(false);
  const [path, setPath] = useState("");
  const [alertDescription, setAlertDescription] = useState("");

  const navigate = useNavigate();
  const { userId, role } = useParams();

  const showAlertError = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 3000);
  };

  const handlerAddPostingan = async (e) => {
    e.preventDefault();

    if (!tanggalProduksi) {
      const errorDesc = [];

      if (!tanggalProduksi) {
        errorDesc.push("tanggal produksi");
      }

      showAlertError();
      setAlertDescription(errorDesc.join(" ,") + " tidak boleh kosong");
      return;
    }
    const dataPostingan = {
      total_produksi: parseInt(totalProduksi),
      total_penjualan: parseInt(totalPenjualan),
      tanggal_produksi: tanggalProduksi,
      deskripsi: deskripsi,
      postingan_pictures: imageValue,
    };

    setLoader(true);
    try {
      const response = await addPostingan(userId, dataPostingan);
      if (response) {
        navigate(`/riwayat/${role}/${userId}`);
      }
      setLoader(false);
    } catch (error) {
      console.error(error);
      showAlertError();
      setAlertDescription("terjadi kesalahan saat submit");
      setLoader(false);
    }
  };

  const totalProduksiOnChange = (e) => {
    setTotalProduksi(e.target.value);
    setHasChange(true);
  };

  const totalPenjualanOnChange = (e) => {
    setTotalPenjualan(e.target.value);
    setHasChange(true);
  };

  const tanggalProduksiOnChange = (e) => {
    setTanggalProduksi(e.target.value);
    setHasChange(true);
  };

  const deskripsiOnChange = (e) => {
    setDeskripsi(e.target.value);
    setHasChange(true);
  };

  const imageOnChange = async (e) => {
    const files = e.target.files;
    setHasChange(true);

    const base64Promises = Array.from(files).map(async (file) => {
      return await convertBase64(file);
    });

    const base64Array = await Promise.all(base64Promises);
    setImageValue((curr) => [...curr, ...base64Array]);
  };

  const handleDeleteImage = (index) => {
    const newImages = [...imageValue];
    newImages.splice(index, 1);
    setImageValue(newImages);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => [reject(error)];
    });
  };

  const showAlert = (path) => {
    setShowSubmitALert((prev) => !prev);
    setPath(path);
  };

  return (
    <LayoutSubmit userId={userId} showAlert={showAlert} hasChange={hasChange}>
      <SubmitAlert
        open={showSubmitALert}
        handler={() => showAlert()}
        path={path}
        desc="Ini belum diunggah. Apakah Anda yakin ingin ke halaman lainnya?"
      />
      <form onSubmit={handlerAddPostingan}>
        <div className="flex justify-between items-center mb-5">
          <h1 className="md:text-3xl text-xl font-semibold">Produksi Garam</h1>
          <ButtonSubmit btnColor="bg-buttonColor" buttonType="Unggah" loader={loader} />
        </div>
        <div className="md:flex gap-20">
          <div className="flex sm:gap-20 gap-5 flex-col sm:flex-row">
            <TotalInput
              label="Total Produksi"
              onChange={totalProduksiOnChange}
              value={totalProduksi}
            />
            <TotalInput
              label="Total Penjualan"
              value={totalPenjualan}
              onChange={totalPenjualanOnChange}
            />
          </div>

          <TanggalInput
            label="Tanggal produksi"
            value={tanggalProduksi}
            onChange={tanggalProduksiOnChange}
          />
        </div>
        <DeskripsiSubmit
          label="deskripsi produksi"
          value={deskripsi}
          onChange={deskripsiOnChange}
        />
        <FotoSubmit
          imageValue={imageValue}
          onChange={imageOnChange}
          handleDeleteImage={handleDeleteImage}
        />
      </form>

      {alert && <AlertFail deskripsi={alertDescription} />}
    </LayoutSubmit>
  );
};

export default ProduksiSubmit;
