import React from "react";
import PersonIcon from "../../../asset/icon/person-icon.svg";
import KelompokIcon from "../../../asset/icon/kelompok-icon.svg";
import { Link, useLocation, useParams } from "react-router-dom";

const ProfilFilter = (props) => {
  // const { userId } = props;
  const { userId, role } = useParams();
  const location = useLocation().pathname;
  return (
    <div className="md:ml-8 md:my-5 ml-5 mt-3">
      {/* <h1 className="md:text-2xl text-lg font-semibold mb-3 text-header2">Pengaturan</h1> */}
      <div className="pl-2">
        <Link
          to={`/pengaturan/profil/petani/${role}/${userId}`}
          className="flex items-center gap-3 mb-3 text-paragraf"
        >
          <img src={PersonIcon} alt="" className="md:h-5 md:w-5 h-4 w-4" />
          <p
            className={`text-lg hover:font-bold ${
              location.includes("profil")
                ? "font-bold md:text-base text-sm"
                : "md:text-base text-sm"
            }`}
          >
            Profil Saya
          </p>
        </Link>
        <Link
          to={`/pengaturan/kelompok/petani/${role}/${userId}`}
          className="flex items-center gap-3 text-paragraf"
        >
          <img src={KelompokIcon} alt="" className="md:h-5 md:w-5 h-4 w-4" />
          <p
            className={`text-lg hover:font-bold ${
              location.includes("kelompok")
                ? "font-bold md:text-base text-sm"
                : "md:text-base text-sm"
            }`}
          >
            Kelompok Saya
          </p>
        </Link>
      </div>
    </div>
  );
};

export default ProfilFilter;
