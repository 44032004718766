import React from "react";
import { useParams } from "react-router-dom";
import LaporanAllPost from "./ketuaPetani/LaporanAllPost";
import LaporanAllPostPemda from "./pemda/LaporanAllPostPemda";

const MasalahPage = () => {
  const { role } = useParams();
  return <>{role === "ketua_petani" ? <LaporanAllPost /> : <LaporanAllPostPemda />}</>;
};

export default MasalahPage;
