import { React, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TotalInput from "../../Components/Submit/TotalInput";
import TanggalInput from "../../Components/Submit/TanggalInput";
import DeskripsiSubmit from "../../Components/Submit/DeskripsiSubmit";
import FotoSubmit from "../../Components/Submit/FotoSubmit";
import ButtonSubmit from "../../Components/Submit/ButtonSubmit";
import LayoutSubmit from "../../Components/Submit/LayoutSubmit";
import {
  addPostingan,
  getDetailLaporan,
  getDetailPostingan,
  updateLaporan,
  updateProduksi,
} from "../../api/api";
import { useEffect } from "react";
import { Spinner } from "@material-tailwind/react";
import SubmitAlert from "../../Components/Modals/SubmitAlert";
import AlertFail from "../../Components/Alert/AlertFail";

const LaporanUpdate = (props) => {
  const [tanggalLaporan, setTanggalLaporan] = useState();
  const [deskripsi, setDeskripsi] = useState();
  const [imageValue, setImageValue] = useState([]);
  const [currImage, setCurrImage] = useState([]);
  const [detailPostingan, setDetailPostingan] = useState();
  const [deletedImageId, setDeletedImageId] = useState([]);
  const [loader, setLoader] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [showSubmitALert, setShowSubmitALert] = useState(false);
  const [path, setPath] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState("");
  const [image, setImage] = useState();

  const navigate = useNavigate();
  const { userId, role, id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const result = await getDetailLaporan(id);
      setDetailPostingan(result);
      setDeskripsi(result.deskripsi);
      setTanggalLaporan(result.tanggal_laporan);
      setImage(result.media);

      result.media.map((data) => {
        setImageValue((prev) => [...prev, data.base64]);
      });

      // const convertBase64Jpg = (file) => {
      //   return new Promise((resolve, reject) => {
      //     const fileReader = new FileReader();
      //     fileReader.readAsDataURL(file);

      //     fileReader.onload = () => {
      //       const base64WithExtension = fileReader.result.replace(
      //         /^data:(.*;base64,)?/,
      //         "data:image/jpeg;base64,"
      //       );
      //       resolve(base64WithExtension);
      //     };

      //     fileReader.onerror = (error) => [reject(error)];
      //   });
      // };

      // const base64Promises = result.media.map(async (data) => {
      //   const response = await fetch(`${process.env.REACT_APP_API_STORAGE_URL}/${data.file_name}`);
      //   const blob = await response.blob();
      //   const base64 = await convertBase64Jpg(blob);
      //   return base64;
      // });

      // const base64Array = await Promise.all(base64Promises);
      // setImageValue((curr) => [...curr, ...base64Array]);

      const uniqueImageIds = new Set(result.media.map((data) => data.id));
      setDeletedImageId(Array.from(uniqueImageIds));
    };
    fetchData();
  }, [id]);

  const showAlertError = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 3000);
  };

  const handleUpdateLaporan = async (e) => {
    e.preventDefault();

    if (!tanggalLaporan || !deskripsi) {
      const errorDesc = [];
      if (!deskripsi) {
        errorDesc.push("deskripsi masalah");
      }
      if (!tanggalLaporan) {
        errorDesc.push("tanggal masalah");
      }
      showAlertError();
      setAlertDescription(errorDesc.join(" ,") + " tidak boleh kosong");
      return;
    }

    const newDataPostingan = {
      deskripsi: deskripsi,
      tanggal_laporan: tanggalLaporan,
      deleted_laporan_pictures: deletedImageId.join(","),
      laporan_pictures: imageValue,
    };

    setLoader(true);
    try {
      const response = await updateLaporan(id, newDataPostingan);
      if (response) {
        navigate(`/riwayat/${role}/${userId}`);
      }
      setLoader(false);
    } catch (error) {
      console.error(error);
      setAlertDescription(error.response.data.errors.details);
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2000);
      setLoader(false);
    }
  };

  const tanggalProduksiOnChange = (e) => {
    setTanggalLaporan(e.target.value);
    setHasChange(true);
  };

  const deskripsiOnChange = (e) => {
    setDeskripsi(e.target.value);
    setHasChange(true);
  };

  const imageOnChange = async (e) => {
    const files = e.target.files;
    setHasChange(true);

    if (!files || files.length === 0) {
      console.error("Tidak ada file yang dipilih");
      return;
    }

    try {
      const base64Array = await Promise.all(
        Array.from(files).map(async (file) => {
          if (!(file instanceof Blob)) {
            console.error("Tipe file tidak valid:", file);
            return null;
          }

          return await convertBase64(file);
        })
      );

      const filteredBase64Array = base64Array.filter((base64) => base64 !== null);
      setImageValue((curr) => [...curr, ...filteredBase64Array]);
    } catch (error) {
      console.error("Error dalam Promise.all:", error);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => [reject(error)];
    });
  };
  const handleDeleteImage = (index) => {
    const newImages = [...imageValue];
    newImages.splice(index, 1);
    setImageValue(newImages);
  };

  const showAlert = (path) => {
    setShowSubmitALert((prev) => !prev);
    setPath(path);
  };

  return (
    <>
      {detailPostingan ? (
        <LayoutSubmit userId={userId} showAlert={showAlert} hasChange={hasChange}>
          <SubmitAlert
            open={showSubmitALert}
            handler={() => showAlert()}
            path={path}
            desc="Ini belum diperbarui. Apakah Anda yakin ingin ke halaman lainnya sehingga batal memperbarui?"
          />
          <form onSubmit={handleUpdateLaporan}>
            <div className="flex justify-between">
              <h1 className="md:text-3xl text-xl font-semibold">Perbaharui Masalah Garam!</h1>
              <ButtonSubmit btnColor="bg-button-laporan" buttonType="Perbaharui" loader={loader} />
            </div>
            <div className="md:flex gap-20">
              <TanggalInput
                label="Tanggal Masalah"
                value={tanggalLaporan}
                onChange={tanggalProduksiOnChange}
                defaultValue={detailPostingan?.tanggal_laporan}
              />
            </div>
            <DeskripsiSubmit
              label="deskripsi masalah"
              value={deskripsi}
              onChange={deskripsiOnChange}
              defaultValue={detailPostingan?.deskripsi}
            />
            <FotoSubmit
              imageValue={imageValue}
              onChange={imageOnChange}
              handleDeleteImage={handleDeleteImage}
            />
          </form>
        </LayoutSubmit>
      ) : (
        <div className="grid place-items-center mt-52">
          <Spinner className="w-20 h-20" />
        </div>
      )}
      {alert && <AlertFail deskripsi={alertDescription} />}
    </>
  );
};

export default LaporanUpdate;
