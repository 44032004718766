import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { addKomentarPemda, getDetailPostinganPemda } from "../../api/PemdaApi";
import ProduksiDetail from "../../Components/OnePost/ProduksiDetail";
import PageNotFound from "../PageNotFound";

const ProduksiDetailPemda = () => {
  const { id, userId } = useParams();
  const [detailPostingan, setDetailPostingan] = useState();
  const [komentar, setKomentar] = useState();
  const [listKomentar, setListKomentar] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [errorPage, setErrorPage] = useState(false);

  useEffect(() => {
    getDetailPostinganPemda(id, setErrorPage).then((result) => {
      setDetailPostingan(result);
      if (errorPage === true) {
        return;
      }
      setListKomentar(result?.komentars);
    });
  }, [id, errorPage]);

  const addKomentarHandler = async (e) => {
    e.preventDefault();

    const KomentarData = {
      type: "postingan",
      item_id: detailPostingan.id,
      deskripsi: komentar,
    };

    try {
      setLoader(true);
      await addKomentarPemda(userId, KomentarData);
      setIsSuccess(true);
      setKomentar("");

      // if (response) {
      //   // const newComment = response.komentars[0];
      //   getDetailPostinganPemda(id).then((result) => {
      //     setDetailPostingan(result);
      //     setLoader(false);
      //   });
      // }
      await getDetailPostinganPemda(id).then((result) => {
        setListKomentar(result.komentars);
      });
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setIsFail(true);
      setLoader(false);
      setTimeout(() => {
        setIsFail(false);
      }, 2000);
    }
  };

  const komentarOnChange = (e) => {
    setKomentar(e.target.value);
  };
  return (
    <>
      {errorPage === false ? (
        <ProduksiDetail
          detailPostingan={detailPostingan}
          addKomentarHandler={addKomentarHandler}
          komentarOnChange={komentarOnChange}
          listKomentar={listKomentar}
          komentar={komentar}
          loader={loader}
          isSuccess={isSuccess}
          isFail={isFail}
        />
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default ProduksiDetailPemda;
