import React from "react";
import RadioFilter from "./Fragments/RadioFilter";
import Ratarata from "./Fragments/Ratarata";
import Riwayat from "./Fragments/Riwayat";
import Total from "./Fragments/Total";
import Waktu from "./Fragments/Waktu";
import LayoutFilter from "./LayoutFilter";

const FilterLaporan = (props) => {
  const {
    startDateOnChange,
    endDateOnChange,
    startDate,
    endDate,
    userId,
    statusOnChange,
    selectedStatus,
    ratarata,
    dateError,
  } = props;

  return (
    <LayoutFilter>
      <Riwayat title="RIWAYAT LAPORAN" userId={userId} />
      <Ratarata title="RATA-RATA MASALAH" ratarata={ratarata} />
      <RadioFilter
        title="FILTER LAPORAN MASALAH"
        statusOnChange={statusOnChange}
        selectedStatus={selectedStatus}
      />
      <Waktu
        title="FILTER JANGKA WAKTU LAPORAN MASALAH"
        startDateOnChange={startDateOnChange}
        endDateOnChange={endDateOnChange}
        startDate={startDate}
        endDate={endDate}
        dateError={dateError}
      />
    </LayoutFilter>
  );
};

export default FilterLaporan;
