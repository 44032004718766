import React, { useState } from "react";

const RadioFilter = (props) => {
  const { title, selectedStatus, statusOnChange } = props;

  const [selectedOption, setSelectedOption] = useState(""); // Nilai default yang dipilih

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div className="md:ml-8 md:my-3 mt-3 w-full ml-5">
      <h5 className="text-sm font-semibold mb-2 text-header2">{title}</h5>
      <div className="pl-2 w-2/3 text-paragraf">
        <div className="flex items-center mb-2 gap-3">
          <input
            type="radio"
            id="option"
            value="semua"
            className="h-3 w-3 checked:bg-black checked:ring-0 active:ring-0 cursor-pointer"
            checked={selectedStatus === "semua"}
            onChange={statusOnChange}
          />
          <label
            htmlFor="option"
            className={`text-xs cursor-pointer ${selectedStatus === "semua" ? "font-bold" : ""}`}
          >
            Semua masalah
          </label>
        </div>
        <div className="flex items-center mb-2 gap-3">
          <input
            type="radio"
            id="option1"
            value="laporan masuk"
            className="h-3 w-3 checked:bg-black checked:ring-0 active:ring-0 cursor-pointer"
            checked={selectedStatus === "laporan masuk"}
            onChange={statusOnChange}
          />
          <label
            htmlFor="option1"
            className={`text-xs cursor-pointer ${
              selectedStatus === "laporan masuk" ? "font-bold" : ""
            }`}
          >
            Laporan Masuk
          </label>
        </div>
        <div className="flex items-center mb-2 gap-3">
          <input
            type="radio"
            id="option2"
            value="ditindaklanjuti"
            className="h-3 w-3 checked:bg-black checked:ring-0 active:ring-0 cursor-pointer"
            checked={selectedStatus === "ditindaklanjuti"}
            onChange={statusOnChange}
          />
          <label
            htmlFor="option2"
            className={`text-xs cursor-pointer ${
              selectedStatus === "ditindaklanjuti" ? "font-bold" : ""
            }`}
          >
            Laporan Ditindaklanjuti
          </label>
        </div>
        <div className="flex items-center mb-2 gap-3">
          <input
            type="radio"
            id="option3"
            value="selesai"
            className="h-3 w-3 checked:bg-black checked:ring-0 active:ring-0 cursor-pointer"
            checked={selectedStatus === "selesai"}
            onChange={statusOnChange}
          />
          <label
            htmlFor="option3"
            className={`text-xs cursor-pointer ${selectedStatus === "selesai" ? "font-bold" : ""}`}
          >
            Laporan Selesai
          </label>
        </div>
      </div>
    </div>
  );
};

export default RadioFilter;
