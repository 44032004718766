import { RequestApi } from "../helpers/RequestApi";
import TokenHelper from "../helpers/TokenHelper";

const getGrafikProduksiTersedia = async (kecamatan, ketuaPetaniId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };
    let path;
    if (kecamatan !== "" && ketuaPetaniId === "") {
      path = `grafik/produksi/sell?kecamatan=${kecamatan}`;
    } else if (ketuaPetaniId !== "" && kecamatan !== "") {
      path = `grafik/produksi/sell?ketua_petani=${ketuaPetaniId}`;
    } else {
      path = `grafik/produksi/sell`;
    }

    const responseData = await RequestApi(
      "GET",
      path,
      {},
      headerToken,
      "Mencoba Menampilkan grafik produksi tersedia"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan produksi tersedia", error);
    throw error;
  }
};

const getGrafikProduksiBulananPemda = async (kecamatan, ketuaPetaniId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };
    let path;
    if (kecamatan !== "" && ketuaPetaniId === "") {
      path = `grafik/produksi/month?kecamatan=${kecamatan}`;
    } else if (ketuaPetaniId !== "" && kecamatan !== "") {
      path = `grafik/produksi/month?ketua_petani=${ketuaPetaniId}`;
    } else {
      path = `grafik/produksi/month`;
    }

    const responseData = await RequestApi(
      "GET",
      path,
      {},
      headerToken,
      "Mencoba Menampilkan grafik produksi bulanan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan produksi bulanan", error);
    throw error;
  }
};

const getAllKecamatanByKetuaPetani = async () => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `public/kecamatan`,
      {},
      headerToken,
      "Mencoba Menampilkan total list kecamatan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan total postingan", error);
    throw error;
  }
};

const getAllKetuaPetaniGroupByKecamatan = async () => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `ketua-petani/kecamatan`,
      {},
      headerToken,
      "Mencoba Menampilkan total list ketua petani by kecamatan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan ketua petani by kecamatan", error);
    throw error;
  }
};

const getAllLaporanPemda = async (page) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `laporan?page=${page}`,
      {},
      headerToken,
      "Mencoba Menampilkan total list laporan Pemda"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan list laporan pemda", error);
    throw error;
  }
};

const getAllRiwayatPemda = async (page) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `riwayat?page=${page}`,
      {},
      headerToken,
      "Mencoba Menampilkan list riwayat laporan pemda"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan list riwayat laporan pemda", error);
    throw error;
  }
};

const getGrafikRiwayat = async (kecamatan, ketuaPetaniId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    let params = [];

    if (ketuaPetaniId) {
      params.push(`ketua_petani=${ketuaPetaniId}`);
    }
    if (kecamatan) {
      params.push(`kecamatan=${kecamatan}`);
    }
    const apiUrl = `grafik/riwayat/month?${params.join("&")}`;

    const responseData = await RequestApi(
      "GET",
      apiUrl,
      {},
      headerToken,
      "Mencoba Menampilkan list grafik riwayat"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan list grafik riwayat", error);
    throw error;
  }
};

const getTotalRiwayatPemda = async () => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `riwayat/totals`,
      {},
      headerToken,
      "Mencoba Menampilkan total riwayat pemda"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan total riwayat pemda", error);
    throw error;
  }
};

const getAverageLaporanPemda = async () => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `laporan/average`,
      {},
      headerToken,
      "Mencoba Menampilkan laporan average pemda"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan laporan average pemda", error);
    throw error;
  }
};

const getAverageProduksiPemda = async () => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `postingan/average`,
      {},
      headerToken,
      "Mencoba Menampilkan produksi average pemda"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan produksi average pemda", error);
    throw error;
  }
};

const getFilterLaporanPemda = async (
  status,
  startDate,
  endDate,
  kecamatan,
  userId,
  page,
  search
) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };
    const params = [];
    if (status) {
      params.push(`status_laporan=${status}`);
    }
    if (kecamatan) {
      params.push(`kecamatan=${kecamatan}`);
    }
    if (userId) {
      params.push(`ketua_petani=${userId}`);
    }
    if (startDate) {
      params.push(`start_date=${startDate}`);
    }
    if (endDate) {
      params.push(`end_date=${endDate}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (search) {
      params.push(`search=${search}`);
    }

    const apiUrl = `laporan/filter?${params.join("&")}`;

    const responseData = await RequestApi(
      "GET",
      apiUrl,
      {},
      headerToken,
      "Mencoba Menampilkan produksi filter laporan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan produksi filter laporan", error);
    throw error;
  }
};

const getFilterPostinganPemda = async (
  status,
  startDate,
  endDate,
  kecamatan,
  userId,
  page,
  search
) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };
    const params = [];
    if (status) {
      params.push(`status_laporan=${status}`);
    }
    if (kecamatan) {
      params.push(`kecamatan=${kecamatan}`);
    }
    if (userId) {
      params.push(`ketua_petani=${userId}`);
    }
    if (startDate) {
      params.push(`start_date=${startDate}`);
    }
    if (endDate) {
      params.push(`end_date=${endDate}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (search) {
      params.push(`search=${search}`);
    }

    const apiUrl = `postingan/filter?${params.join("&")}`;

    const responseData = await RequestApi(
      "GET",
      apiUrl,
      {},
      headerToken,
      "Mencoba Menampilkan produksi filter postingan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan produksi filter postingan", error);
    throw error;
  }
};

const getDetailPostinganPemda = async (id, setErrorPage) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `postingan/detail/${id}`,
      {},
      headerToken,
      "Mencoba Menampilkan produksi average pemda"
    );

    return responseData.data;
  } catch (error) {
    setErrorPage(true);
    console.error("Terjadi kesalahan saat menampilkan produksi average pemda", error);
    // throw error;
    return;
  }
};

const getDetailProfilPemda = async (id) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `profile/user/${id}`,
      {},
      headerToken,
      "Mencoba Menampilkan detail profile"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan detail profile", error);
    throw error;
  }
};

const getNotifPemda = async (userId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `pemda-unhas/notification/${userId}`,
      {},
      headerToken,
      "Mencoba Menampilkan notif pemda"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan notif pemda", error);
    throw error;
  }
};

const addKomentarPemda = async (userId, komentar) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "POST",
      `komentar/${userId}`,
      komentar,
      headerToken,
      "Mencoba add komentar"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat add komentar  ", error);
    throw error;
  }
};

const getDetailLaporanPemda = async (id, setErrorPage) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `laporan/detail/${id}`,
      {},
      headerToken,
      "Mencoba Menampilkan laporan detail pemda"
    );

    return responseData.data;
  } catch (error) {
    setErrorPage(true);
    console.error("Terjadi kesalahan saat menampilkan laporan detail pemda", error);
    // throw error;
    return;
  }
};

const updateProfilePemda = async (id, newProfile) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "POST",
      `profile/user/${id}`,
      newProfile,
      headerToken,
      "Mencoba update profile pemda"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat update profile pemda", error);
    throw error;
  }
};

const updateStatusLaporan = async (id, newStatus) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "PUT",
      `laporan/status/${id}`,
      newStatus,
      headerToken,
      "Mencoba update status laporan"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat update status laporan", error);
    throw error;
  }
};

const getSearchRiwayatPemda = async (page, search, kecamatan, ketua_petani) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const params = [];
    if (page) {
      params.push(`page=${page}`);
    }
    if (search) {
      params.push(`search=${search}`);
    }
    if (kecamatan) {
      params.push(`kecamatan=${kecamatan}`);
    }
    if (ketua_petani) {
      params.push(`ketua_petani=${ketua_petani}`);
    }

    const apiUrl = `riwayat/filter?${params.join("&")}`;

    const responseData = await RequestApi(
      "GET",
      apiUrl,
      {},
      headerToken,
      "Mencoba Menampilkan riwayat search pemda"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan riwayat search pemda  ", error);
    throw error;
  }
};

export {
  getGrafikProduksiTersedia,
  getAllKecamatanByKetuaPetani,
  getAllKetuaPetaniGroupByKecamatan,
  getGrafikProduksiBulananPemda,
  getAllLaporanPemda,
  getAllRiwayatPemda,
  getGrafikRiwayat,
  getTotalRiwayatPemda,
  getAverageLaporanPemda,
  getAverageProduksiPemda,
  getFilterLaporanPemda,
  getFilterPostinganPemda,
  getDetailPostinganPemda,
  getDetailProfilPemda,
  getNotifPemda,
  addKomentarPemda,
  getDetailLaporanPemda,
  updateProfilePemda,
  updateStatusLaporan,
  getSearchRiwayatPemda,
};
