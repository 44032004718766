import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getAllKecamatanByKetuaPetani,
  getAllKetuaPetaniGroupByKecamatan,
  getAllLaporanPemda,
  getAverageLaporanPemda,
  getFilterLaporanPemda,
} from "../../api/PemdaApi";
import LayotuAllPostPemda from "../../Components/LayoutAllPostPemda/LayotuAllPostPemda";
import PostCardLaporanPemda from "../../Components/PostcardPemda/PostCardLaporanPemda";
import SkeletonPostCard from "../../Components/Skeleton/SkeletonPostCard";
import _ from "lodash";

const LaporanAllPostPemda = () => {
  const [listLaporan, setListLaporan] = useState([]);
  const [kecamatan, setKecamatan] = useState();
  const [ketuaPetani, setKetuaPetani] = useState();
  const [selectedKecamatan, setSelectedKecamatan] = useState();
  const [selectedKetuaPetani, setSelectedKetuaPetani] = useState();
  const [averageLaporan, setAverageLaporan] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedStatus, setSelectedStatus] = useState("semua");
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [search, setSearch] = useState("");
  const [alertPop, setAlertPop] = useState(false);

  useEffect(() => {
    let status = selectedStatus;
    if (status === "semua") {
      status = "";
    }
    try {
      if (selectedKecamatan || startDate || endDate || selectedKetuaPetani || status || search) {
        if (startDate > endDate) {
          setAlertPop(true);
          return;
        }
        setAlertPop(false);
        setLoader(true);
        setHasMoreData(true);

        getFilterLaporanPemda(
          status,
          startDate,
          endDate,
          selectedKecamatan,
          selectedKetuaPetani,
          page,
          search
        ).then((result) => {
          setListLaporan((prev) => [...prev, ...result.laporans]);
          if (result.pagination.last_page <= result.pagination.current_page) {
            setHasMoreData(false);
          }
          setAlertPop(false);

          setLoader(false);
          if (result && result.length === 0) {
            return alert("data tidak ditemukan");
          }
        });
        return;
      }
      setLoader(true);
      getAllLaporanPemda(page).then((result) => {
        setListLaporan((prev) => [...prev, ...result.laporans]);
        if (result.pagination.last_page <= result.pagination.current_page) {
          setHasMoreData(false);
        }
        setAlertPop(false);
        setLoader(false);
      });
    } catch (error) {}
  }, [selectedKecamatan, startDate, endDate, selectedStatus, selectedKetuaPetani, page, search]);

  const debouncedSearchOnChange = _.debounce((value) => {
    setSearch(value);
    setPage(1);
    setListLaporan([]);
  }, 500);

  const searchOnChange = (e) => {
    const value = e.target.value;
    debouncedSearchOnChange(value);
  };

  useEffect(() => {
    getAllKecamatanByKetuaPetani().then((result) => {
      setKecamatan(result);
    });
  }, []);

  useEffect(() => {
    getAllKetuaPetaniGroupByKecamatan().then((result) => {
      setKetuaPetani(result);
    });
  }, []);

  useEffect(() => {
    getAverageLaporanPemda().then((result) => {
      setAverageLaporan(result);
    });
  }, []);

  // useEffect(() => {
  //   if (selectedKecamatan || startDate || endDate || selectedKetuaPetani || selectedStatus) {
  //     setLoader(true);
  //     if (selectedStatus === "semua") {
  //       getAllLaporanPemda(page).then((result) => {
  //         setListLaporan(result);
  //         setLoader(false);
  //       });
  //     } else {
  //       getFilterLaporanPemda(
  //         selectedStatus,
  //         startDate,
  //         endDate,
  //         selectedKecamatan,
  //         selectedKetuaPetani
  //       ).then((result) => {
  //         setListLaporan(result);
  //         setLoader(false);
  //         if (result && result.length === 0) {
  //           return alert("data tidak ditemukan");
  //         }
  //       });
  //     }
  //   }

  //   console.log("ini adalah ketua petani asdhasdhaksjh k", selectedKetuaPetani);
  // }, [selectedKecamatan, startDate, endDate, selectedStatus, selectedKetuaPetani, page]);

  const handleSelectedKecamatan = (event) => {
    setSelectedKecamatan(event.target.value);
    setSelectedKetuaPetani(""); // Reset opsi kedua saat opsi pertama berubah
    setPage(1);
    setListLaporan([]);
  };

  const handleSelectedKetuaPetani = (e) => {
    setSelectedKetuaPetani(e.target.value);
    setPage(1);
    setListLaporan([]);
  };
  const startDateOnChange = (e) => {
    setStartDate(e.target.value);
    setPage(1);
    setListLaporan([]);
  };
  const endDateOnChange = (e) => {
    setEndDate(e.target.value);
    setPage(1);
    setListLaporan([]);
  };

  const statusOnChange = (e) => {
    setSelectedStatus(e.target.value);
    setPage(1);
    setListLaporan([]);
  };

  const displayLoader = () => {
    return (
      <>
        <SkeletonPostCard />
        <SkeletonPostCard />
        <SkeletonPostCard />
      </>
    );
  };

  return (
    <LayotuAllPostPemda
      title="Masalah Garam"
      kecamatan={kecamatan}
      ketuaPetani={ketuaPetani}
      selectedKecamatan={selectedKecamatan}
      selectedKetuaPetani={selectedKetuaPetani}
      handleSelectedKecamatan={handleSelectedKecamatan}
      handleSelectedKetuaPetani={handleSelectedKetuaPetani}
      ratarata={averageLaporan}
      startDateOnChange={startDateOnChange}
      endDateOnChange={endDateOnChange}
      statusOnChange={statusOnChange}
      selectedStatus={selectedStatus}
      searchOnChange={searchOnChange}
      dateError={alertPop}
    >
      <div className="md:mt-26 mt-32 lg:ml-[300px] mb-10">
        {listLaporan && (
          <InfiniteScroll
            dataLength={listLaporan.length}
            next={() => setPage((prev) => prev + 1)}
            loader={displayLoader()}
            hasMore={hasMoreData}
            // className="overflow-y-hidden flex flex-wrap"
          >
            <div className="flex flex-wrap  overflow-y-hidden">
              {listLaporan?.map((data, index) => {
                return (
                  <PostCardLaporanPemda
                    key={index}
                    id={data.id}
                    tanggal_produksi={data.tanggal_laporan}
                    deskripsi={data.deskripsi}
                    total_views={data.total_views}
                    total_komen={data.komentars_count}
                    nama={data.nama_ketua_petani}
                    image={data.media.length !== 0 && data.media[0].file_name}
                    kecamatan={data.alamat_ketua_petani.kecamatan}
                    status_laporan={data.status_laporan}
                    read_by_pemda={data.read_by_pemda}
                    read_by_unhas={data.read_by_unhas}
                  />
                );
              })}
              {!loader && listLaporan.length === 0 && (
                <div className="m-10">
                  <p>Data tidak ditemukan....</p>
                </div>
              )}
            </div>
          </InfiniteScroll>
        )}
      </div>
    </LayotuAllPostPemda>
  );
};

export default LaporanAllPostPemda;
