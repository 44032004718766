import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getNotif } from "../../api/api";
import { getNotifPemda } from "../../api/PemdaApi";
import NotifCreateMasalah from "./NotifCreateMasalah";
import NotifCreateProduksi from "./NotifCreateProduksi";
import NotifKomen from "./NotifKomen";
import NotifUbahStatus from "./NotifUbahStatus";

const Notifikasi = ({ isAuthenticated, notif }) => {
  const { userId, role } = useParams();

  const time = (data) => {
    const dateObject = new Date(data);
    const year = dateObject.getFullYear();
    const options = { month: "long" };
    const monthName = new Intl.DateTimeFormat("id-ID", options).format(dateObject); // Bulan dimulai dari 0 (Januari) hingga 11 (Desember), jadi tambahkan 1.
    const day = dateObject.getDate();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const formattedTime =
      (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes;
    return `${day} ${monthName} ${year} | ${formattedTime}`;
  };

  const notifPath = (postingan_id, laporan_id) => {
    if (role === "ketua_petani") {
      if (postingan_id === null) {
        return `/laporan/${role}/${userId}/detail/${laporan_id}`;
      } else if (laporan_id === null) {
        return `/produksi/${role}/${userId}/detail/${postingan_id}`;
      }
    } else {
      if (postingan_id === null) {
        return `/laporan/${role}/${userId}/detail/laporan/${laporan_id}`;
      } else if (laporan_id === null) {
        return `/produksi/${role}/${userId}/detail/produksi/${postingan_id}`;
      }
    }
  };
  return (
    <div className="h-[400px] overflow-y-scroll">
      <h1 className="px-5 text-lg font-bold py-3">Minggu Ini</h1>
      <hr />
      <ul>
        {notif?.map((data, index) => {
          if (data.is_new === true) {
            const dateTime = time(data.notification.created_at);
            const path = notifPath(data.notification.postingan_id, data.notification.laporan_id);
            switch (data.notification.tipe_notifikasi) {
              case "komentar":
                return (
                  <NotifKomen
                    key={index}
                    deskripsi={data.notification.deskripsi}
                    date={dateTime}
                    path={path}
                    username={data.notification.users.username}
                    profile={data.notification.users.profile}
                    kecamatan={data.notification.alamats.kecamatan}
                  />
                );
              case "create_postingan":
                return (
                  <NotifCreateProduksi
                    key={index}
                    date={dateTime}
                    path={path}
                    username={data.notification.users.username}
                    profile={data.notification.users.profile}
                    kecamatan={data.notification.alamats.kecamatan}
                  />
                );
              case "create_laporan":
                return (
                  <NotifCreateMasalah
                    key={index}
                    date={dateTime}
                    path={path}
                    username={data.notification.users.username}
                    profile={data.notification.users.profile}
                    kecamatan={data.notification.alamats.kecamatan}
                  />
                );
              case "ubah_status":
                return (
                  <NotifUbahStatus
                    key={index}
                    deskripsi={data.notification.deskripsi}
                    date={dateTime}
                    path={path}
                    username={data.notification.users.username}
                    profile={data.notification.users.profile}
                  />
                );
              default:
                return null;
            }
          }
        })}
      </ul>
      <h1 className="px-5 text-lg font-bold my-3">Bulan Ini</h1>
      <hr />
      <ul>
        {notif?.map((data, index) => {
          if (data.is_new === false) {
            const dateTime = time(data.notification.created_at);
            const path = notifPath(data.notification.postingan_id, data.notification.laporan_id);
            switch (data.notification.tipe_notifikasi) {
              case "komentar":
                return (
                  <NotifKomen
                    key={index}
                    deskripsi={data.notification.deskripsi}
                    date={dateTime}
                    path={path}
                    username={data.notification.users.username}
                    profile={data.notification.users.profile}
                    kecamatan={data.notification.alamats.kecamatan}
                  />
                );
              case "create_postingan":
                return (
                  <NotifCreateProduksi
                    key={index}
                    date={dateTime}
                    path={path}
                    username={data.notification.users.username}
                    profile={data.notification.users.profile}
                    kecamatan={data.notification.alamats.kecamatan}
                  />
                );
              case "create_laporan":
                return (
                  <NotifCreateMasalah
                    key={index}
                    date={dateTime}
                    path={path}
                    username={data.notification.users.username}
                    profile={data.notification.users.profile}
                    kecamatan={data.notification.alamats.kecamatan}
                  />
                );
              case "ubah_status":
                return (
                  <NotifUbahStatus
                    key={index}
                    deskripsi={data.notification.deskripsi}
                    date={dateTime}
                    path={path}
                    username={data.notification.users.username}
                    profile={data.notification.users.profile}
                  />
                );
              default:
                return null;
            }
          }
        })}
      </ul>
    </div>
  );
};

export default Notifikasi;
