import React from "react";
import { useState } from "react";
import EyeIcon from "../../asset/icon/eyes-icon.svg";
import OpenEyeIcon from "../../asset/icon/open-eye.svg";
import CloseEyeIcon from "../../asset/icon/close-eye.svg";

const InputLogin = (props) => {
  const { onChangeHandler, value, type, title } = props;
  const [changeType, setChangeType] = useState(type);
  const changeTypeHandler = () => {
    if (changeType === "password") {
      setChangeType("text");
    }
    if (changeType === "text") {
      setChangeType("password");
    }
  };

  return (
    <div className="mb-3">
      <h4 className="font-bold mb-2 text-sm">{title}</h4>
      <div className="relative">
        <input
          type={changeType}
          onChange={onChangeHandler}
          value={value}
          placeholder="Silahkan ketik e-mail Anda disini"
          className="border-2 w-72 h-8 rounded-md text-sm ps-2 active:border-blue-gray-800 focus:border-blue-gray-800 focus:ring-0"
        />
        {type === "password" && (
          <img
            src={changeType === "password" ? CloseEyeIcon : OpenEyeIcon}
            alt=""
            className="cursor-pointer absolute top-1/2 transform -translate-y-1/2 right-3 h-5 w-5"
            onClick={changeTypeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default InputLogin;
