import React from "react";

const TotalLaporan = (props) => {
  const { title, postinganTotal, laporanTotal } = props;
  const total = postinganTotal + laporanTotal;

  return (
    <div className="md:ml-8 md:my-5 mt-3 ml-5 pr-5 w-full">
      <h5 className="text-sm font-semibold mb-2 md:mr-10 text-header2">{title}</h5>
      <div className="pl-2 md:w-1/3 w-1/2 text-paragraf">
        <div className="flex items-center mb-2 justify-between">
          <p className="text-xs">Semua :</p>
          <p className="text-xs">{total}</p>
        </div>
        <div className="flex items-center mb-2 justify-between">
          <p className="text-xs">Produksi :</p>
          <p className="text-xs">{postinganTotal}</p>
        </div>
        <div className="flex items-center justify-between">
          <p className="text-xs">Masalah :</p>
          <p className="text-xs">{laporanTotal}</p>
        </div>
      </div>
    </div>
  );
};

export default TotalLaporan;
