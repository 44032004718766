import { Spinner } from "@material-tailwind/react";
import React, { useState } from "react";
import LayoutOnePost from "./LayoutOnePost";
import "../embla.css";
import InputKomen from "./InputKomen";
import EmblaCarousel from "../EmblaCarousel";
import Edit from "./Edit";
import Komentar from "./Komentar";

import Modals from "../Modals/Modals";

import { useEffect } from "react";
import StatusLaporan from "./StatusLaporan";
import { useParams } from "react-router-dom";
import Select from "react-select";
import AlertSuccess from "../Alert/AlertSuccess";
import AlertFail from "../Alert/AlertFail";
import { Lightbox } from "react-modal-image";

// const SLIDE_COUNT = images.length;
// const SLIDES = Array.from(Array(SLIDE_COUNT).keys());
const OPTIONS = { dragFree: true, containScroll: "trimSnaps" };

const LaporanDetail = (props) => {
  const {
    detailLaporan,
    addKomentarHandler,
    komentarOnChange,
    komentar,
    listKomentar,
    loader,
    statusOnChange,
    newStatus,
    handleOpenSelectStatus,
    openStatusSelect,
    statusError,
    isSuccess,
    isFail,
  } = props;
  const { role, id } = useParams();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState([]);
  const [slide, setSlide] = useState();
  const [tahun, setTahun] = useState();
  const [bulan, setBulan] = useState();
  const [hari, setHari] = useState();
  const [tahunModified, setTahunModified] = useState();
  const [bulanModified, setBulanModified] = useState();
  const [hariModified, setHariModified] = useState();
  const [jam, setJam] = useState();
  const [menit, setMenit] = useState();

  const handleOpen = () => setOpen(!open);

  const [showModal, setShowModal] = useState(false);
  const [imageIndex, setImageIndex] = useState();

  const openModal = (index) => {
    setShowModal(true);
    setImageIndex(index);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (detailLaporan) {
      const SLIDE_COUNT = detailLaporan.media.length;
      const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

      setImage(
        detailLaporan.media.map((data) => {
          return data.file_name;
        })
      );
      setSlide(SLIDES);

      const tanggal_laporan = detailLaporan.tanggal_laporan;
      const [tahun, bulanAngka, hari] = tanggal_laporan.split("-");
      const namaBulan = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const namaBulanString = namaBulan[bulanAngka - 1];
      setTahun(tahun);
      setBulan(namaBulanString);
      setHari(hari);

      if (detailLaporan.tanggal_last_modified_by !== null) {
        const [tanggal, waktu] = detailLaporan.tanggal_last_modified_by.split(" ");
        const [tahunModified, bulanModified, hariModified] = tanggal.split("-");
        setTahunModified(tahunModified);
        setBulanModified(namaBulan[bulanModified - 1]);
        setHariModified(hariModified);

        const dateObject = new Date(`${tanggal}T${waktu}Z`);
        const [jam, menit] = waktu.split(":");

        setJam(jam);
        setMenit(menit);
      }
    }
  }, [detailLaporan, listKomentar]);

  const goBack = () => {
    window.history.back();
  };

  const options = [
    { value: "laporan masuk", label: "Masuk" },
    { value: "ditindaklanjuti", label: "Ditindaklanjuti" },
    { value: "selesai", label: "selesai" },
  ];

  return (
    <>
      {detailLaporan && slide ? (
        <LayoutOnePost goBack={goBack} title="Masalah Garam">
          {isSuccess && <AlertSuccess deskripsi="Komentar berhasil ditambahkan" />}
          {isFail && <AlertFail deskripsi="terjadi kesalahan saat menambahkan komentar" />}
          <>
            <section className="sandbox__carousel z-0 md:p-6 pb-3">
              <EmblaCarousel
                slides={slide}
                options={OPTIONS}
                image={image}
                detailLaporan={detailLaporan}
                openModal={openModal}
              />
            </section>

            <p className="text-xs text-hint">
              {hari} {bulan} {tahun}
            </p>
            <p className="text-xs text-hint font-bold mt-2">{detailLaporan.nama_ketua_petani}</p>
            <div className="mt-2">
              <Modals open={open} handler={handleOpen} id={id} type="laporan"></Modals>
              {role !== "ketua_petani" ? "" : <Edit handleOpen={handleOpen} />}
              <div className="md:flex gap-5 mt-5 items-center">
                <StatusLaporan
                  status={newStatus ? newStatus.value : detailLaporan.status_laporan}
                  handleOpenSelectStatus={handleOpenSelectStatus}
                />

                {openStatusSelect && (
                  <div className="w-48 md:mt-0 mt-3">
                    <Select
                      options={options}
                      isSearchable={false}
                      className="text-xs w-full"
                      defaultValue={detailLaporan.status_laporan}
                      onChange={statusOnChange}
                      placeholder="Pilih status"
                    />
                  </div>
                )}
                {detailLaporan.tanggal_last_modified_by !== null ? (
                  <p className="text-[10px] text-hint md:mt-0 mt-3">
                    Tag terakhir kali diubah oleh{" "}
                    <span className="font-bold">{detailLaporan.last_modified_by}</span> pada jam{" "}
                    {jam}:{menit} tanggal {hariModified} {bulanModified} {tahunModified}
                  </p>
                ) : (
                  ""
                )}
              </div>
              {statusError && (
                <p className="text-xs text-red-700">status yang dipilih tidak boleh sama!</p>
              )}
            </div>

            <div className="md:text-sm text-xs text-paragraf mt-5 break-words">
              {detailLaporan.deskripsi}
            </div>
            <div className="w-full border border-gray-800 mt-10"></div>
            <InputKomen
              onChangeHandler={komentarOnChange}
              value={komentar}
              addKomentarHandler={addKomentarHandler}
              loader={loader}
            />
            {listKomentar.map((data, index) => {
              return (
                <Komentar
                  image={role !== "ketua_petani" ? data.profile : data.users.profile}
                  user={role !== "ketua_petani" ? data.username : data.users.username}
                  key={index}
                  deskripsi={data.deskripsi}
                  date={data.created_at}
                />
              );
            })}
            {/* <Komentar image={LogoUnhas} title="Admin 1" />
            <Komentar image={LogoUnhas} title="Admin 1" /> */}
          </>
        </LayoutOnePost>
      ) : (
        <div className="flex mt-72 justify-center">
          <Spinner className="h-20 w-20 " />
        </div>
      )}
      {showModal ? (
        <Lightbox
          medium={`${process.env.REACT_APP_API_STORAGE_URL}/${image[imageIndex]}`}
          large={`${process.env.REACT_APP_API_STORAGE_URL}/${image[imageIndex]}`}
          alt="image detail"
          onClose={closeModal}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default LaporanDetail;
