import React from "react";
import NavigationBar from "../NavigationBar";
import FilterPetani from "../FilterPetani";
import { useLocation } from "react-router-dom";
import FilterProduksi from "../Filter/FilterProduksi";
import FilterLaporan from "../Filter/FilterLaporan";
import FilterRiwayat from "../Filter/FilterRiwayat";

const LayotuAllPostPemda = (props) => {
  const location = useLocation().pathname;
  const {
    title,
    children,
    kecamatan,
    ketuaPetani,
    selectedKecamatan,
    selectedKetuaPetani,
    handleSelectedKecamatan,
    handleSelectedKetuaPetani,
    totalPostingan,
    ratarata,
    startDateOnChange,
    endDateOnChange,
    statusOnChange,
    selectedStatus,
    role,
    userId,
    searchOnChange,
    startDate,
    endDate,
    totalLaporan,
    totalProduksi,
    dateError,
  } = props;
  return (
    <>
      <NavigationBar
        role={role}
        userId={userId}
        searchOnChange={searchOnChange}
        startDateOnChange={startDateOnChange}
        endDateOnChange={endDateOnChange}
        startDate={startDate}
        endDate={endDate}
        totalProduksi={totalProduksi}
        totalLaporan={totalLaporan}
        dateError={dateError}
        statusOnChange={statusOnChange}
        selectedStatus={selectedStatus}
        ratarata={ratarata}
        totalPostingan={totalPostingan}
      />
      <div className="pt-16 flex grid-container">
        {/* Left Konten */}
        <div className="fixed bg-white">
          {(location.includes("/produksi/pemda") || location.includes("/produksi/unhas")) && (
            <FilterProduksi
              ratarata={ratarata}
              startDateOnChange={startDateOnChange}
              endDateOnChange={endDateOnChange}
              statusOnChange={statusOnChange}
              selectedStatus={selectedStatus}
              startDate={startDate}
              endDate={endDate}
              dateError={dateError}
            />
          )}
          {(location.includes("/laporan/pemda") || location.includes("/laporan/unhas")) && (
            <FilterLaporan
              ratarata={ratarata}
              startDateOnChange={startDateOnChange}
              endDateOnChange={endDateOnChange}
              statusOnChange={statusOnChange}
              startDate={startDate}
              endDate={endDate}
              selectedStatus={selectedStatus}
              dateError={dateError}
            />
          )}
          {(location.includes("/riwayat/pemda") || location.includes("/riwayat/unhas")) && (
            <FilterRiwayat totalPostingan={totalPostingan} />
          )}

          {/* <Filter /> */}
        </div>

        <div className="w-full">
          {/* Right Konten */}

          <div className="w-full header fixed bg-white z-10 lg:ml-[300px] ">
            <div className="lg:h-24 lg:justify-start justify-center h-16 text-center w-full flex items-center shadow-md md:pl-10">
              <h1 className="lg:text-3xl text-2xl font-semibold">{title}</h1>
            </div>
            <FilterPetani
              kecamatan={kecamatan}
              ketuaPetani={ketuaPetani}
              selectedKecamatan={selectedKecamatan}
              selectedKetuaPetani={selectedKetuaPetani}
              handleSelectedKecamatan={handleSelectedKecamatan}
              handleSelectedKetuaPetani={handleSelectedKetuaPetani}
              startDateOnChange={startDateOnChange}
              endDateOnChange={endDateOnChange}
            />
          </div>

          {/* post card */}
          <div className="pt-16">{children}</div>
        </div>
      </div>
    </>
  );
};

export default LayotuAllPostPemda;
