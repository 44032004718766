import { useCallback, useRef } from "react";
import LayoutAllPostPetani from "../../Components/LayoutAllPostPetani/LayoutAllPostPetani";
import { useEffect } from "react";
import { useState } from "react";
import { getAllRiwayat, getSearchRiwayat, getTotalPostingan } from "../../api/api";
import PostCardLaporan from "../../Components/PostCard/PostCardLaporan";
import PostCardProduksi from "../../Components/PostCard/PostCardProduksi";
import LayoutPostCard from "../../Components/PostCard/LayoutPostCard";
import Modals from "../../Components/Modals/Modals";
import { useParams } from "react-router-dom";
import SkeletonPostCard from "../../Components/Skeleton/SkeletonPostCard";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";

const RiwayatLaporan = (props) => {
  // const { userId } = props;
  const { userId, role } = useParams();
  const [open, setOpen] = useState(false);
  const [riwayat, setRiwayat] = useState([]);
  const [totalPostingan, setTotalPostingan] = useState();
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [search, setSearch] = useState("");
  const [postinganId, setPostinganId] = useState();
  const [tipePostingan, setTipePostingan] = useState();

  const handleOpen = (id, type) => {
    setPostinganId(id);
    setTipePostingan(type);
    setOpen(!open);
  };
  useEffect(() => {
    setLoader(true);
    try {
      if (search.length > 1) {
        setHasMoreData(true);
        getSearchRiwayat(userId, page, search).then((result) => {
          // Jika bukan halaman pertama, tambahkan hasil pencarian ke riwayat yang sudah ada
          setRiwayat((prevRiwayat) => [...prevRiwayat, ...result.riwayats]);

          if (result.pagination.last_page <= result.pagination.current_page) {
            setHasMoreData(false);
          }
          setLoader(false);
        });
        return;
      } else {
        setHasMoreData(true);
        getAllRiwayat(userId, page).then((result) => {
          // Jika ini adalah hasil tanpa pencarian pada halaman pertama, setel riwayat menjadi hasil tersebut
          if (page === 1) {
            setRiwayat(result.riwayats);
          } else {
            // Jika bukan halaman pertama, tambahkan hasil pencarian ke riwayat yang sudah ada
            setRiwayat((prevRiwayat) => [...prevRiwayat, ...result.riwayats]);
          }

          if (result.pagination.last_page <= result.pagination.current_page) {
            setHasMoreData(false);
          }
          setLoader(false);
        });
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  }, [userId, page, search]);

  const debouncedSearchOnChange = _.debounce((value) => {
    setSearch(value);
    setPage(1);
    setRiwayat([]);
  }, 500); // Sesuaikan timeout debouncing sesuai kebutuhan

  const searchOnChange = (e) => {
    const value = e.target.value;
    debouncedSearchOnChange(value);
  };

  useEffect(() => {
    getTotalPostingan(userId).then((result) => {
      setTotalPostingan(result);
    });
  }, [userId]);

  const displayLoader = () => {
    return (
      <>
        <SkeletonPostCard />
        <SkeletonPostCard />
      </>
    );
  };

  return (
    <LayoutAllPostPetani
      title="Semua Riwayat Laporan"
      userId={userId}
      totalPostingan={totalPostingan}
      searchOnChange={searchOnChange}
      // role={role}
    >
      <div className="lg:ml-[300px] lg:mt-24 mt-16 mb-10">
        <div className="flex justify-center right-0">
          <Modals open={open} handler={handleOpen} id={postinganId} type={tipePostingan}></Modals>
        </div>
        <InfiniteScroll
          dataLength={riwayat.length}
          next={() => setPage((prev) => prev + 1)}
          loader={displayLoader()}
          hasMore={hasMoreData}
          // className="overflow-y-hidden flex flex-wrap"
        >
          <LayoutPostCard>
            {riwayat?.map((data, index) => {
              if (data.type === "laporan") {
                return (
                  <PostCardLaporan
                    key={data.id}
                    handleOpen={handleOpen}
                    id={data.id}
                    type={data.type}
                    status_laporan={data.status_laporan}
                    deskripsi={data.deskripsi}
                    tanggal_laporan={data.tanggal_laporan}
                    buttonColor={
                      data.status_laporan === "laporan masuk"
                        ? "bg-button-laporan"
                        : data.status_laporan === "selesai"
                        ? "bg-button-selesai"
                        : data.status_laporan === "ditindaklanjuti"
                        ? "bg-button-ditindaklanjuti"
                        : ""
                    }
                    image={data.media.length !== 0 && data.media[0].file_name}
                    total_views={data.total_views}
                    total_komen={data.komentars_count}
                    read_by_pemda={data.read_by_pemda}
                    read_by_unhas={data.read_by_unhas}
                  />
                );
              } else {
                return (
                  <PostCardProduksi
                    key={data.id}
                    handleOpen={handleOpen}
                    id={data.id}
                    type={data.type}
                    total_penjualan={data.total_penjualan}
                    total_produksi={data.total_produksi}
                    deskripsi={data.deskripsi}
                    tanggal_produksi={data.tanggal_produksi}
                    image={data.media.length !== 0 && data.media[0].file_name}
                    total_views={data.total_views}
                    total_komen={data.komentars_count}
                    read_by_pemda={data.read_by_pemda}
                    read_by_unhas={data.read_by_unhas}
                  />
                );
              }
            })}
            {!loader && riwayat.length === 0 && (
              <div className="m-10">
                <p>Data tidak ditemukan....</p>
              </div>
            )}
          </LayoutPostCard>
        </InfiniteScroll>
      </div>
    </LayoutAllPostPetani>
  );
};

export default RiwayatLaporan;
