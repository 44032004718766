import Cookies from "js-cookie";
import { RequestApi } from "../helpers/RequestApi";
import TokenHelper from "../helpers/TokenHelper";

const loginUser = async (credentials) => {
  try {
    const responseLogin = await RequestApi("POST", "login", credentials, {}, "Mencoba Login");

    const access_token = responseLogin.data.access_token;
    const role = responseLogin.data.login_user.role;
    let userId;
    if (role === "ketua_petani") {
      userId = responseLogin.data.login_user.ketua_petani_id;
    } else {
      userId = responseLogin.data.login_user.user_id;
    }
    Cookies.set("access_token", access_token, { expires: 7 });
    Cookies.set("userId", userId, { expires: 7 });
    Cookies.set("role", role, { expires: 7 });

    return responseLogin.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat mencoba login ", error);
    throw error;
  }
};

// Logout API
const logoutUser = async () => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi("POST", "logout", {}, headerToken, "Mencoba Keluar");

    Cookies.remove("access_token");
    Cookies.remove("userId");
    Cookies.remove("role");

    return responseData;
  } catch (error) {
    console.error("Terjadi kesalahan saat mencoba logout ", error);
    throw error;
  }
};

const forgotPassword = async (email) => {
  try {
    const responseData = await RequestApi(
      "POST",
      "forgot-password",
      email,
      {},
      "mencoba forgot password"
    );

    return responseData;
  } catch (error) {
    console.error("Terjadi kesalahan saat forgot password ", error);
    throw error;
  }
};

const resetPassword = async (data) => {
  try {
    const responseData = await RequestApi(
      "POST",
      "reset-password",
      data,
      {},
      "mencoba reset password"
    );

    return responseData;
  } catch (error) {
    console.error("Terjadi kesalahan saat reset password ", error);
    throw error;
  }
};

export { loginUser, logoutUser, forgotPassword, resetPassword };
