import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { addKomentar, getDetailPostingan } from "../../api/api";
import ProduksiDetail from "../../Components/OnePost/ProduksiDetail";
import PageNotFound from "../PageNotFound";

const ProduksiDetailPetani = () => {
  const { id, userId } = useParams();
  const [detailPostingan, setDetailPostingan] = useState();
  const [komentar, setKomentar] = useState();
  const [listKomentar, setListKomentar] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [errorPage, setErrorPage] = useState(false);

  useEffect(() => {
    getDetailPostingan(id, setErrorPage).then((result) => {
      if (errorPage === true) {
        return;
      }
      setDetailPostingan(result);
      setListKomentar(result?.komentars);
    });
  }, [id, errorPage]);

  const addKomentarHandler = async (e) => {
    e.preventDefault();

    const komentarData = {
      type: "postingan",
      item_id: detailPostingan.id,
      deskripsi: komentar,
    };

    try {
      setLoader(true);
      await addKomentar(userId, komentarData);
      setIsSuccess(true);
      setKomentar("");

      await getDetailPostingan(id).then((result) => {
        setListKomentar(result.komentars);
      });
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      setLoader(false);
    } catch (error) {
      console.error(error);

      setIsFail(true);
      setLoader(false);
      setTimeout(() => {
        setIsFail(false);
      }, 2000);
    }
  };

  const komentarOnChange = (e) => {
    setKomentar(e.target.value);
  };
  return (
    <>
      {errorPage === false ? (
        <ProduksiDetail
          detailPostingan={detailPostingan}
          addKomentarHandler={addKomentarHandler}
          komentarOnChange={komentarOnChange}
          komentar={komentar}
          listKomentar={listKomentar}
          loader={loader}
          isSuccess={isSuccess}
          isFail={isFail}
        />
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default ProduksiDetailPetani;
