import { Alert, Carousel, Spinner } from "@material-tailwind/react";
import React, { useState } from "react";
import LayoutOnePost from "./LayoutOnePost";
import "../embla.css";
import { images } from "../ImageByIndex";
import InputKomen from "./InputKomen";
import EmblaCarousel from "../EmblaCarousel";
import Edit from "./Edit";
import Total from "./Total";
import Komentar from "./Komentar";
import LogoUnhas from "../../asset/logo/logo-unhas.png";
import LogoMaros from "../../asset/logo/logo-maros.png";
import Modals from "../Modals/Modals";

import { logDOM } from "@testing-library/react";
import { useEffect } from "react";
import { addKomentar } from "../../api/api";
import { useParams } from "react-router-dom";
import AlertSuccess from "../Alert/AlertSuccess";
import AlertFail from "../Alert/AlertFail";
import { Lightbox } from "react-modal-image";

// const SLIDE_COUNT = images.length;
// const SLIDES = Array.from(Array(SLIDE_COUNT).keys());
const OPTIONS = { dragFree: true, containScroll: "trimSnaps" };

const ProduksiDetail = (props) => {
  const {
    userId,
    detailPostingan,
    addKomentarHandler,
    komentarOnChange,
    komentar,
    listKomentar,
    dontShowKomentarInput,
    loader,
    isSuccess,
    isFail,
  } = props;
  const { role, id } = useParams();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState([]);
  const [slide, setSlide] = useState();
  const [tahun, setTahun] = useState();
  const [bulan, setBulan] = useState();
  const [hari, setHari] = useState();

  const handleOpen = () => setOpen(!open);

  const [showModal, setShowModal] = useState(false);
  const [imageIndex, setImageIndex] = useState();

  const openModal = (index) => {
    setShowModal(true);
    setImageIndex(index);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (detailPostingan) {
      const SLIDE_COUNT = detailPostingan.media.length;
      const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

      setImage(
        detailPostingan.media.map((data) => {
          return data.file_name;
        })
      );
      setSlide(SLIDES);

      const [tahun, bulanAngka, hari] = detailPostingan.tanggal_produksi.split("-");
      const namaBulan = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const namaBulanString = namaBulan[bulanAngka - 1];
      setTahun(tahun);
      setBulan(namaBulanString);
      setHari(hari);
    }
  }, [detailPostingan]);

  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      {detailPostingan && slide ? (
        <LayoutOnePost userId={userId} goBack={goBack} title="Produksi Garam">
          {isSuccess && <AlertSuccess deskripsi="Komentar berhasil ditambahkan" />}
          {isFail && <AlertFail deskripsi="terjadi kesalahan saat menambahkan komentar" />}
          <>
            <section className="sandbox__carousel z-0 md:p-6 pb-3">
              <EmblaCarousel
                slides={slide}
                options={OPTIONS}
                image={image}
                detailPostingan={detailPostingan}
                openModal={openModal}
              />
            </section>

            <p className="text-xs text-hint">
              {hari} {bulan} {tahun}
            </p>
            <p className="text-xs text-hint font-bold mt-2">{detailPostingan.nama_ketua_petani}</p>
            <div className="mt-2">
              <Modals open={open} handler={handleOpen} id={id} type="postingan"></Modals>
              {role !== "ketua_petani" ? "" : <Edit handleOpen={handleOpen} />}
            </div>
            <Total
              totalPenjualan={detailPostingan.total_penjualan}
              totalProduksi={detailPostingan.total_produksi}
            />
            <div className="md:text-sm text-xs text-paragraf mt-5 break-words">
              {detailPostingan.deskripsi}
            </div>
            <div className="w-full border border-gray-800 mt-10"></div>

            {listKomentar && !dontShowKomentarInput ? (
              <InputKomen
                onChangeHandler={komentarOnChange}
                value={komentar}
                addKomentarHandler={addKomentarHandler}
                loader={loader}
              />
            ) : (
              ""
            )}
            {listKomentar &&
              listKomentar.map((data, index) => {
                return (
                  <Komentar
                    image={data.profile}
                    user={role !== "ketua_petani" ? data.username : data.users.username}
                    key={index}
                    deskripsi={data.deskripsi}
                    date={data.created_at}
                  />
                );
              })}
            {/* <Komentar image={LogoUnhas} title="Admin 1" />
            <Komentar image={LogoUnhas} title="Admin 1" /> */}
          </>
        </LayoutOnePost>
      ) : (
        <div className="flex mt-72 justify-center">
          <Spinner className="h-20 w-20 " />
        </div>
      )}
      {showModal ? (
        <Lightbox
          medium={`${process.env.REACT_APP_API_STORAGE_URL}/${image[imageIndex]}`}
          large={`${process.env.REACT_APP_API_STORAGE_URL}/${image[imageIndex]}`}
          alt="Image detail!"
          onClose={closeModal}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ProduksiDetail;
