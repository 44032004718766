import { React, useRef, useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import LogoPetani from "../asset/logo/logo-petani.png";
import Kalkulator from "../asset/icon/Page.svg";
import ErrorIcon from "../asset/icon/Error.svg";
import CircledMenuIcon from "../asset/icon/circled-menu.svg";
import NotificationIcon from "../asset/icon/Notification.svg";
import HomeIcon from "../asset/icon/home-icon.svg";
import FilterIcon from "../asset/icon/filter-icon.svg";
import Notifikasi from "./Notifikasi";
import BackIcon from "../asset/icon/back-icon.svg";
import Riwayat from "./Filter/Fragments/Riwayat";
import Waktu from "./Filter/Fragments/Waktu";
import RadioFilter from "./Filter/Fragments/RadioFilter";
import TotalLaporan from "./Filter/Fragments/TotalLaporan";
import ProfilFilter from "./Filter/Fragments/ProfilFilter";
import { getDetailProfil, getNotif } from "../api/api";
import { logoutUser } from "../api/apiAuth";
import TokenHelper from "../helpers/TokenHelper";
import UserToggle from "./Toggle/UserToggle";
import { getDetailProfilPemda, getNotifPemda } from "../api/PemdaApi";
import Ratarata from "./Filter/Fragments/Ratarata";
import Bio from "./Filter/Fragments/Bio";
import DefaultImg from "../asset/img/defaultImage.png";

const NavigationBar = (props) => {
  const {
    searchOnChange,
    startDateOnChange,
    endDateOnChange,
    startDate,
    endDate,
    totalProduksi,
    totalLaporan,
    dateError,
    statusOnChange,
    selectedStatus,
    ratarata,
    totalPostingan,
    hasChange,
    showAlert,
  } = props;
  const { userId, role } = useParams();
  const [show, setShow] = useState(false);
  const [notifOpen, setNotifOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [userToggleOpen, setUserToggleOpen] = useState(false);

  const location = useLocation().pathname;
  const navigate = useNavigate();
  const token = TokenHelper();

  const [notifCount, setNotifCount] = useState(0);

  const [notif, setNotif] = useState();

  useEffect(() => {
    if (userId && isAuthenticated && role === "ketua_petani") {
      getNotif(userId).then((res) => {
        setNotif(res.list_notifications);
        setNotifCount(res.is_new_count);
      });
    }
    if (isAuthenticated && (role === "unhas" || role === "pemda")) {
      getNotifPemda(userId).then((res) => {
        setNotif(res.list_notifications);
        setNotifCount(res.is_new_count);
      });
    }
  }, [userId, role, isAuthenticated]);

  useEffect(() => {
    if (!token) {
      setIsAuthenticated(false);
      return;
    }
    setIsAuthenticated(true);
    return;
  }, [token]);

  const handleToggle = () => {
    setShow((current) => !current);
  };

  const handleNotif = () => {
    setNotifOpen((current) => !current);
  };
  const handleUserToggle = () => {
    setUserToggleOpen((current) => !current);
  };

  const notifRef = useRef(null);
  const userRef = useRef(null);

  const handleClickOutside = (event) => {
    if (notifRef.current && !notifRef.current.contains(event.target)) {
      setNotifOpen(false);
    }
    if (userRef.current && !userRef.current.contains(event.target)) {
      setUserToggleOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleLogout = async () => {
    try {
      const response = await logoutUser();
      setIsAuthenticated(false);
      if (response) {
        navigate("/");
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    if (userId && isAuthenticated && role === "ketua_petani") {
      getDetailProfil(userId).then((result) => {
        setUser(result);
      });
    } else if (userId && isAuthenticated && role !== "ketua_petani") {
      getDetailProfilPemda(userId).then((result) => {
        setUser(result);
      });
    }
  }, [isAuthenticated, userId, role]);

  // const showAlert = (pathname) => {
  //   setShowSubmitALert((prev) => !prev);
  //   setPath(pathname);
  // };

  return (
    <div className="shadow-xl relative w-screen">
      <nav className="bg-[#f5f5f5] h-16 flex items-center w-full fixed z-20 ">
        <div className="md:px-5 px-2 flex justify-between w-full">
          <div className="flex items-center ">
            <div
              onClick={() =>
                hasChange ? showAlert(`/${role}/${userId}`) : navigate(`/${role}/${userId}`)
              }
              className={`sm:flex hidden items-center gap-3 font-bold cursor-pointer`}
            >
              <img src={LogoPetani} alt="" className="h-8 w-8" />
              <span className="text-xl">Garamqu</span>
            </div>
            {(location === `/${role}/${userId}` || location === "/") && (
              <img src={LogoPetani} alt="" className="h-8 w-8 sm:hidden" />
            )}
            <div
              onClick={() =>
                hasChange ? showAlert(`/${role}/${userId}`) : navigate(`/${role}/${userId}`)
              }
            >
              <img
                src={HomeIcon}
                alt="home icon"
                className={`sm:hidden h-8 w-8 mr-3 ${
                  (location === `/${role}/${userId}` || location === "/") && "hidden"
                }`}
              />
            </div>
            {(location === `/produksi/${role}/${userId}` ||
              location === `/laporan/${role}/${userId}` ||
              location === `/riwayat/${role}/${userId}`) && (
              <div className="relative sm:ml-10">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-3 h-3 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="search-navbar"
                  className="block p-2 pl-10 h-8 lg:w-72 w-full text-sm text-gray-900 border border-gray-300 rounded-md bg-white focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Search..."
                  onChange={searchOnChange}
                  // value={search}
                />
              </div>
            )}
          </div>
          {isAuthenticated ? (
            <>
              <div className="flex items-center md:gap-5 gap-2 ml-2">
                <button
                  className={`border bg-primary text-white h-8 w-[93px] px-2 rounded-md text-xs gap-1 items-center justify-center lg:hidden ${
                    location === `/laporan/${role}/${userId}` ||
                    location === `/produksi/${role}/${userId}` ||
                    location === `/riwayat/${role}/${userId}` ||
                    (location.includes(`/pengaturan`) && role === "ketua_petani") ||
                    location.includes(`/bio`)
                      ? "flex"
                      : "hidden"
                  }`}
                  onClick={handleToggle}
                >
                  <img src={FilterIcon} alt="" className=" h-4 w-4" />
                  Filter
                </button>

                <button
                  className={`border border-black text-black h-8 w-[93px] px-2 rounded-md text-xs gap-1 flex items-center lg:hidden ${
                    (location === `/laporan/${role}/${userId}` ||
                      location === `/produksi/${role}/${userId}` ||
                      location.includes(`/pengaturan`) ||
                      location === `/riwayat/${role}/${userId}` ||
                      location.includes(`/bio`)) &&
                    "hidden"
                  }`}
                  onClick={handleToggle}
                >
                  <img src={CircledMenuIcon} alt="" className=" h-4 w-4" />
                  Laporan
                </button>
                {role !== "ketua_petani" && location !== `/${role}/${userId}` ? (
                  ""
                ) : (
                  <>
                    <button
                      className={`bg-buttonColor hover:bg-buttonColor-hover active:bg-buttonColor-active h-8 px-2 rounded-md text-xs text-white  lg:flex items-center hidden ${
                        location === `/submit/produksi/${role}/${userId}` && "lg:hidden"
                      }`}
                      onClick={
                        role === "ketua_petani"
                          ? () =>
                              hasChange
                                ? showAlert(`/submit/produksi/${role}/${userId}`)
                                : navigate(`/submit/produksi/${role}/${userId}`)
                          : () => navigate(`/produksi/${role}/${userId}`)
                      }
                    >
                      <img src={Kalkulator} alt="" className="h-5 w-5" />
                      Laporkan Produksi
                    </button>
                    <button
                      className={`border border-red-600 text-black h-8 px-3 rounded-md text-xs gap-1 lg:flex items-center hidden ${
                        location === `/submit/laporan/${role}/${userId}` && "lg:hidden"
                      }`}
                      onClick={
                        role === "ketua_petani"
                          ? () =>
                              hasChange
                                ? showAlert(`/submit/laporan/${role}/${userId}`)
                                : navigate(`/submit/laporan/${role}/${userId}`)
                          : () => navigate(`/laporan/${role}/${userId}`)
                      }
                    >
                      <img src={ErrorIcon} alt="" className="h-4 w-4" />
                      Laporkan Masalah
                    </button>
                    <button
                      className="border border-black text-black h-8 px-3 rounded-md text-xs gap-1 lg:flex items-center hidden"
                      onClick={() =>
                        hasChange
                          ? showAlert(`/riwayat/${role}/${userId}`)
                          : navigate(`/riwayat/${role}/${userId}`)
                      }
                    >
                      <img src={CircledMenuIcon} alt="" className="h-4 w-4" />
                      Riwayat Laporan
                    </button>
                  </>
                )}

                <div
                  className={`${
                    location === "/" || location === `/${role}/${userId}`
                      ? "relative"
                      : "relative hidden md:flex"
                  } cursor-pointer`}
                  onClick={handleNotif}
                  ref={notifRef}
                >
                  <img src={NotificationIcon} alt="error" className="w-8 h-8" />
                  {notifCount !== 0 && (
                    <div className="h-4 w-4 bg-button-laporan rounded-full text-white text-[10px] flex items-center justify-center font-semibold absolute top-0">
                      {notifCount}
                    </div>
                  )}
                </div>

                {/* show notification */}

                <div
                  className={`absolute bg-white top-16 md:right-20 right-3 w-80 rounded-sm z-50 shadow-md before:content-[''] ${
                    notifOpen
                      ? "opacity-100 visible translate-y-0 duration-200 ease-linear"
                      : "opacity-0 invisible -translate-y-5 duration-200 ease-linear"
                  }`}
                >
                  <Notifikasi
                    isAuthenticated={isAuthenticated}
                    // onNotifCountChange={handleNotifCount}
                    notif={notif}
                  />
                </div>

                {user ? (
                  <>
                    <div
                      // to={`/pengaturan/profil/${userId}`}
                      className="flex items-center gap-3 cursor-pointer"
                      onClick={handleUserToggle}
                      ref={userRef}
                    >
                      <img
                        src={
                          user.profile !== null
                            ? `${process.env.REACT_APP_API_STORAGE_URL}/${user.profile}`
                            : DefaultImg
                        }
                        alt="error"
                        className={` ${
                          location === `/${role}/${userId}` ||
                          (location.includes("pengaturan") && role !== "ketua_petani")
                            ? "w-10 h-10 rounded-full object-cover object-center"
                            : "w-10 h-10 hidden md:flex rounded-full object-cover object-center"
                        }`}
                      />
                      {/* <p className="xl:block hidden text-xs">{user.full_name}</p> */}
                    </div>
                    <div
                      className={`absolute top-16 md:right-2 right-0 z-50 shadow-md before:content-[''] ${
                        userToggleOpen
                          ? "opacity-100 visible translate-y-0 duration-200 ease-linear"
                          : "opacity-0 invisible -translate-y-5 duration-200 ease-linear"
                      }`}
                    >
                      <UserToggle
                        handleLogout={handleLogout}
                        profile={user.profile}
                        userName={user.full_name}
                        navigate={navigate}
                        userId={userId}
                        role={role}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className={`${
                      location === `/${role}/${userId}` || location.includes("pengaturan")
                        ? "w-10 h-10 bg-gray-200 animate-pulse rounded-full"
                        : "w-10 h-10 bg-gray-200 animate-pulse rounded-full md:block hidden"
                    }`}
                  ></div>
                )}

                {/* <div className="h-10 border border-gray-500 hidden xl:block"></div>
                <p className="xl:block hidden text-xs cursor-pointer" onClick={handleLogout}>
                  Keluar
                </p> */}
              </div>
              {show &&
                (location === `/${role}/${userId}` ||
                  location === `/submit/produksi/${role}/${userId}` ||
                  location === `/submit/laporan/${role}/${userId}` ||
                  location.includes("/update")) && (
                  <ToggleLaporan
                    closeHandler={handleToggle}
                    userId={userId}
                    show={show}
                    hasChange={hasChange}
                    showAlert={showAlert}
                  />
                )}
              {show &&
                (location === `/laporan/${role}/${userId}` ||
                  location === `/produksi/${role}/${userId}` ||
                  location === `/riwayat/${role}/${userId}`) && (
                  <FilterToggle
                    closeHandler={handleToggle}
                    title="FILTER"
                    userId={userId}
                    show={show}
                    startDateOnChange={startDateOnChange}
                    endDateOnChange={endDateOnChange}
                    startDate={startDate}
                    endDate={endDate}
                    totalProduksi={totalProduksi}
                    totalLaporan={totalLaporan}
                    dateError={dateError}
                    ratarata={ratarata}
                    totalPostingan={totalPostingan}
                    statusOnChange={statusOnChange}
                    selectedStatus={selectedStatus}
                  />
                )}
              {show && location.includes(`/pengaturan`) && (
                <FilterToggle
                  closeHandler={handleToggle}
                  title="PENGATURAN"
                  userId={userId}
                  show={show}
                />
              )}
              {show && location.includes(`/bio`) && (
                <FilterToggle
                  closeHandler={handleToggle}
                  title="MENU"
                  userId={userId}
                  startDate={startDate}
                  endDate={endDate}
                  startDateOnChange={startDateOnChange}
                  endDateOnChange={endDateOnChange}
                />
              )}
            </>
          ) : (
            <button
              className="bg-buttonColor hover:bg-buttonColor-hover font-semibold text-xs text-white rounded-md w-24"
              onClick={() => navigate("/login")}
            >
              Masuk
            </button>
          )}
        </div>
      </nav>
    </div>
  );
};

const ToggleLaporan = (props) => {
  const { animation, hasChange, showAlert } = props;
  const { userId, role } = useParams();
  const location = useLocation().pathname;
  const navigate = useNavigate();
  return (
    <div
      className={`w-64 h-screen bg-white shadow-md absolute z-30 right-0 p-5 top-0 ${animation}`}
    >
      <div onClick={props.closeHandler} className="font-semibold flex items-center gap-3 mb-5">
        <img src={BackIcon} alt="backicon" />
        <p className="text-lg semibold">LAPORAN</p>
      </div>
      <div className="absolute z-40 ml-5">
        <button
          className={`bg-buttonColor hover:bg-buttonColor-hover active:bg-buttonColor-active h-10 px-3 rounded-md text-sm text-white gap-1 items-center flex w-48`}
          onClick={() =>
            role === "ketua_petani"
              ? hasChange && location.includes("laporan")
                ? showAlert(`/submit/produksi/${role}/${userId}`)
                : navigate(`/submit/produksi/${role}/${userId}`)
              : navigate(`/produksi/${role}/${userId}`)
          }
        >
          <img src={Kalkulator} alt="" className="h-5 w-5" />
          Laporkan Produksi
        </button>
        <button
          className={`border border-red-600 text-black h-10 px-3 rounded-md text-sm gap-1 flex items-center w-48 mt-5`}
          onClick={() =>
            role === "ketua_petani"
              ? hasChange && location.includes("produksi")
                ? showAlert(`/submit/laporan/${role}/${userId}`)
                : navigate(`/submit/laporan/${role}/${userId}`)
              : navigate(`/laporan/${role}/${userId}`)
          }
        >
          <img src={ErrorIcon} alt="" className="h-5 w-5" />
          Laporkan Masalah
        </button>
        <button
          className="border border-black text-black h-10 px-3 rounded-md text-sm gap-1 flex items-center w-48 mt-5"
          onClick={() =>
            hasChange
              ? showAlert(`/riwayat/${role}/${userId}`)
              : navigate(`/riwayat/${role}/${userId}`)
          }
        >
          <img src={CircledMenuIcon} alt="" className="h-5 w-5" />
          Riwayat Laporan
        </button>
      </div>
    </div>
  );
};

const FilterToggle = (props) => {
  const location = useLocation().pathname;
  const {
    startDateOnChange,
    endDateOnChange,
    startDate,
    endDate,
    dateError,
    statusOnChange,
    selectedStatus,
    ratarata,
    totalPostingan,
  } = props;
  const { userId, role, type } = useParams();
  return (
    <div className={`w-[300px] h-screen bg-white shadow-md absolute z-30 right-0 p-3 top-0 `}>
      <div onClick={props.closeHandler} className="font-semibold flex items-center gap-3 mb-2">
        <img src={BackIcon} alt="backicon" />
        <p className="text-md semibold">{props.title}</p>
      </div>
      <div className="absolute z-40">
        {location === `/produksi/${role}/${userId}` && (
          <>
            <Riwayat title="RIWAYAT LAPORAN" userId={userId} />
            {/* <Total title="TOTAL PRODUKSI" /> */}
            <Ratarata title="RATA-RATA PRODUKSI" ratarata={ratarata} />
            <Waktu
              title="FILTER JANGKA WAKTU LAPORAN PRODUKSI"
              startDateOnChange={startDateOnChange}
              endDateOnChange={endDateOnChange}
              startDate={startDate}
              endDate={endDate}
              dateError={dateError}
            />
          </>
        )}
        {location === `/laporan/${role}/${userId}` && (
          <>
            <Riwayat title="RIWAYAT LAPORAN" userId={userId} />
            {/* <Total title="TOTAL MASALAH" /> */}
            <Ratarata ratarata={ratarata} title="RATA-RATA MASALAH" />
            <RadioFilter
              title="FILTER LAPORAN MASALAH"
              ratarata={ratarata}
              statusOnChange={statusOnChange}
              selectedStatus={selectedStatus}
            />
            <Waktu
              title="FILTER JANGKA WAKTU LAPORAN MASALAH"
              startDateOnChange={startDateOnChange}
              endDateOnChange={endDateOnChange}
              startDate={startDate}
              endDate={endDate}
            />
          </>
        )}
        {location === `/riwayat/${role}/${userId}` && (
          <>
            <Riwayat title="RIWAYAT LAPORAN" userId={userId} />
            <TotalLaporan
              title="TOTAL SELURUH RIWAYAT LAPORAN"
              postinganTotal={totalPostingan?.total_postingan}
              laporanTotal={totalPostingan?.total_laporan}
            />
          </>
        )}
        {location.includes(`/pengaturan`) && (
          // <FilterTemplate />
          <ProfilFilter userId={userId} />
        )}

        {/* {(location === "/bio" || location === "/pengaturan/kelompok") && <Bio />} */}
        {location.includes("/bio") && (
          <>
            <Bio />
            {type === "produksi" && (
              <Waktu
                title="FILTER"
                startDateOnChange={startDateOnChange}
                endDateOnChange={endDateOnChange}
                startDate={startDate}
                endDate={endDate}
                dateError={dateError}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NavigationBar;
