import React from "react";
import { Link, useParams } from "react-router-dom";
import NavigationBar from "../NavigationBar";

const LayoutPostCardPemda = (props) => {
  const { children, id, type } = props;
  const { userId, role } = useParams();
  return (
    <>
      <Link
        to={
          type === "masalah"
            ? `/laporan/${role}/${userId}/detail/${type}/${id}`
            : `/produksi/${role}/${userId}/detail/${type}/${id}`
        }
        className="md:h-40 w-full lg:ml-10 md:ml-3 mx-3 mt-5 md:pr-5 border border-gray-300 shadow-md md:rounded-l-lg rounded-lg flex relative"
      >
        {children}
      </Link>
    </>
  );
};

export default LayoutPostCardPemda;
