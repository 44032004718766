import React from "react";
import { useState } from "react";
import Ratarata from "./Fragments/Ratarata";
import Riwayat from "./Fragments/Riwayat";
import Total from "./Fragments/Total";
import Waktu from "./Fragments/Waktu";
import LayoutFilter from "./LayoutFilter";

const FilterProduksi = (props) => {
  const {
    startDateOnChange,
    endDateOnChange,
    startDate,
    endDate,
    totalProduksi,
    totalLaporan,
    dateError,
    userId,
    ratarata,
  } = props;

  return (
    <LayoutFilter>
      <Riwayat title="RIWAYAT LAPORAN" userId={userId} />
      <Ratarata title="RATA-RATA PRODUKSI" ratarata={ratarata} />

      <Waktu
        title="FILTER JANGKA WAKTU LAPORAN PRODUKSI"
        startDateOnChange={startDateOnChange}
        endDateOnChange={endDateOnChange}
        startDate={startDate}
        endDate={endDate}
        dateError={dateError}
      />
    </LayoutFilter>
  );
};

export default FilterProduksi;
