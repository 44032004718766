import { Card, Typography, List, ListItem, ListItemPrefix } from "@material-tailwind/react";
import { Cog6ToothIcon, PowerIcon } from "@heroicons/react/24/solid";
import DefaultImg from "../../asset/img/defaultImage.png";

const userToggle = (props) => {
  const { userName, profile, handleLogout, navigate, userId, role } = props;
  // const navigate = useNavigate();

  return (
    <Card className="w-full max-w-[15rem] p-2 shadow-xl shadow-blue-gray-900/5">
      <div className="flex items-center gap-4 p-4">
        <img
          src={
            profile !== null ? `${process.env.REACT_APP_API_STORAGE_URL}/${profile}` : DefaultImg
          }
          alt="brand"
          className="h-8 w-8 rounded-full object-cover object-center"
        />
        <Typography variant="h5" color="blue-gray">
          {userName}
        </Typography>
      </div>
      <List>
        <ListItem
          onClick={() =>
            navigate(
              role === "ketua_petani"
                ? `/pengaturan/profil/petani/${role}/${userId}`
                : `/pengaturan/${role}/${userId}`
            )
          }
        >
          <ListItemPrefix>
            <Cog6ToothIcon className="h-5 w-5" />
          </ListItemPrefix>
          Settings
        </ListItem>
        <ListItem onClick={handleLogout}>
          <ListItemPrefix>
            <PowerIcon className="h-5 w-5" />
          </ListItemPrefix>
          Log Out
        </ListItem>
      </List>
    </Card>
  );
};

export default userToggle;
