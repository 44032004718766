import { Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

const SubmitAlert = ({ open, desc, handler, path }) => {
  const navigate = useNavigate();
  return (
    <Dialog open={open} size="sm" className="w-[100px] md:h-[250px] h-[280px] ">
      <>
        <DialogHeader className="text-red-600 flex justify-center">Peringatan!!</DialogHeader>
        <hr />
        <DialogBody className="text-center text-black">{desc}</DialogBody>
        <DialogFooter className="text-center flex justify-center">
          <button
            className="mr-1 w-44 h-10 md:mb-0 mb-3 text-red-700 rounded-none border-2 border-red-700 uppercase"
            onClick={() => navigate(path)}
          >
            <span>Iya, Batalkan</span>
          </button>
          <button
            className="bg-white w-44 text-black font-semibold uppercase"
            onClick={() => handler()}
          >
            <span>Tidak</span>
          </button>
        </DialogFooter>
      </>
    </Dialog>
  );
};

export default SubmitAlert;
