import { Alert, Spinner } from "@material-tailwind/react";
import React from "react";

const InputKomen = (props) => {
  const { value, onChangeHandler, addKomentarHandler, loader } = props;

  return (
    <form className="md:mx-10" onSubmit={addKomentarHandler}>
      <textarea
        name=""
        id=""
        cols="30"
        rows="7"
        onChange={onChangeHandler}
        value={value}
        placeholder="Ketik komentar di sini"
        className="w-full border-2 border-gray-500 focus:border-gray-700 active:border-gray-700 rounded-3xl mt-10 p-5 placeholder:text-gray-400"
      ></textarea>
      <button
        className="bg-buttonColor px-5 py-3 rounded-md text-white mt-8 flex items-center gap-10 relative disabled:opacity-80"
        disabled={loader}
      >
        Komentar
        {loader && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Spinner />
          </div>
        )}
      </button>
    </form>
  );
};

export default InputKomen;
