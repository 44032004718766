import NotFoundIcon from "../asset/icon/not-found.png";

const PageNotFound = () => {
  const goBack = () => {
    window.history.back();
  };
  return (
    <div className="min-h-screen grid place-content-center place-items-center">
      <img src={NotFoundIcon} alt="" className="mb-10 w-[100px] h-[100px]" />
      <div className="flex flex-col items-center mb-10 gap-1 mx-16">
        <h1 className="text-paragraf text-xl font-semibold text-center">Halaman Tidak Ditemukan</h1>
        <p className="text-hint text-xs text-center">
          Halaman yang Anda tuju mungkin telah terhapus atau telah terganti
        </p>
      </div>
      <div className="flex flex-col items-center gap-3">
        <p className="text-cyan-600 underline text-sm cursor-pointer" onClick={goBack}>
          Kembali ke halaman sebelumnya
        </p>
        <a href="/" className="text-cyan-600 underline text-sm">
          Kembali ke halaman utama
        </a>
        <a
          href="https://wa.me/+6281524025278"
          rel="noreferrer"
          target="_blank"
          className="text-cyan-600 underline text-sm"
        >
          Butuh bantuan? hubungi kami
        </a>
      </div>
    </div>
  );
};

export default PageNotFound;
