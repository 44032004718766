import React from "react";
import { useLocation, useParams } from "react-router-dom";
import LayoutPengaturan from "../../Components/Pengaturan/LayoutPengaturan";
import Kelompok from "../../Components/Pengaturan/Kelompok";
import { useEffect } from "react";
import { getAllAnggota } from "../../api/api";
import { useState } from "react";
import { Spinner } from "@material-tailwind/react";

const PengaturanKelompok = (props) => {
  const location = useLocation().pathname;
  const [anggota, setAnggota] = useState();
  const { userId } = useParams();

  useEffect(() => {
    getAllAnggota(userId).then((result) => {
      setAnggota(result);
    });
  }, [userId]);
  return (
    <LayoutPengaturan title={"Data Diri Anggota Saya"} userId={userId}>
      {anggota ? (
        anggota.map((data, index) => {
          return (
            <Kelompok
              key={index}
              data={data}
              // username={}
              namaLengkap={data.full_name}
              namaPanggilan={data.nickname}
              gender={data.gender}
              tempatLahir={data.place_birth}
              tanggalLahir={data.birth_date}
              tlp={data.phone_number}
              jalanLahan={data.lahan_garam.jalan}
              kelurahanLahan={data.lahan_garam.kelurahan}
              kecamatanLahan={data.lahan_garam.kecamatan}
              kabupatenLahan={data.lahan_garam.kota}
              provinsiLahan={data.lahan_garam.provinsi}
              // alamatLahan={data.lahan_garam}
              luasLahan={data.lahan_garam.luas_lahan}
            />
          );
        })
      ) : (
        <div className="mt-80 flex justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      )}
    </LayoutPengaturan>
  );
};

export default PengaturanKelompok;
