import React from "react";

import NavigationBar from "../NavigationBar";
import BackIcon from "../../asset/icon/back-icon.svg";
import HomeIcon from "../../asset/icon/home-icon.svg";
import { Link, useParams } from "react-router-dom";

const LayoutOnePost = (props) => {
  const { children, goBack, title } = props;
  const { role, userId } = useParams();
  return (
    <>
      <div className="md:block hidden">
        <NavigationBar userId={userId} />
      </div>
      <div className="md:pt-16 mb-10 flex grid-container">
        {/* Left Konten */}

        {/* Right Konten */}

        <div className="w-full">
          <div className="md:h-24 h-16 w-full flex items-center justify-between md:px-0 px-5 shadow-md z-10 fixed bg-white ">
            <div className="flex items-center gap-5">
              <img src={BackIcon} alt="" className="md:ml-32 cursor-pointer" onClick={goBack} />
              <h1 className="md:text-3xl text-2xl font-semibold">{title}</h1>
            </div>
            <Link to={userId ? `/${role}/${userId}` : "/"} className="md:hidden">
              <img src={HomeIcon} alt="" className="h-8 w-8" />
            </Link>
          </div>
          <div className="md:mx-32 mx-8 mt-28">{children}</div>
        </div>
      </div>
    </>
  );
};

export default LayoutOnePost;
