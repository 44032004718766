import { RequestApi } from "../helpers/RequestApi";
import TokenHelper from "../helpers/TokenHelper";

const getKetuaPetaniDanAnggota = async (ketuaPetaniId) => {
  try {

    const responseData = await RequestApi(
      "GET",
      `public/ketua-petani/${ketuaPetaniId}/profile`,
      {},
      {},
      "Mencoba Menampilkan ketua petani dan anggota"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan ketua petani dan anggota", error);
    throw error;
  }
};

const getAllKetuaPetani = async () => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `public/ketua-petani`,
      {},
      headerToken,
      "Mencoba Menampilkan Ketua Petani"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan ketua petani  ", error);
    throw error;
  }
};

const getPostinganByKetuaPetani = async (ketuaPetaniId, page) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `public/postingan/${ketuaPetaniId}?page=${page}`,
      {},
      headerToken,
      "Mencoba Menampilkan postingan by ketua petani id"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan postingan by ketua petani id  ", error);
    throw error;
  }
};

const getFilterPostinganPublic = async (startDate, endDate, ketuaPetaniId, page, search) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };
    const params = [];
    if (startDate) {
      params.push(`start_date=${startDate}`);
    }
    if (endDate) {
      params.push(`end_date=${endDate}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (search) {
      params.push(`search=${search}`);
    }

    const apiUrl = `public/postingan/${ketuaPetaniId}/filter?${params.join("&")}`;

    const responseData = await RequestApi(
      "GET",
      apiUrl,
      {},
      headerToken,
      "Mencoba Menampilkan produksi filter postingan public"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan produksi filter postingan public", error);
    throw error;
  }
};

const getPostinganAveragePublic = async (ketuaPetaniId) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `postingan/average/${ketuaPetaniId}`,
      {},
      headerToken,
      "Mencoba Menampilkan produksi filter postingan public"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan produksi filter postingan public", error);
    throw error;
  }
};

const getDetailPostinganPublic = async (id) => {
  try {
    const token = TokenHelper();

    const headerToken = {
      Authorization: `Bearer ${token}`,
    };

    const responseData = await RequestApi(
      "GET",
      `public/postingan/detail/${id}`,
      {},
      headerToken,
      "Mencoba Menampilkan detail postingan public"
    );

    return responseData.data;
  } catch (error) {
    console.error("Terjadi kesalahan saat menampilkan detail postingan public", error);
    throw error;
  }
};
export {
  getKetuaPetaniDanAnggota,
  getAllKetuaPetani,
  getPostinganByKetuaPetani,
  getFilterPostinganPublic,
  getPostinganAveragePublic,
  getDetailPostinganPublic,
};
