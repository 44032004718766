import { React, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TotalInput from "../../Components/Submit/TotalInput";
import TanggalInput from "../../Components/Submit/TanggalInput";
import DeskripsiSubmit from "../../Components/Submit/DeskripsiSubmit";
import FotoSubmit from "../../Components/Submit/FotoSubmit";
import ButtonSubmit from "../../Components/Submit/ButtonSubmit";
import LayoutSubmit from "../../Components/Submit/LayoutSubmit";
import { addPostingan, getDetailPostingan, updateProduksi } from "../../api/api";
import { useEffect } from "react";
import { Spinner } from "@material-tailwind/react";
import AlertFail from "../../Components/Alert/AlertFail";
import SubmitAlert from "../../Components/Modals/SubmitAlert";

const ProduksiUpdate = () => {
  const [totalProduksi, setTotalProduksi] = useState();
  const [totalPenjualan, setTotalPenjualan] = useState();
  const [tanggalProduksi, setTanggalProduksi] = useState();
  const [deskripsi, setDeskripsi] = useState();
  const [imageValue, setImageValue] = useState([]);
  const [detailPostingan, setDetailPostingan] = useState();
  const [deletedImageId, setDeletedImageId] = useState([]);
  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [showSubmitALert, setShowSubmitALert] = useState(false);
  const [path, setPath] = useState("");
  const [alertDescription, setAlertDescription] = useState("");

  const navigate = useNavigate();
  const { userId, role, id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getDetailPostingan(id);
        setDetailPostingan(result);
        setTotalProduksi(result.total_produksi);
        setTotalPenjualan(result.total_penjualan);
        setTanggalProduksi(result.tanggal_produksi);
        setDeskripsi(result.deskripsi);

        result.media.map((data) => {
          setImageValue((prev) => [...prev, data.base64]);
        });

        // const convertBase64Jpg = (file) => {
        //   return new Promise((resolve, reject) => {
        //     const fileReader = new FileReader();
        //     fileReader.readAsDataURL(file);

        //     fileReader.onload = () => {
        //       const base64WithExtension = fileReader.result.replace(
        //         /^data:(.*;base64,)?/,
        //         "data:image/jpeg;base64,"
        //       );
        //       resolve(base64WithExtension);
        //     };

        //     fileReader.onerror = (error) => [reject(error)];
        //   });
        // };

        // const base64Promises = result.media.map(async (data) => {
        //   const response = await fetch(
        //     `${process.env.REACT_APP_API_STORAGE_URL}/${data.file_name}`
        //   );
        //   const blob = await response.blob();
        //   const base64 = await convertBase64Jpg(blob);
        //   return base64;
        // });

        // const base64Array = await Promise.all(base64Promises);
        // setImageValue((curr) => [...curr, ...base64Array]);

        const uniqueImageIds = new Set(result.media.map((data) => data.id));
        setDeletedImageId(Array.from(uniqueImageIds));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const showAlertError = () => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 3000);
  };

  const handleUpdatePostingan = async (e) => {
    e.preventDefault();

    if (!tanggalProduksi) {
      const errorDesc = [];

      if (!tanggalProduksi) {
        errorDesc.push("tanggal produksi");
      }

      showAlertError();
      setAlertDescription(errorDesc.join(" ,") + " tidak boleh kosong");
      return;
    } 

    const newDataPostingan = {
      total_produksi: parseInt(totalProduksi),
      total_penjualan: parseInt(totalPenjualan),
      tanggal_produksi: tanggalProduksi,
      deskripsi: deskripsi,
      deleted_postingan_pictures: deletedImageId.join(","),
      postingan_pictures: imageValue,
    };

    setLoader(true);
    try {
      const response = await updateProduksi(id, newDataPostingan);
      if (response) {
        navigate(`/riwayat/${role}/${userId}`);
      }
    } catch (error) {
      console.error(error);
      setAlertDescription(error.response.data.errors.details);
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2000);
    } finally {
      setLoader(false);
    }
  };

  const totalProduksiOnChange = (e) => {
    setTotalProduksi(e.target.value);
    setHasChange(true);
  };

  const totalPenjualanOnChange = (e) => {
    setTotalPenjualan(e.target.value);
    setHasChange(true);
  };

  const tanggalProduksiOnChange = (e) => {
    setTanggalProduksi(e.target.value);
    setHasChange(true);
  };

  const deskripsiOnChange = (e) => {
    setDeskripsi(e.target.value);
    setHasChange(true);
  };

  const imageOnChange = async (e) => {
    const files = e.target.files;
    setHasChange(true);

    if (!files || files.length === 0) {
      console.error("Tidak ada file yang dipilih");
      return;
    }

    try {
      const base64Array = await Promise.all(
        Array.from(files).map(async (file) => {
          if (!(file instanceof Blob)) {
            console.error("Tipe file tidak valid:", file);
            return null;
          }

          return await convertBase64(file);
        })
      );

      const filteredBase64Array = base64Array.filter((base64) => base64 !== null);
      setImageValue((curr) => [...curr, ...filteredBase64Array]);
    } catch (error) {
      console.error("Error dalam Promise.all:", error);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => [reject(error)];
    });
  };

  const handleDeleteImage = (index) => {
    const newImages = [...imageValue];
    newImages.splice(index, 1);
    setImageValue(newImages);
  };

  const showAlert = (path) => {
    setShowSubmitALert((prev) => !prev);
    setPath(path);
  };

  return (
    <>
      {detailPostingan ? (
        <LayoutSubmit userId={userId} showAlert={showAlert} hasChange={hasChange}>
          <SubmitAlert
            open={showSubmitALert}
            handler={() => showAlert()}
            path={path}
            desc="Ini belum diperbarui. Apakah Anda yakin ingin ke halaman lainnya sehingga batal memperbarui?"
          />
          <form onSubmit={handleUpdatePostingan}>
            <div className="flex justify-between mb-5">
              <h1 className="md:text-3xl text-xl font-semibold mr-10">Perbaharui Produksi Garam</h1>
              <ButtonSubmit btnColor="bg-buttonColor" buttonType="Perbaharui" loader={loader} />
            </div>
            <div className="md:flex gap-20">
              <div className="flex md:gap-20 gap-10">
                <TotalInput
                  label="Total Produksi"
                  onChange={totalProduksiOnChange}
                  value={totalProduksi}
                  defaultValue={detailPostingan?.total_produksi}
                />
                <TotalInput
                  label="Total Penjualan"
                  value={totalPenjualan}
                  onChange={totalPenjualanOnChange}
                  defaultValue={detailPostingan?.total_penjualan}
                />
              </div>

              <TanggalInput
                label="Tanggal produksi"
                value={tanggalProduksi}
                onChange={tanggalProduksiOnChange}
                defaultValue={detailPostingan?.tanggal_produksi}
              />
            </div>
            <DeskripsiSubmit
              label="deskripsi produksi"
              value={deskripsi}
              onChange={deskripsiOnChange}
              defaultValue={detailPostingan?.deskripsi}
            />
            <FotoSubmit
              imageValue={imageValue}
              onChange={imageOnChange}
              handleDeleteImage={handleDeleteImage}
            />
          </form>
        </LayoutSubmit>
      ) : (
        <div className="grid place-items-center mt-52">
          <Spinner className="w-20 h-20" />
        </div>
      )}
      {alert && <AlertFail deskripsi={alertDescription} />}
    </>
  );
};

export default ProduksiUpdate;
