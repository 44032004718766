import React from "react";
import LayoutFilter from "./LayoutFilter";
import ProfilFilter from "./Fragments/ProfilFilter";

const FilterPengaturan = (props) => {
  const { userId } = props;
  return (
    <LayoutFilter>
      <ProfilFilter userId={userId} />
    </LayoutFilter>
  );
};

export default FilterPengaturan;
