import React from "react";

const DeskripsiPostCard = (props) => {
  const { deskripsi } = props;
  return (
    <p className="md:text-sm text-xs text-hint overflow-hidden text-ellipsis truncate md:max-w-[65ch] max-w-[25ch]">
      {deskripsi}
    </p>
  );
};

export default DeskripsiPostCard;
