import React from "react";
import { useParams } from "react-router-dom";
import Bio from "./Fragments/Bio";
import Ratarata from "./Fragments/Ratarata";
import Waktu from "./Fragments/Waktu";
import LayoutFilter from "./LayoutFilter";

const FilterBio = (props) => {
  // const { userId } = props;
  const { type } = useParams();
  const { startDateOnChange, endDateOnChange, startDate, endDate, dateError, ratarata } = props;

  return (
    <LayoutFilter>
      <Bio />
      {type === "produksi" && (
        <Waktu
          title="FILTER"
          startDateOnChange={startDateOnChange}
          endDateOnChange={endDateOnChange}
          startDate={startDate}
          endDate={endDate}
          dateError={dateError}
        />
      )}
      {/* <Ratarata title="RATA-RATA PRODUKSI" ratarata={ratarata} /> */}
    </LayoutFilter>
  );
};

export default FilterBio;
