import { useLocation } from "react-router-dom";
import GalleryIcon from "../../asset/icon/gallery-icon.png";

const FotoSubmit = (props) => {
  const { imageValue, onChange, handleDeleteImage } = props;

  return (
    <div>
      <p className="text-xs mt-2">
        Foto/Vidio <span className="font-semibold">(tidak wajib diisi)</span>
      </p>
      <div className="mt-2 flex gap-3">
        <div>
          <div className="w-20 h-20 bg-gray-200 border border-gray-700 rounded-xl pt-3">
            <input type="file" id="input-foto" onChange={onChange} className="hidden" multiple />
            <label
              htmlFor="input-foto"
              className="cursor-pointer flex flex-col justify-center items-center"
            >
              <img src={GalleryIcon} alt="" className="h-10 w-10" />
              <p className="text-xs text-center font-semibold">Pilih Foto</p>
            </label>
          </div>
        </div>
        <div className="flex gap-3 flex-wrap md:mb-0 mb-32">
          {imageValue?.map((item, index) => {
            return (
              <div key={index} className=" relative">
                <img
                  src={item}
                  alt=""
                  className="h-20 md:w-30 w-24 rounded-lg z-20 object-cover object-center"
                />
                <div
                  className="absolute -top-2 -right-2 border-2 border-gray-700 rounded-full  w-5 h-5 flex items-center justify-center cursor-pointer"
                  onClick={() => handleDeleteImage(index)}
                >
                  <p className=" text-black">x</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FotoSubmit;
