import React from "react";

const Kelompok = (props) => {
  const {
    namaPanggilan,
    namaLengkap,
    luasLahan,
    provinsiLahan,
    kabupatenLahan,
    kecamatanLahan,
    kelurahanLahan,
    jalanLahan,
    tlp,
    tanggalLahir,
    tempatLahir,
    gender,
  } = props;
  return (
    <div className="lg:ml-[315px]">
      <div className="p-5 border border-gray-700 rounded-xl md:mr-10 mx-2 md:translate-y-24 md:mt-5 mt-2 md:mb-10 mb-5 ">
        <div className="lg:flex">
          <div>
            <h3 className="font-semibold sm:text-lg text-sm md:mb-3 mb-1">Alamat Rumah</h3>
            <table className="w-full table-fixed sm:text-sm text-xs">
              {/* <tr>
                <td>Username</td>
                <td>Petani1</td>
              </tr> */}
              <tr>
                <td>Nama Lengkap</td>
                <td>{namaLengkap}</td>
              </tr>
              <tr>
                <td>Nama Panggilan</td>
                <td>{namaPanggilan}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>{gender}</td>
              </tr>
              <tr>
                <td>Tempat Lahir</td>
                <td>{tempatLahir}</td>
              </tr>
              <tr>
                <td>Tanggal Lahir</td>
                <td>{tanggalLahir}</td>
              </tr>
              <tr>
                <td>No.Telp</td>
                <td>{tlp}</td>
              </tr>
            </table>
          </div>
          <div className="md:flex gap-32 md:mt-0 mt-5">
            <div>
              <h3 className="font-semibold sm:text-lg text-sm md:mb-3 mb-1">Lahan Garam</h3>
              <table className="w-full table-fixed sm:text-sm text-xs">
                <tr>
                  <td>Jalan</td>
                  <td>{jalanLahan}</td>
                </tr>
                <tr>
                  <td>Kelurahan/Dusun</td>
                  <td>{kelurahanLahan}</td>
                </tr>
                <tr>
                  <td>Kecamatan</td>
                  <td>{kecamatanLahan}</td>
                </tr>
                <tr>
                  <td>Kabupaten/Kota</td>
                  <td>{kabupatenLahan}</td>
                </tr>
                <tr>
                  <td>Provinsi</td>
                  <td>{provinsiLahan}</td>
                </tr>
                {/* <tr>
                  <td>Alamat lahan garam</td>
                  <td></td>
                </tr> */}
                <tr>
                  <td>Luas Lahan</td>
                  <td>{luasLahan}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kelompok;
