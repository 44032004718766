import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { addKomentar, getDetailLaporan } from "../../api/api";
import LaporanDetail from "../../Components/OnePost/LaporanDetail";
import PageNotFound from "../PageNotFound";

const LaporanDetailPetani = () => {
  const { id, userId } = useParams();

  const [detailLaporan, setDetailLaporan] = useState();
  const [listKomentar, setListKomentar] = useState();
  const [komentar, setKomentar] = useState("");
  const [loader, setLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [errorPage, setErrorPage] = useState(false);

  useEffect(() => {
    getDetailLaporan(id, setErrorPage).then((result) => {
      setDetailLaporan(result);
      if (errorPage === true) {
        return;
      }
      setListKomentar(result?.komentars);
    });
  }, [id, errorPage]);

  const addKomentarHandler = async (e) => {
    e.preventDefault();

    const KomentarData = {
      type: "laporan",
      item_id: detailLaporan.id,
      deskripsi: komentar,
    };

    try {
      setLoader(true);
      await addKomentar(userId, KomentarData);
      setIsSuccess(true);
      setKomentar("");

      await getDetailLaporan(id).then((result) => {
        setListKomentar(result.komentars);
      });
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setIsFail(true);
      setLoader(false);
      setTimeout(() => {
        setIsFail(false);
      }, 2000);
    }
  };

  const komentarOnChange = (e) => {
    setKomentar(e.target.value);
  };
  return (
    <>
      {errorPage === false ? (
        <LaporanDetail
          detailLaporan={detailLaporan}
          addKomentarHandler={addKomentarHandler}
          komentarOnChange={komentarOnChange}
          listKomentar={listKomentar}
          komentar={komentar}
          loader={loader}
          isSuccess={isSuccess}
          isFail={isFail}
        />
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default LaporanDetailPetani;
