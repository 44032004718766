import React from "react";

const SkeletonRatarata = () => {
  return (
    <div class="w-full animate-pulse">
      <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
      <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[200px] mb-2.5"></div>
      <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[200px] mb-2.5"></div>
      <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[200px] mb-2.5"></div>
    </div>
  );
};

export default SkeletonRatarata;
