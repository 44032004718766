import React from "react";
import { useParams } from "react-router-dom";
import RiwayatLaporan from "./ketuaPetani/RiwayatLaporan";
import RiwayatLaporanPemda from "./pemda/RiwayatLaporanPemda";

const RiwayatPage = () => {
  const { role } = useParams();
  return <>{role === "ketua_petani" ? <RiwayatLaporan /> : <RiwayatLaporanPemda />}</>;
};

export default RiwayatPage;
