import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getPostinganPemda } from "../../api/api";
import {
  getAllKecamatanByKetuaPetani,
  getAllKetuaPetaniGroupByKecamatan,
  getAverageProduksiPemda,
  getFilterPostinganPemda,
  getGrafikProduksiBulananPemda,
  getGrafikProduksiTersedia,
} from "../../api/PemdaApi";
import { LineChartPemda } from "../../Components/Grafik/LineChartPemda";
import { PieChartPemda } from "../../Components/Grafik/PieChartPemda";
import LayotuAllPostPemda from "../../Components/LayoutAllPostPemda/LayotuAllPostPemda";
import PostCardProduksiPemda from "../../Components/PostcardPemda/PostCardProduksiPemda";
import SkeletonPostCard from "../../Components/Skeleton/SkeletonPostCard";
import _ from "lodash";

const ProduksiAllPostPemda = () => {
  const [postingan, setPostingan] = useState([]);
  const [kecamatan, setKecamatan] = useState();
  const [ketuaPetani, setKetuaPetani] = useState();
  const [selectedKecamatan, setSelectedKecamatan] = useState("");
  const [selectedKetuaPetani, setSelectedKetuaPetani] = useState("");
  const [grafikPorduksiTersedia, setGrafikPorduksiTersedia] = useState();
  const [dataGrafik, setDataGrafik] = useState();
  const [averagePostingan, setAveragePostingan] = useState();
  const [dataGrafikLine, setDataGrafikLine] = useState({ labels: [], datasets: [] });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [loader, setLoader] = useState(false);
  const [maxTotalPostingan, setMaxTotalPostingan] = useState();
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [search, setSearch] = useState("");
  const [alertPop, setAlertPop] = useState(false);

  const handleSelectedKecamatan = (event) => {
    setSelectedKecamatan(event.target.value);
    setSelectedKetuaPetani(""); // Reset opsi kedua saat opsi pertama berubah
    setPage(1);
    setPostingan([]);
  };

  const handleSelectedKetuaPetani = (e) => {
    setSelectedKetuaPetani(e.target.value);
    setPage(1);
    setPostingan([]);
  };

  useEffect(() => {
    setLoader(true);
    if (
      selectedKecamatan ||
      startDate ||
      endDate ||
      selectedKetuaPetani ||
      selectedStatus ||
      search
    ) {
      if (startDate > endDate) {
        setAlertPop(true);
        return;
      }
      setAlertPop(false);
      setLoader(true);
      setHasMoreData(true);
      if (selectedStatus === "semua" && search <= 1) {
        getPostinganPemda(page).then((result) => {
          setPostingan((prev) => [...prev, ...result.postingans]);
          // setPostingan(result.postingans)
          if (result.pagination.last_page === page) {
            setHasMoreData(false);
          }
          setAlertPop(false);
          setLoader(false);
        });
      } else {
        getFilterPostinganPemda(
          selectedStatus,
          startDate,
          endDate,
          selectedKecamatan,
          selectedKetuaPetani,
          page,
          search
        ).then((result) => {
          setPostingan((prev) => [...prev, ...result.postingans]);
          if (result.pagination.last_page <= result.pagination.current_page) {
            setHasMoreData(false);
          }
          setAlertPop(false);
          setLoader(false);

          if (result && result.length === 0) {
            return alert("data tidak ditemukan");
          }
        });
      }
      return;
    }
    setHasMoreData(true);
    getPostinganPemda(page).then((result) => {
      setPostingan((prev) => [...prev, ...result.postingans]);
      // setPostingan(result.postingans)
      if (result.pagination.last_page === page) {
        setHasMoreData(false);
      }
      setLoader(false);
    });
  }, [page, selectedKecamatan, startDate, endDate, selectedStatus, selectedKetuaPetani, search]);

  const debouncedSearchOnChange = _.debounce((value) => {
    setSearch(value);
    setPage(1);
    setPostingan([]);
  }, 500);

  const searchOnChange = (e) => {
    const value = e.target.value;
    debouncedSearchOnChange(value);
  };

  useEffect(() => {
    getAllKecamatanByKetuaPetani().then((result) => {
      setKecamatan(result);
    });
  }, []);

  useEffect(() => {
    getAllKetuaPetaniGroupByKecamatan().then((result) => {
      setKetuaPetani(result);
    });
  }, []);

  useEffect(() => {
    getAverageProduksiPemda().then((result) => {
      setAveragePostingan(result);
    });
  }, []);

  useEffect(() => {
    getGrafikProduksiTersedia(selectedKecamatan, selectedKetuaPetani).then((result) => {
      setGrafikPorduksiTersedia(result);
      if (result) {
        const data = {
          labels: ["Terjual", "Tersedia"],
          datasets: [
            {
              label: "total (ton)",
              data: [result.total_terjual, result.total_tersedia],
              backgroundColor: ["rgba(215, 39, 39, 0.8)", "rgba(0, 102, 141, 0.57)"],
              // borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
              borderWidth: 0,
            },
          ],
        };

        setDataGrafik(data);
      }
    });
  }, [selectedKecamatan, selectedKetuaPetani]);

  useEffect(() => {
    try {
      getGrafikProduksiBulananPemda(selectedKecamatan, selectedKetuaPetani).then((result) => {
        if (result) {
          const newChartData = dataForChart(result);
          setDataGrafikLine(newChartData);
        }
      });
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  }, [selectedKecamatan, selectedKetuaPetani]);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const dataForChart = (result) => {
    const chartData = {
      labels: [],
      datasets: [],
    };
    let maxProduksi = 0;
    for (const key in result) {
      if (Object.hasOwnProperty.call(result, key)) {
        const dataForLabel = result[key];
        const currentMaxProduksi = Math.max(...dataForLabel.map((data) => data.produksi));

        // Update maxProduksi if the currentMaxProduksi is greater
        if (currentMaxProduksi > maxProduksi) {
          maxProduksi = currentMaxProduksi;
        }
        const dataset = {
          label: key,
          data: dataForLabel.map((data) => data.produksi),
          backgroundColor: getRandomColor(),
          cubicInterpolationMode: "monotone",
        };
        chartData.labels = dataForLabel.map((item) => item.date);
        chartData.datasets.push(dataset);
      }
    }
    setMaxTotalPostingan(maxProduksi);
    return chartData;
  };

  // useEffect(() => {
  //   if (selectedKecamatan || startDate || endDate || selectedKetuaPetani || selectedStatus) {
  //     setPage(1);
  //     setLoader(true);
  //     setHasMoreData(true);
  //     if (selectedStatus === "semua") {
  //       // getPostinganPemda().then((result) => {
  //       //   setPostingan(result);
  //       //   setLoader(false);
  //       // });
  //       return;
  //     } else {
  //       getFilterPostinganPemda(
  //         selectedStatus,
  //         startDate,
  //         endDate,
  //         selectedKecamatan,
  //         selectedKetuaPetani,
  //         page
  //       ).then((result) => {
  //         setPostingan((prev) => [...prev, ...result.postingans]);
  //         if (result.pagination.last_page <= result.pagination.current_page) {
  //           setHasMoreData(false);
  //         }
  //         // setHasMoreData(false);
  //         setLoader(false);

  //         if (result && result.length === 0) {
  //           return alert("data tidak ditemukan");
  //         }
  //       });
  //     }
  //   }
  // }, [selectedKecamatan, startDate, endDate, selectedStatus, selectedKetuaPetani, page]);

  const startDateOnChange = (e) => {
    setStartDate(e.target.value);
    setPage(1);
    setPostingan([]);
  };
  const endDateOnChange = (e) => {
    setEndDate(e.target.value);
    setPage(1);
    setPostingan([]);
  };

  const statusOnChange = (e) => {
    setSelectedStatus(e.target.value);
    setPage(1);
    setPostingan([]);
  };

  const displayLoader = () => {
    return (
      <div className="">
        <SkeletonPostCard />
        <SkeletonPostCard />
      </div>
    );
  };

  return (
    <LayotuAllPostPemda
      title="Produksi Garam"
      kecamatan={kecamatan}
      ketuaPetani={ketuaPetani}
      selectedKecamatan={selectedKecamatan}
      selectedKetuaPetani={selectedKetuaPetani}
      handleSelectedKecamatan={handleSelectedKecamatan}
      handleSelectedKetuaPetani={handleSelectedKetuaPetani}
      ratarata={averagePostingan}
      statusOnChange={statusOnChange}
      endDateOnChange={endDateOnChange}
      startDateOnChange={startDateOnChange}
      searchOnChange={searchOnChange}
      dateError={alertPop}
    >
      <div className="lg:ml-[310px] md:mt-28 mt-36 items-center">
        <h1 className="md:text-4xl text-xl font-bold text-center mb-5">Grafik Produksi Garam</h1>
        <div className="lg:flex items-center">
          <div className="lg:w-1/4 w-3/5 mx-auto">
            {dataGrafik && <PieChartPemda dataGrafik={dataGrafik} />}
          </div>
          <div className="lg:w-3/4 w-full">
            {dataGrafikLine.labels.length !== 0 && (
              <LineChartPemda
                dataGrafik={dataGrafikLine}
                max={maxTotalPostingan}
                title="Produksi (Ton)"
              />
            )}
          </div>
        </div>
      </div>
      <hr />
      {/* <div className="w-full h-0.5 bg-gray-200" /> */}
      <div className="lg:ml-[300px] mb-10 ">
        <InfiniteScroll
          dataLength={postingan.length}
          next={() => setPage((prev) => prev + 1)}
          loader={displayLoader()}
          hasMore={hasMoreData}
        >
          <div className="flex flex-wrap overflow-y-hidden">
            {postingan?.map((data, index) => {
              return (
                <PostCardProduksiPemda
                  key={index}
                  id={data.id}
                  tanggal_produksi={data.tanggal_produksi}
                  total_produksi={data.total_produksi}
                  total_penjualan={data.total_penjualan}
                  deskripsi={data.deskripsi}
                  total_views={data.total_views}
                  total_komen={data.komentars_count}
                  nama={data.nama_ketua_petani}
                  image={data.media.length !== 0 && data.media[0].file_name}
                  kecamatan={data.alamat_ketua_petani.kecamatan}
                  read_by_pemda={data.read_by_pemda}
                  read_by_unhas={data.read_by_unhas}
                />
              );
            })}
            {!loader && postingan.length === 0 && (
              <div className="m-10">
                <p>Data tidak ditemukan....</p>
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    </LayotuAllPostPemda>
  );
};

export default ProduksiAllPostPemda;
